import React from "react";
import { PageList } from "../components/pageList";
import { InvoiceTableSchema } from "../schema/table/invoice";
import { InvoiceAddForm, InvoiceEditForm } from "../schema/form/invoice";
import { useTableSchema } from "../hooks/useTableSchema";

const Invoice = ({ title = "Invoice" }) => {
  const [getTableSchema] = useTableSchema();

  const formSchema = {
    add: { ...InvoiceEditForm, initialValues: { status: "DFT" } },
    edit: InvoiceEditForm,
  };
  return (
    <PageList
      title={title}
      apiEntity={"invoice"}
      tableSchema={getTableSchema("invoice")}
      formSchema={formSchema}
      showImport={false}
      initSort={{ by: "code", order: "desc" }}
      searchByTextField={true}
      showAddButton={true}
      // stats={{
      //   component: ProjecPageStats,
      //   props: {
      //     mb: 35,

      //   },
      // }}
    />
  );
};

export default Invoice;
