import React from "react";
import { Timeline, Card, Text, Group } from "@mantine/core";
import { IconDotsVertical } from "@tabler/icons-react";
import _ from "lodash";
import JsonViewer from "./common/jsonViewer";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ProjectTimeline = ({ form, name }) => {
  const timeline = _.get(form.values, "duration") || {};

  // const getActive = () => {
  //   const now = moment();
  //   // const timelineStarts = timeline.map((t) => moment(t.start));

  //   for (let i = 0; i < timelineStarts.length; i++) {
  //     if (now.isBetween(timelineStarts[i], timelineStarts[i + 1])) {
  //       return i;
  //     }
  //   }

  //   if (now.isAfter(timelineStarts[timelineStarts.length - 1])) {
  //     return timelineStarts.length - 1;
  //   }

  //   return 0;
  // };

  const getDuration = (start, end) => {
    const hrs = moment(end).diff(moment(start), "hours");
    if (hrs < 24) {
      return `${hrs} hours`;
    } else {
      return `${moment(end).diff(moment(start), "days")} days`;
    }
  };
  const { t: translate } = useTranslation();

  //for each key in duration
  //if the key is less than the current date
  //add the key to the passedEvents array
  //return the passedEvents array
  const passedEvents = Object.keys(timeline).length;
  return (
    <Card>
      {/* <JsonViewer src={timeline} /> */}
      <Card.Section withBorder inheritPadding py={"xs"}>
        <Group justify="space-between">
          <Text size="sm" fw={500}>
            {translate("Timeline")}
          </Text>
          {/* <IconDotsVertical size={12} /> */}
        </Group>
      </Card.Section>
      <Card.Section inheritPadding py="md" withBorder>
        <Timeline active={7} bulletSize={10} lineWidth={2}>
          <Timeline.Item>
            <Text size="xs" fw={700}>
              {translate("Setup")}
            </Text>
            <Text size="xs" mt={2}>
              {timeline.setup
                ? moment(timeline.setup).format("lll, dddd")
                : "--"}
            </Text>
          </Timeline.Item>
          <Timeline.Item>
            <Text size="xs" fw={700}>
              {translate("Setup End")}
            </Text>
            <Text size="xs" mt={2}>
              {timeline.setupEnd
                ? moment(timeline.setupEnd).format("lll, dddd")
                : "--"}
            </Text>
          </Timeline.Item>
          <Timeline.Item>
            <Text size="xs" fw={700}>
              {translate("Rehearsal Start")}
            </Text>
            <Text size="xs" mt={2}>
              {timeline.rehearsal
                ? moment(timeline.rehearsal).format("lll, dddd")
                : "--"}
            </Text>
          </Timeline.Item>
          <Timeline.Item>
            <Text size="xs" fw={700}>
              {translate("Rehearsal End")}
            </Text>
            <Text size="xs" mt={2}>
              {timeline.rehearsalEnd
                ? moment(timeline.rehearsalEnd).format("lll, dddd")
                : "--"}
            </Text>
          </Timeline.Item>
          <Timeline.Item>
            <Text size="xs" fw={700}>
              {translate("Event Start")}
            </Text>
            <Text size="xs" mt={2}>
              {timeline.start
                ? moment(timeline.start).format("lll, dddd")
                : "--"}
            </Text>
          </Timeline.Item>
          <Timeline.Item>
            <Text size="xs" fw={700}>
              {translate("Event End")}
            </Text>
            <Text size="xs" mt={2}>
              {timeline.end ? moment(timeline.end).format("lll, dddd") : "--"}
            </Text>
          </Timeline.Item>
          <Timeline.Item>
            <Text size="xs" fw={700}>
              {translate("Dismantle Start")}
            </Text>
            <Text size="xs" mt={2}>
              {timeline.dismantle
                ? moment(timeline.dismantle).format("lll, dddd")
                : "--"}
            </Text>
          </Timeline.Item>
          <Timeline.Item>
            <Text size="xs" fw={700}>
              {translate("Dismantle End")}
            </Text>
            <Text size="xs" mt={2}>
              {timeline.dismantleEnd
                ? moment(timeline.dismantleEnd).format("lll, dddd")
                : "--"}
            </Text>
          </Timeline.Item>
        </Timeline>
      </Card.Section>
    </Card>
  );
};

export default ProjectTimeline;
