export const DATA_CURRENCY = [
  { value: "HKD", label: "HKD" },
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
  { value: "RMB", label: "RMB" },
  { value: "AUD", label: "AUD" },
  { value: "CAD", label: "CAD" },
  { value: "JPY", label: "JPY" },
  { value: "CHF", label: "CHF" },
  { value: "SGD", label: "SGD" },
];

export const DATA_INCENTIVE_TYPE = [
  {
    value: "INCENTIVE",
    label: "INCENTIVE",
  },
  {
    value: "CHARGE",
    label: "CHARGE",
  },
];

export const DATA_UNIT_OF_LENGTH = [
  { value: "feet", label: "feet 尺" },
  { value: "inch", label: "inch 吋" },
  { value: "mm", label: "mm 毫米" },
  { value: "cm", label: "cm 里米" },
  { value: "M", label: "M 米" },
];

export const DATA_UNIT_OF_MEASURE = [
  { value: "PCS", label: "PCS 件", type: "count" },
  { value: "JOB", label: "JOB 工", type: "count" },
  { value: "EACH", label: "Each 個", type: "count" },
  { value: "BOX", label: "Box 箱", type: "count" },
  { value: "CASE", label: "Case 盒", type: "count" },
  { value: "POUND", label: "Pound 磅", type: "Weight" },
  { value: "KILOGRAM", label: "KG 公斤", type: "Weight" },
  { value: "METER", label: "Meter米", type: "length" },
  { value: "CENTIMETER", label: "CM 里米", type: "length" },
  { value: "Millmeter", label: "MM 毫米", type: "length" },
  { value: "INCH", label: "Inch 吋", type: "length" },
  { value: "LITER", label: "L 升", type: "volumn" },
  { value: "cm^2", label: "cm2 平方里米", type: "area" },
  { value: "m^2", label: "m2 平方米", type: "area" },
  { value: "PAX", label: "PAX 件", type: "count" },
];

export const DATA_ITEM_TRANSACTION_TYPE = [
  {
    group: "In (+)",
    items: [
      { label: "Purchase 購入", value: "PURCHASE" },
      { label: "Initial 庫存初始", value: "INIT" },
      { label: "Adjustment(+) 庫存調整", value: "ADJUSTMENT-IN" },
      { label: "Transfer In 轉入", value: "TRANSFER-IN" },
    ],
  },
  {
    group: "Out (-)",
    items: [
      { label: "Loss 遺失", value: "LOSS" },
      { label: "Damage 損耗", value: "DAMAGE" },
      { label: "Sell Out 賣出", value: "SELL" },
      { label: "Adjustment(-) 庫存調整", value: "ADJUSTMENT-OUT" },
      { label: "Transfer Out 轉出", value: "TRANSFER-OUT" },
    ],
  },
];

export const DATA_PRODUCT_DEFAULT_CHECKLIST = [
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "642548fb59f8091d30f4cacc",
    name: "Client Service Agreement with FNA",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "642548fb59f8091d30f4cacd",
    name: "Client Service Agreement with MPF",
  },
  {
    optional: false,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af1",
    name: "Client's Identity (ID / Passport )",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af2",
    name: "Client Address Proof (within 3 months)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af3",

    name: "Client Service Agreement (ILAS)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af4",
    name: "Frontier Risk Profile Questionnaire (ILAS)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af5",
    name: "AML Assessment Form (CDD) ",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af6",
    name: "Enhanced Due Diligence Form (EDD)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af7",
    name: "Application Form",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af8",
    name: "Proposal ",
  },
  {
    optional: false,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8af9",
    name: "FNA",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8afa",
    name: "Provider Risk Profile Questionnaire (ILAS)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8afb",
    name: "Import Facts statement and applicant's declarations (ILAS)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8afc",
    name: "Import Facts statement and applicant's declarations (Premium Financing)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8afd",
    name: "Import Facts statement and applicant's declarations (MCV)",
  },
  {
    optional: false,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8afe",
    name: "Direct Debit Authorization (DDA)",
  },
  {
    optional: false,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8aff",
    name: "Initial Payment  (Credit Card/ Bank Slip/ Cheque/ Online Payment)",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8b00",
    name: "Supplementary Documents",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8b03",
    name: "External Transfer Form",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8b02",
    name: "Medical Report",
  },
  {
    optional: true,
    frontier: true,
    provider: true,
    _id: "6453504375b1112af17b8b01",
    name: "Others:",
  },
];

export const DATA_NUMBER_COMPARISON = [
  { value: "=", label: "= (Equal)" },
  { value: "<>", label: "<> (Between)" },
  { value: ">", label: "> (Larger)" },
  { value: ">=", label: ">= (Larger or Equal)" },
  { value: "<", label: "< (Smaller)" },
  { value: "<=", label: "<= (Smaller or Equal)" },
];

export const DATA_CALENDAR_EVENT_TYPE = [
  { value: "companyEvent", label: "Company Event" },
  { value: "inTraining", label: "Internal Training" },
  { value: "meeting", label: "Meeting" },
];

export const DATA_ROUND_ON = [
  { value: "RATE", label: "Rate" },
  { value: "MONEY", label: "Money" },
];

export const DATA_ADDRESS_TYPE = [
  { value: "residental", label: "Residental" },
  { value: "correspondence", label: "Correspondence" },
];

export const DATA_JURISDICTION = [
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Macau", label: "Macau" },
  { value: "Singapore", label: "Singapore" },
  { value: "Bermuda", label: "Bermuda" },
];

export const DATA_PRODUCT_STATUS = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "InActive" },
];

export const DATA_PRODUCT_AGETYPES = [
  { value: "PREVIOUS", label: "Previous dob" },
  { value: "NEXT", label: "Next dob" },

  { value: "NEAREST", label: "Nearest dob" },
];

export const DATA_RELATIONSHIP = [
  { value: "Single Person", label: "Single Person", group: "Other" },

  //Spouse
  { value: "Husband", label: "Husband", group: "Spouse" },
  { value: "Wife", label: "Wife", group: "Spouse" },

  //Parent
  { value: "Father", label: "Father", group: "Parent" },
  { value: "Mother", label: "Mother", group: "Parent" },
  { value: "Legal Guardian", label: "Legal Guardian", group: "Parent" },

  //Sibling
  { value: "Brother", label: "Brother", group: "Sibling" },
  { value: "Sister", label: "Sister", group: "Sibling" },

  //Child
  { value: "Son", label: "Son", group: "Child" },
  { value: "Daughter", label: "Daughter", group: "Child" },

  // Extended family
  { value: "Grandparent", label: "Grandparent", group: "Extended" },
  { value: "Grandfather", label: "Grandfather", group: "Extended" },
  { value: "Grandson", label: "Grandson", group: "Extended" },
  { value: "Granddaughter", label: "Granddaughter", group: "Extended" },
  { value: "Uncle", label: "Uncle", group: "Extended" },
  { value: "Aunt", label: "Aunt", group: "Extended" },
  { value: "Cousin", label: "Cousin", group: "Extended" },
  { value: "Nephew", label: "Nephew", group: "Extended" },
  { value: "Niece", label: "Niece", group: "Extended" },
];

export const DATA_OVERRIDING_LEVEL_TYPE = [
  { value: "upper", label: "Up Line" },
  { value: "userGroup", label: "User Group" },
  { value: "user", label: "User" },
];

export const DATA_MARIAGE_STATUS = [
  { value: "Single", label: "Single" },
  { value: "Married", label: "Married" },
  { value: "Divorced", label: "Divorced" },
  { value: "Widowed", label: "Widowed" },
  { value: "Undetermined", label: "Undetermined" },
];

//const PROJECT_STATUS = ["DFT", "CONFIRM", "CANCEL", "COMPLETED"];
export const DATA_PROJECT_STATUS = [
  { value: "DFT", label: "Draft" },
  { value: "CONFIRM", label: "Confirmed" },
  { value: "CANCEL", label: "Cancelled" },
  { value: "COMPLETED", label: "Completed" },
];

export const DATA_ID_TYPE = [
  { value: "ID No.", label: "ID No." },
  { value: "BR No.", label: "BR No." },
  { value: "Passport", label: "Passport" },
];

export const DATA_REPORT_DATE_TYPE = [
  { value: "submissionDate", label: "Submit Date" },
  { value: "signedDate", label: "Sign Date" },
];

export const DATA_REPORT_CLAIM_DATE_TYPE = [
  { value: "claimDate", label: "Claim Date" },
  { value: "transactionDate", label: "Transaction Date" },
  { value: "paidDate", label: "Paid Date" },
];

export const DATA_GENDERS = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

export const DATA_AGE_TYPES = [
  { value: "PREVIOUS", label: "Previous dob" },
  { value: "NEXT", label: "Next dob" },
  { value: "NEAREST", label: "Nearest dob" },
];

export const DATA_CLIENT_TYPES = [
  { label: "Direct", value: "DIRECT" },
  { label: "Channel", value: "CHANNEL" },
];

export const DATA_CLIENT_INDUSTRY = [
  {
    label: "Advertising and Marketing",
    value: "ADMARK",
    clabel: "廣告與市場營銷",
  },
  {
    label: "Agriculture",
    value: "AGRI",
    clabel: "農業",
  },
  {
    label: "Art and Design",
    value: "ARTDESIGN",
    clabel: "藝術與設計",
  },
  {
    label: "Automotive",
    value: "AUTO",
    clabel: "汽車",
  },
  {
    label: "Beauty and Cosmetics",
    value: "BEAUTY",
    clabel: "美容與化妝",
  },
  {
    label: "Biotechnology",
    value: "BIOTECH",
    clabel: "生物技術",
  },
  {
    label: "Construction",
    value: "CONS",
    clabel: "建築",
  },
  {
    label: "Consulting",
    value: "CONSULT",
    clabel: "諮詢",
  },
  {
    label: "Education",
    value: "EDU",
    clabel: "教育",
  },
  {
    label: "Energy",
    value: "ENERGY",
    clabel: "能源",
  },
  {
    label: "Engineering",
    value: "ENG",
    clabel: "工程",
  },
  {
    label: "Entertainment",
    value: "ENT",
    clabel: "娛樂",
  },
  {
    label: "Fashion",
    value: "FASH",
    clabel: "時尚",
  },
  {
    label: "Financial Services",
    value: "FIN",
    clabel: "金融服務",
  },
  {
    label: "Fitness and Wellness",
    value: "FITWELL",
    clabel: "健身與健康",
  },
  {
    label: "Food and Beverage",
    value: "FOODBEV",
    clabel: "食品與飲料",
  },
  {
    label: "Gaming",
    value: "GAMING",
    clabel: "遊戲",
  },
  {
    label: "Healthcare",
    value: "HC",
    clabel: "醫療保健",
  },
  {
    label: "Hospitality",
    value: "HOSP",
    clabel: "酒店與款待",
  },
  {
    label: "Information Technology",
    value: "IT",
    clabel: "信息技術",
  },
  {
    label: "Insurance",
    value: "INSUR",
    clabel: "保險",
  },
  {
    label: "Legal Services",
    value: "LEGAL",
    clabel: "法律服務",
  },
  {
    label: "Manufacturing",
    value: "MANUFACT",
    clabel: "製造業",
  },
  {
    label: "Media",
    value: "MEDIA",
    clabel: "媒體",
  },
  {
    label: "Music",
    value: "MUSIC",
    clabel: "音樂",
  },
  {
    label: "Nonprofit",
    value: "NGO",
    clabel: "非營利組織",
  },
  {
    label: "Pharmaceuticals",
    value: "PHARMA",
    clabel: "制藥",
  },
  {
    label: "Real Estate",
    value: "RE",
    clabel: "房地產",
  },
  {
    label: "Retail",
    value: "RTL",
    clabel: "零售",
  },
  {
    label: "Retail Technology",
    value: "RETAILTECH",
    clabel: "零售技術",
  },
  {
    label: "Sports",
    value: "SPORTS",
    clabel: "運動",
  },
  {
    label: "Telecommunications",
    value: "TELCO",
    clabel: "電信",
  },
  {
    label: "Transportation",
    value: "TRANS",
    clabel: "運輸",
  },
  {
    label: "Travel",
    value: "TRAVEL",
    clabel: "旅遊",
  },
  {
    label: "Other",
    value: "OTH",
    clabel: "其他",
  },
];

export const DATA_EMPLOYMENT_TYPES = [
  { label: "Full Time", value: "FULLTIME" },
  { label: "Part Time", value: "PARTIME" },
];

export const DATA_RANK_BY = [
  { label: "Case", shortName: "CASE", value: "BY_CASE" },
  { label: "First Year Premium", shortName: "FYP", value: "BY_FYP" },
  {
    label: "First Year Commission (Consultant)",
    shortName: "FYC",
    value: "BY_FYCC",
  },
];

export const DATA_LANGUAGE = [
  { value: "CANTONESE", label: "Cantonese" },
  { value: "MANDARINE", label: "Mandarine" },
  { value: "ENGLISH", label: "English" },
  { value: "JAPANESE", label: "Japnanese" },
  { value: "FRENCH", label: "French" },
];

export const DATA_PAY_MODES = [
  { value: "MONTHLY", label: "Monthly" },
  { value: "QUARTERLY", label: "Quarterly" },
  { value: "SEMIANNUALLY", label: "Semi Year" },
  { value: "ANNUALLY", label: "Annually" },
  { value: "LUMPSUM", label: "Lump Sum" },
];
export const DATA_ALLOWANCE_PAY_MODES = [
  { value: "WEEKLY", label: "Weekly" },
  { value: "MONTHLY", label: "Monthly" },
  { value: "QUARTERLY", label: "Quarterly" },
  { value: "ANNUALLY", label: "Annually" },
];

export const DATA_USER_TYPES = [
  { value: "BACKOFFICE", label: "Back Office" },
  { value: "CONSULTANT", label: "Consultant" },
  { value: "CHANNEL", label: "Channel" },
];

export const DATA_COUNTRY = [
  { value: "Australia", label: "Australia" },
  { value: "Canada", label: "Canada" },
  { value: "China", label: "China" },
  { value: "Germany", label: "Germany" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "India", label: "India" },

  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Japan", label: "Japan" },
  { value: "Macau", label: "Macau" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Pakistan", label: "Pakistan" },

  { value: "Singapore", label: "Singapore" },
  { value: "South Korea", label: "South Korea" },

  { value: "Taiwan", label: "Taiwan" },
  { value: "Thailand", label: "Thailand" },

  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "Vietnam", label: "Vietnam" },

  { value: "Other", label: "Other" },
];

export const DATA_SALUATATION = [
  { value: "Mr.", label: "Mr." },
  { value: "Ms.", label: "Ms." },
  { value: "Mrs.", label: "Mrs." },
  { value: "", label: "--" },
];

export const DATA_NATIONALITY = [
  { value: "Australian", label: "Australian" },
  { value: "British", label: "British" },
  { value: "Canadian", label: "Canadian" },
  { value: "Chinese", label: "Chinese" },
  { value: "German", label: "German" },
  { value: "Indonesian", label: "Indonesian" },
  { value: "Indian", label: "Indian" },
  { value: "Japanese", label: "Japanese" },
  { value: "Malaysian", label: "Malaysian" },
  { value: "New Zealander", label: "New Zealander" },
  { value: "Pakistani", label: "Pakistani" },
  { value: "Singaporean", label: "Singaporean" },
  { value: "South Korean", label: "South Korean" },
  { value: "Taiwanese", label: "Taiwanese" },
  { value: "Thai", label: "Thai" },
  { value: "US", label: "United States" },
  { value: "Vietnamese", label: "Vietnamese" },
  { value: "Other", label: "Other" },
];

export const DATA_MAIL_TYPE = [
  { value: "Email", lable: "Email" },
  { value: "Post", lable: "Post" },
  { value: "Email & Post", lable: "Email & Post" },
  { value: "None", lable: "None" },
];

export const DATA_EDUCATION_LEVEL = [
  { value: "Primary level or below", label: "Primary level or below" },
  { value: "Secondary", label: "Secondary" },
  { value: "Non-Degree Tertiary", label: "Non-Degree Tertiary" },
  { value: "University or above", label: "University or above" },
];

export const DATA_TICKET_ASSIGN_METHODS = [
  { value: "ALL", label: "All Memebers" },
  { value: "Random Member", label: "Random member" },
  { value: "Lesser Case", label: "Less Case Member" },
];

export const DATA_QUO_REQUEST_STATUS = [
  { value: "DFT", label: "Draft" },
  { value: "CAN", label: "Cancelled" },
  { value: "DONE", label: "Quotation Done" },
  { value: "CONFIRM", label: "Confirmed by Client" },
  { value: "COPIEDDN", label: "Debit Note Done" },
];

export const DATA_DN_STATUS = [
  { value: "DFT", label: "Draft" },
  { value: "WAIT", label: "Waiting Payment" },
  { value: "CAN", label: "Cancelled" },
  { value: "PAID", label: "Paid" },
  { value: "SETTLED", label: "Settled" },
  { value: "EXP", label: "Expired" },
];

export const DATA_QUO_STATUS = [
  { value: "DFT", label: "Draft" },
  { value: "WAIT", label: "Waiting Confrim" },
  { value: "CANCEL", label: "Cancelled" },
  { value: "CONFIRM", label: "Confirmed" },
];

export const DATA_ITEM_ASSIGN_METHOD = [
  { value: "TILE_2D_BY_LEAST_NUM", label: "2D Tile (WxD) - Allow Rotate" },
  {
    value: "TILE_2D_BY_LEAST_NUM_NO_ROTATE",
    label: "2D Tile (WxD) - No Rotate",
  },
  { value: "TILE_2D_BY_LEAST_NUM_W_H", label: "2D Tile (WxH) - Allow Rotate" },
  {
    value: "TILE_2D_BY_LEAST_NUM_NO_ROTATE_W_H",
    label: "2D Tile (WxH) - No Rotate",
  },
  { value: "TILE_2D_BY_LEAST_NUM_D_H", label: "2D Tile (DxH) - Allow Rotate" },
  {
    value: "TILE_2D_BY_LEAST_NUM_NO_ROTATE_D_H",
    label: "2D Tile (DxH) - No Rotate",
  },

  { value: "TILE_2D_BY_LEAST_NUM_H_W", label: "2D Tile (HxW) - Allow Rotate" },
  {
    value: "TILE_2D_BY_LEAST_NUM_NO_ROTATE_H_W",
    label: "2D Tile (HxW) - No Rotate",
  },

  { value: "TILE_1D_BY_LEAST_NUM", label: "1D Tile (W)" },
  { value: "MATCH_TO_COVER_H", label: "Match to Cover (H)" },
  { value: "ANY", label: "Any Item" },
];

export const DATA_ORDER_STATUS = [
  { value: "DFT", label: "Draft" },
  { value: "CAN", label: "Cancelled" },
  { value: "RCV", label: "Received from consultant" },
  { value: "SUB", label: "Submitted to provider" },
  { value: "PND", label: "In Pending" },
  { value: "AIP", label: "Application in Process" },
  { value: "ACT", label: "ACT" },

  { value: "INF", label: "Inforced" },
  { value: "UNKNOWN", label: "Unknown" },

  { value: "SUS", label: "Payment Suspended" },
  { value: "SUR", label: "Surrender" },
  { value: "LAP", label: "Lapsed" },
  { value: "COL", label: "Cool Off" },
  { value: "REJ", label: "Rejected By Provider" },
  { value: "WTH", label: "Withdraw Application" },
  { value: "TRO", label: "Transfered out" },
  { value: "TER", label: "Terminated" },
  { value: "EXP", label: "Expired" },

  { value: "OTH", label: "Other" },
];

export const DATA_TERMINATION_TYPE = [
  { value: "SUR", label: "Surrender" },
  { value: "LAP", label: "Lapsed" },
  { value: "COL", label: "Cool Off" },
  { value: "REJ", label: "Rejected By Provider" },
  { value: "WTH", label: "Withdraw Application" },
  { value: "TRO", label: "Transfer Out" },
  { value: "CAN", label: "Cancelled" },
];

export const DATA_POLICY_DELIVER_METHODS = [
  { value: "Electronic", label: "Electronic" },
  { value: "By Provider", label: "By Provider" },
  { value: "By Froniter", label: "By Froniter" },
];

export const DATA_ORDER_TYPE = [
  { label: "New Biz", value: "NEW" },
  { label: "Transfer In", value: "TRANSFER" },
];

export const DATA_PAYMENT_METHOD = [
  { value: "DDA", label: "DDA AutoPay" },
  { value: "Bank Transfer", label: "Bank Transfer" },
  { value: "Cash", label: "Cash" },
  { value: "Cheque", label: "Cheque" },
  { value: "Credit Card", label: "Credit Card" },
  { value: "FPS", label: "FPS" },
  { value: "Online Banking", label: "Online Banking" },
  { value: "No Payment", label: "No Payment" },
  { value: "Others", label: "Others" },
];

export const DATA_CONSULTANT_PAYMENT_METHOD = [
  { value: "Bank Transfer", label: "Bank Transfer" },
  { value: "Cash", label: "Cash" },
  { value: "Cheque", label: "Cheque" },
];

export const DATA_BANK = [
  { value: "003", label: "Standard Chartered Hong Kong 渣打銀行(香港)" },
  {
    value: "004",
    label: "Hongkong and Shanghai Banking Corporation 香港上海滙豐銀行",
  },
  {
    value: "009",
    label: "China Construction Bank (Asia) 中國建設銀行(亞洲)",
  },
  { value: "012", label: "Bank of China (Hong Kong) 中國銀行(香港)" },
  { value: "015", label: "Bank of East Asia 東亞銀行" },
  { value: "016", label: "DBS Bank (Hong Kong) 星展銀行(香港)" },
  { value: "018", label: "China CITIC Bank International 中信銀行國際" },
  { value: "020", label: "CMB Wing Lung Bank 招商永隆銀行" },
  { value: "024", label: "Hang Seng Bank 恒生銀行" },
  { value: "025", label: "Shanghai Commercial Bank 上海商業銀行" },
  {
    value: "027",
    label: "Bank of Communications (Hong Kong) 交通銀行(香港)",
  },
  { value: "028", label: "Public Bank (Hong Kong) 大眾銀行(香港)" },
  { value: "035", label: "OCBC Wing Hang Bank 華僑永亨銀行" },
  { value: "038", label: "Tai Yau Bank 大有銀行" },
  { value: "039", label: "Chiyu Banking Corporation 集友銀行" },
  { value: "040", label: "Dah Sing Bank 大新銀行" },
  { value: "041", label: "Chong Hing Bank 創興銀行" },
  { value: "043", label: "Nanyang Commercial Bank 南洋商業銀行" },
  { value: "061", label: "Tai Sang Bank 大生銀行" },
  {
    value: "072",
    label: "Industrial and Commercial Bank of China (Asia) 中國工商銀行(亞洲)",
  },
  { value: "128", label: "Fubon Bank (Hong Kong) 富邦銀行(香港)" },
  { value: "250", label: "Citibank (Hong Kong) Limited" },
  { value: "385", label: "PING AN BANK CO., LTD." },
  { value: "387", label: "ZA BANK LIMITED" },
  { value: "388", label: "LIVI VB LIMITED" },
  { value: "389", label: "MOX BANK LIMITED" },
  { value: "390", label: "WELAB BANK LIMITED" },
  { value: "391", label: "FUSION BANK LIMITED" },
  { value: "392", label: "PING AN ONECONNECT BANK (HONG KONG) LIMITED" },
  { value: "393", label: "ANT BANK (HONG KONG) LIMITED" },
  { value: "395", label: "AIRSTAR BANK LIMITED" },
];
