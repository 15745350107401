import {
  Text,
  Title,
  createStyles,
  Button,
  Group,
  Space,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import React, { useState, useEffect } from "react";

import { IconPlus } from "@tabler/icons-react";
import { useViewportSize } from "@mantine/hooks";

// function PageListHeader( {title, handleAdd, handleRefresh, handleClose}){
function PageListHeader({ title, actionButtons }) {
  const [isSticky, setIsSticky] = useState(false);
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme();
  const { width, height } = useViewportSize();

  const isMobile = width < 768;
  useEffect(() => {
    const handleScroll = () => {
      const isScrolling = window.scrollY > 0;
      setIsSticky(isScrolling);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      style={{
        position: !(isMobile && isSticky) ? "sticky" : "static",
        top: 0,
        background:
          colorScheme === "dark"
            ? theme.background?.dark
            : theme.background?.light,
        zIndex: 100,
      }}
    >
      <Group justify="space-between" wrap="nowrap">
        <Title lineClamp={1}>{title}</Title>
        <Group wrap="nowrap">
          {actionButtons.map(({ onClick, label, variant = "filled" }) => (
            <Button size="xs" key={label} onClick={onClick} variant={variant}>
              {label}
            </Button>
          ))}
        </Group>
      </Group>
      <Space h="lg" />
    </div>
  );
}

export default PageListHeader;
