import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil } from "@tabler/icons-react";
import { useAuthUser } from "react-auth-kit";
import { Text } from "@mantine/core";

export const CalendarEvent = (cellRender) => {
  return {
    showActionColumn: true,
    searchByTextField: true,
    searchableFields: ["type", "title", "description"],
    columns: [
      {
        field: "from",
        headerName: "Date",
        width: 200,
        cellRender: (col, data) => (
          <>
            <Text size="xs">
              {moment(data.from).format("YYYY-MM-DD hh:mm")}
            </Text>
            <Text c="dimmed" size="xs">
              {data.to ? moment(data.to).format("YYYY-MM-DD hh:mm") : ""}
            </Text>
          </>
        ),

        sortable: true,
      },

      {
        field: "type",
        headerName: "Type",
        sortable: true,
        cellRender: (col, data) => data.type?.toUpperCase(),
      },

      {
        field: "title",
        headerName: "Title",
        sortable: true,
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
      },
    ],
  };
};
