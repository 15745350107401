import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil } from "@tabler/icons-react";
import { useAuthUser } from "react-auth-kit";

export const ClaimTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    searchByTextField: true,
    searchableFields: [
      "name",
      "user.name",
      "user.cname",
      "user.aliasName",
      "user.staffId",
      "claimType.name",
      "code",
      "status",
    ],
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },

      {
        field: "claimType",
        headerName: "Type",
        sortable: true,
        cellRender: (column, data) => data.claimType?.name,
      },
      {
        field: "project",
        headerName: "Project",
        sortable: true,
        cellRender: (column, data) => data.project?.name,
      },

      {
        field: "transactionDate",
        headerName: "Transaction Date",
        cellRender: (col, data) => moment(data?.transactionDate).format("LL"),
        sortable: true,
      },
      {
        field: "claimDate",
        headerName: "Claim Date",
        cellRender: (col, data) => moment(data?.claimDate).format("LL"),
        sortable: true,
      },

      {
        field: "amount",
        headerName: "Amount",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.amount),
      },
      {
        field: "user",
        headerName: "Claim By",
        sortable: true,
        cellRender: (column, data) => cellRender.User(data.user),
      },
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
      },
    ],
  };
};

export const ProjectClaimTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    searchableFields: ["code", "name", "type", "code", "status"],
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },
      {
        field: "claimType",
        headerName: "Type",
        sortable: true,
        cellRender: (column, data) => data.claimType?.name,
      },

      {
        field: "amount",
        headerName: "Amount",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.amount),
      },
      {
        field: "user",
        headerName: "Claim By",
        sortable: true,
        cellRender: (column, data) => cellRender.User(data.user),
      },
      {
        field: "status",
        headerName: "Status",
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
      },
    ],
  };
};

export const MyClaimTableSchema = (cellRender) => {
  const auth = useAuthUser();
  const userId = auth().id;
  return {
    showActionColumn: true,
    searchableFields: ["code", "name", "type", "code", "status"],
    preQuery: { user: userId },
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },

      {
        field: "claimType",
        headerName: "Type",
        sortable: true,
        cellRender: (column, data) => data.claimType?.name,
      },

      {
        field: "project",
        headerName: "Project",
        sortable: true,
        cellRender: (column, data) => data.project?.name,
      },

      {
        field: "transactionDate",
        headerName: "Transaction Date",
        cellRender: (col, data) => moment(data?.transactionDate).format("LL"),
        sortable: true,
      },
      {
        field: "claimDate",
        headerName: "Claim Date",
        cellRender: (col, data) => moment(data?.claimDate).format("LL"),
        sortable: true,
      },

      {
        field: "amount",
        headerName: "Amount",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.amount),
      },
      {
        field: "user",
        headerName: "Claim By",
        sortable: true,
        cellRender: (column, data) => cellRender.User(data.user),
      },
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
      },
    ],
  };
};
