import {
  Button,
  Group,
  Accordion,
  Avatar,
  Text,
  Table,
  Checkbox,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import _ from "lodash";
import { useServerApi } from "../hooks/useServerApi";
import { useCellRender } from "../hooks/useCellRender";
import JsonViewer from "./common/jsonViewer";
import moment from "moment";
import { useForceUpdate } from "@mantine/hooks";
import { useFormNotification } from "../layout/page";

const ClaimSettlementHeader = () => {
  return (
    <Table.Thead>
      <Table.Tr>
        <Table.Th></Table.Th>
        <Table.Th>Date</Table.Th>
        <Table.Th>Code</Table.Th>
        <Table.Th>Status</Table.Th>
        <Table.Th>Type</Table.Th>
        <Table.Th>Project</Table.Th>
        <Table.Th>Amount</Table.Th>
      </Table.Tr>
    </Table.Thead>
  );
};
const ClaimSettlementControl = ({ item }) => {
  const [cellRender] = useCellRender();

  return (
    <Group justify="space-between">
      {cellRender.User(item.user)}
      <Text>{item?.rows?.filter((r) => r.selected).length} claims</Text>
      <Text>{cellRender.Currency(item.amount)}</Text>
    </Group>
  );
};

const ClaimSettlementRow = ({ row, key, updateClaimAmount, status }) => {
  const { claim } = row;
  const forceUpdate = useForceUpdate();
  const [formatter] = useCellRender();

  return (
    <Table.Tr>
      <Table.Td>
        <Checkbox
          checked={row.selected}
          disabled={status !== "Pending"}
          onChange={(e) => {
            console.log("row.selected", row, e.target.checked, key);
            row.selected = e.target.checked;
            forceUpdate();
            updateClaimAmount();
          }}
        />
      </Table.Td>

      <Table.Td>
        {row.claim?.transactionDate
          ? moment(row.claim?.transactionDate).format("YYYY-MM-DD")
          : "-"}
      </Table.Td>
      <Table.Td>{row.claim?.code}</Table.Td>
      <Table.Td>{row.claim?.status}</Table.Td>
      <Table.Td>{row.claim?.claimType.name}</Table.Td>

      <Table.Td>{row.claim?.project?.name}</Table.Td>
      <Table.Td>{formatter.Currency(row.claim?.amount)}</Table.Td>
    </Table.Tr>
  );
};
const ClaimSettlementItems = ({ form, claimSettlements, mt = "xl" }) => {
  const { t } = useTranslation();
  const [cellRender] = useCellRender();

  const updateClaimAmount = () => {
    const claimSettlements = form.values.claimSettlements;
    let totalAmount = 0;
    let totalCount = 0;
    claimSettlements.forEach((cs) => {
      cs.amount = 0;
      cs.rows.forEach((row) => {
        if (row.selected) {
          cs.amount += row.claim.amount;
          totalCount++;
        }
      });
      totalAmount += cs.amount;
    });
    form.setFieldValue("claimSettlements", claimSettlements);
    form.setFieldValue("amount", totalAmount);
    form.setFieldValue("count", totalCount);
  };

  const status = _.get(form.values, "status") ?? "";

  return (
    <>
      {/* <JsonViewer src={claimSettlements} name="claimSettlements" /> */}
      {/* <JsonViewer src={form.values} name={"form values"} /> */}
      {claimSettlements && (
        <Accordion variant="separated" mt={mt}>
          {claimSettlements?.map((item, key) => (
            <Accordion.Item key={key} value={key + "-s"}>
              <Accordion.Control>
                <ClaimSettlementControl item={item} />
              </Accordion.Control>

              <Accordion.Panel>
                <Table>
                  <ClaimSettlementHeader />
                  {item.rows?.map((row, k) => (
                    <ClaimSettlementRow
                      row={row}
                      key={k + "-r"}
                      updateClaimAmount={updateClaimAmount}
                      status={status}
                    />
                  ))}
                </Table>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </>
  );
};

const ClaimSelttlementList = ({ form }) => {
  const { t } = useTranslation();
  const [api] = useServerApi();
  const [notifyFormSuccess, notifyFormError] = useFormNotification();

  const claimSettlements = _.get(form.values, "claimSettlements") ?? [];
  const status = _.get(form.values, "status") ?? "";

  const confirmClaim = async () => {
    try {
      if (!window.confirm("Are you sure you want to confirm the claims?"))
        return;

      if (!form.values || !form.values._id) return;
      await form.save();

      const result = await api.ClaimSettlement.confirmClaim(form.values._id);

      if (!result || !result.success)
        throw "Claim Settlement can't be confirmed";
      const { settlement } = result.data;

      form.setFieldValue("status", settlement.status);

      if (result.success) {
        notifyFormSuccess({
          title: "Claim Confirmed Success",
          message: "Claims have been confirmed and paid",
        });
      }
    } catch (error) {
      console.log(error);
      notifyFormError({
        title: "Claim Confirmed Fail",
        message: "Claims have been confirmed and paid",
      });
    }
  };

  const downloadReport = async () => {
    try {
      await form.save();
      const result = await api.ClaimSettlement.downloadReport(form.values._id);
      if (!result || !result.success || !result.data)
        throw "Report download failed";
      // console.log(result);
      const { url, settlement } = result.data;

      console.log("downloadReport >settlement", settlement);
      window.open(url, "_blank");
      notifyFormSuccess({
        title: "Report Download Success",
        message: "Report downloaod sucessfully",
      });
    } catch (error) {
      console.log(error);
      notifyFormError({
        title: "Download report failed",
        message: "Report download failed",
      });
    }
  };

  const fetchPendingClaims = async () => {
    // Fetch claim settlements
    try {
      const result = await api.search({
        apiEntity: "claim",
        pageSize: 1000000,
        searchQuery: {
          status: "Pending",
        },
      });

      if (!result || !result.docs) throw "No claims found";

      const { docs: claims } = result;
      // console.log("fetchPendingClaims", claims);

      const grouped = _.groupBy(claims, "user._id");
      // console.log("claimSettlements", grouped);

      //Set data
      const cs = [];
      Object.keys(grouped).forEach((key) => {
        const userClaims = grouped[key];
        const totalAmount = userClaims.reduce((acc, claim) => {
          return acc + claim.amount;
        }, 0);
        cs.push({
          user: userClaims[0].user,
          claims: userClaims,
          rows: userClaims.map((claim) => ({
            claim,
            selected: true,
          })),
          amount: totalAmount,
        });
      });
      // console.log("cs", cs);
      // form.setFieldValue("claimSettlements", cs);
      // form.setFieldValue(
      //   "amount",
      //   cs.reduce((acc, c) => acc + c.amount, 0)
      // );
      // form.setFieldValue("count", claims.length);
      await form.updateAndSave({
        status: "Pending",
        amount: cs.reduce((acc, c) => acc + c.amount, 0),
        count: claims.length,
        claimSettlements: cs,
      });

      // console.log(form.values);
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDisabled = claimSettlements.length === 0 || status === "Paid";

  return (
    <>
      {/* <JsonViewer src={claimSettlements} /> */}
      <Group justify="right" gap={"xs"}>
        <Button
          variant="default"
          size="xs"
          onClick={() => fetchPendingClaims(form)}
          disabled={status !== "Pending"}
        >
          {t("Get Pending Claims")}
        </Button>
        <Button variant="default" size="xs" onClick={() => downloadReport()}>
          {t("Download Report")}
        </Button>
        <Button
          size="xs"
          color="red"
          onClick={() => confirmClaim()}
          disabled={confirmDisabled}
        >
          {t("Confirm Claims")}
        </Button>
      </Group>

      <ClaimSettlementItems claimSettlements={claimSettlements} form={form} />
    </>
  );
};

export default ClaimSelttlementList;
