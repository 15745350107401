import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import RemarkList from "../../components/remarkList";
import FileList from "../../components/fileList";
import ActivityLog from "../../components/activityLog";
import QuotationProjectDetail from "../../components/quotationProjectDetail";
import Terms from "../../components/terms";
import { DateInput } from "@mantine/dates";
import DataSelect from "../../components/dataSelect";
import remoteSelect2 from "../../components/remoteSelect2";
import {
  InputWrapper,
  TextInput,
  Textarea,
  Text,
  Box,
  Group,
} from "@mantine/core";
import AutoCodeInput from "../../components/autoCodeInput";
import QuotationProjectSection from "../../components/quotationProjectSection";
import InvoiceActionList from "../../components/invoiceActionList";
import DummyWidget from "../../components/dummyWidget";
import { useCellRender } from "../../hooks/useCellRender";
import InvoiceItems from "../../components/invoiceItems";
import moment from "moment";
import InvoicePaymentHistory from "../../components/invoicePaymentHistory";
import PaymentHistory from "../../components/paymentHistory";

const initialValues = {};

const apiEntity = "invoice";

export const InvoiceAddForm = {
  name: "addSchema",
  title: "New Invoice",
  description: "Please follow the step to create a New Invoice",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "1000px", //xs, sm, xl  ....100%
  },

  apiEntity,
  initialValues,

  layout: {
    containers: [],
    fields: [],
  },
};

export const InvoiceEditForm = {
  name: "editSchema",
  title: "Edit Invoice",
  titleField: "code",
  description: "Please follow the step to edit invoice",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
  },

  apiEntity,
  initialValues,
  layout: {
    containers: [
      {
        key: "main-g",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "main-g1",
            props: {
              span: 3,
            },
          },
          {
            key: "main-g2",
            props: {
              span: 9,
            },
          },
        ],
      },
      {
        key: "main-tab",
        parent: "main-g2",
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Overview",
        },
        tab: [
          {
            key: "tab-overview",
            label: "Overview",
          },
          {
            key: "tab-payment",
            label: "Payment",
          },
          {
            key: "tab-file",
            label: "Files",
          },
          {
            key: "tab-log",
            label: "Logs",
          },
        ],
      },

      {
        key: "section-basic",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Basic Information",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },

      {
        key: "section-detail",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Details",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },

      {
        key: "section-payments",
        parent: "tab-payment",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Payments",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },

      {
        key: "detail-tab",
        parent: "section-basic",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "basic-g1",
            props: {
              span: 4,
            },
          },
          {
            key: "basic-g2",
            props: {
              span: 4,
            },
          },
          {
            key: "basic-g3",
            props: {
              span: 4,
            },
          },
        ],
      },

      {
        key: "section-term",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Terms",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-remarks",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Remarks",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-file",
        parent: "tab-file",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Files",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-log",
        parent: "tab-log",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Logs",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
    ],
    fields: [
      {
        name: "project",
        component: QuotationProjectDetail,
        parent: "main-g1",
        props: {
          title: "Project Details",
        },
      },
      {
        name: "code",
        component: TextInput,
        parent: "basic-g1",
        props: {
          label: "Code (Auto)",
          // dummyRender: (data, cellRender) => {
          //   return (
          //     <InputWrapper label="Status" mb={"xs"}>
          //       <Group align="center">
          //         <Text>{data.code}</Text>
          //         <Box>{cellRender.Invoice.status(data?.status)}</Box>
          //       </Group>
          //     </InputWrapper>
          //   );
          // },
        },
      },

      {
        name: "invDate",
        component: DateInput,
        parent: "basic-g2",
        type: "dateInput",
        props: {
          label: "Invoice Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
          defaultValue: new Date(),
        },
      },
      {
        name: "dueDate",
        component: DateInput,
        parent: "basic-g3",
        type: "dateInput",
        props: {
          label: "Due Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
          defaultValue: moment().add(14, "days").toDate(),
        },
      },
      {
        name: "project",
        component: remoteSelect2,
        parent: "basic-g1",
        props: {
          label: "Project",
          apiEntity: "project",
          valueField: "_id",
          labelField: "name",
          searchFields: ["name", "code"],
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },

      {
        name: "customer",
        component: remoteSelect2,
        parent: "basic-g2",
        props: {
          label: "Payer",
          apiEntity: "client",
          valueField: "_id",
          labelField: "name",
          searchFields: ["name", "cname", "code"],
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },

      {
        name: "contactPerson",
        component: DataSelect,
        parent: "basic-g3",
        props: {
          label: "Contact Person",
          sourceField: "customer.contactPersons",
          valueField: "_id",
          labelField: "name",

          sourceId: "customer._id",
          sourceApiEntity: "client",
          sourceApiField: "contactPersons",
          clearable: true,

          labelRender: (data) => data?.name,
        },
      },
      {
        name: "status",
        component: DummyWidget,
        parent: "basic-g1",
        props: {
          label: "Status",
          dummyRender: (data, cellRender) => {
            return (
              <InputWrapper label="Status" mb={"xs"}>
                <Group align="center">
                  <Box>{cellRender.Invoice.status(data?.status)}</Box>
                </Group>
              </InputWrapper>
            );
          },
        },
      },
      {
        name: "rows",
        component: InvoiceItems,
        parent: "section-detail",
        props: {},
      },
      {
        name: "payments",
        component: PaymentHistory,
        parent: "section-payments",
        props: {},
      },

      {
        name: "terms",
        component: Terms,
        parent: "section-term",
        props: {
          templateCategory: "Invoice Terms",
        },
      },
      {
        name: "remarks",
        component: RemarkList,
        parent: "section-remarks",
      },

      {
        name: "files",
        component: FileList,
        parent: "section-file",
      },
      {
        name: "log",
        component: ActivityLog,
        parent: "section-log",
      },
      {
        name: "actions",
        component: InvoiceActionList,
        parent: "main-g1",
        props: {},
      },
    ],
  },
};
