import _ from "lodash";
import {
  Text,
  Title,
  Divider,
  Group,
  ThemeIcon,
  Badge,
  ActionIcon,
  UnstyledButton,
  Button,
} from "@mantine/core";
import React from "react";
import moment from "moment";
import InfoGridList from "./infoGridList";
import { useCellRender } from "../hooks/useCellRender";

import {
  IconClick,
  IconCash,
  IconHeart,
  IconEdit,
  IconEditCircle,
} from "@tabler/icons-react";
import { useFormRender } from "../hooks/useFormRender";
import { useFormSchema } from "../hooks/useFormSchema";
import ItemBasicEditButton from "./itemBasicEditButton";

export default function ItemBasicInfo({ form }) {
  const [cellRender] = useCellRender();
  const item = form.values;
  const emptyStr = "-";
  const fields = [
    { title: "Code", value: item.code ?? emptyStr },
    { title: "Name", value: item.name ?? emptyStr },
    { title: "Chinese Name", value: item.cname ?? emptyStr },
    { title: "Category", value: item.category?.name ?? emptyStr },
    { title: "Unit", value: item.unitsOfMeasure ?? emptyStr },
    { title: "Description", value: item.description ?? emptyStr },
    { title: "Width", value: item.dimensions?.width ?? emptyStr },
    { title: "Height", value: item.dimensions?.height ?? emptyStr },
    { title: "Depth", value: item.dimensions?.depth ?? emptyStr },
    {
      title: "Daily Use Cost ",
      value: cellRender.Currency(item.dailyCost) ?? emptyStr,
    },
    {
      title: "Single use Cost ",
      value: cellRender.Currency(item.singleCost) ?? emptyStr,
    },
  ];

  return (
    item && (
      <>
        <InfoGridList fields={fields} values={item} mb="xl" />
        {/* <ItemBasicEditButton mainForm={form} /> */}
      </>
    )
  );
}
