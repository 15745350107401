import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useServerApi } from "../hooks/useServerApi";
import JsonViewer from "./common/jsonViewer";
import { Group, Text, Select, TagsInput } from "@mantine/core";

const ItemOptions = ({ form, name }) => {
  const cat = _.get(form.values, "category");
  const [api] = useServerApi();

  //   const fetch = async (catId) => {
  //     // fetch data
  //     const cat = await api.getById({
  //       id: catId,
  //       model: "ItemCategory",
  //     });
  //     console.log(cat);

  //     if (!cat) return;
  //     setCat(cat);
  //   };

  //   useEffect(() => {
  //     if (!catId) return;

  //     fetch();
  //   }, [catId]);

  return (
    <div>
      {/* <JsonViewer src={form.values} /> */}
      {/* catId:{cat?._id}
      {cat?.name}  */}

      {cat &&
        cat.options &&
        cat.options.map((option, index) => {
          return (
            <span>
              {/* {name} {index} */}
              <Select
                label={option.name}
                {...form.getInputProps(`${name}.${index}.value`)}
                data={option.tags.map((tag) => tag)}
              />
            </span>
          );
        })}
    </div>
  );
};

export default ItemOptions;
