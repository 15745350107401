import React from "react";
import { PageList } from "../components/pageList";
import { ClaimTableSchema } from "../schema/table/claim";
import { ClaimForm } from "../schema/form/claim";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";
import { useAuthUser } from "react-auth-kit";

const MyClaim = ({ title = "My Claim" }) => {
  const [getTableSchema] = useTableSchema();
  const [getFormSchema] = useFormSchema();

  const auth = useAuthUser();
  const userId = auth().id;

  const form = getFormSchema("claim");
  form.initialValues.user = userId;

  return (
    <>
      {/* userId : {userId} */}
      <PageList
        title={title}
        apiEntity={"claim"}
        tableSchema={getTableSchema("myClaim")}
        formSchema={form}
        showImport={false}
        initSort={{ by: "code", order: "desc" }}
        searchByTextField={true}
        // stats={{
        //   component: ProjecPageStats,
        //   props: {
        //     mb: 35,

        //   },
        // }}
      />
    </>
  );
};

export default MyClaim;
