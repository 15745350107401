import React, { useRef, useState } from "react";
import {
  TextInput,
  Grid,
  Select,
  Group,
  Text,
  Button,
  TagsInput,
} from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import RemoteSelect2 from "./remoteSelect2";
import { DATA_ITEM_ASSIGN_METHOD } from "../data/options";
import OrderList from "./orderList";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { TimeInput } from "@mantine/dates";
import _ from "lodash";
import JsonViewer from "./common/jsonViewer";

const ItemCateogryOptionsRow = ({ item, index, form, name }) => {
  return (
    <Grid>
      <Grid.Col span={"auto"}>
        <TextInput
          placeholder="Option Name"
          fw={true}
          {...form.getInputProps(`${name}.${index}.name`)}
        />
      </Grid.Col>
      <Grid.Col span={"auto"}>
        <TagsInput data={[]} {...form.getInputProps(`${name}.${index}.tags`)} />
      </Grid.Col>
    </Grid>
  );
};

export default ItemCateogryOptionsRow;
