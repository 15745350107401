import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";

import Terms from "../../components/terms";
import { DateInput } from "@mantine/dates";
import remoteSelect2 from "../../components/remoteSelect2";
import { InputWrapper, TextInput, Textarea, Text, Box } from "@mantine/core";

export const ProjectInvoiceSchema = {
  title: "Invoice",
  isMainForm: false,
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "md", //xs, sm, xl  ....100%
    position: "right",
  },
  apiEntity: "invoice",

  initialValues: {
    terms: [{ title: "", detail: "" }],
  },
  closeAfterSave: true,
  layout: {
    fields: [
      {
        name: "invDate",
        component: DateInput,
        type: "dateInput",
        props: {
          label: "Invoice Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
          defaultValue: new Date(),
        },
      },
      {
        name: "project",
        component: remoteSelect2,
        props: {
          label: "Project",
          apiEntity: "project",
          valueField: "_id",
          labelField: "name",
          searchFields: ["name", "code"],
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },
      {
        name: "customer",
        component: remoteSelect2,
        props: {
          label: "Customer",
          apiEntity: "client",
          valueField: "_id",
          labelField: "name",
          searchFields: ["name", "cname", "code"],
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },
      {
        name: "client",
        component: remoteSelect2,
        props: {
          label: "Client",
          apiEntity: "client",
          valueField: "_id",
          labelField: "name",
          searchFields: ["name", "cname", "code"],
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },
    ],
  },
};
