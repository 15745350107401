import React, { useState } from "react";
import { Button } from "@mantine/core";
import { useServerApi } from "../hooks/useServerApi";
import { useTranslation } from "react-i18next";
import { showNotification } from "@mantine/notifications";

const InvoicePrintButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const [printing, setPrinting] = useState(false);
  const { t } = useTranslation();
  const print = async () => {
    try {
      const { _id } = mainForm.values;
      if (!_id) {
        showNotification({
          title: `Please save the new invoice first.`,
          message: "No ID found.",
        });

        return;
      }
      await mainForm.save();

      setPrinting(true);
      const result = await api.Invoice.print(_id);
      setPrinting(false);
      // console.log(result);

      if (!result || !result.data || !result.data.url) return;
      window.open(result.data.url);
      if (result.data.invoice.status)
        mainForm.setFieldValue("status", result.data.invoice.status);
    } catch (error) {
      console.log(error);
      setPrinting(false);
    }
  };

  return (
    <Button
      fullWidth
      onClick={() => print()}
      loading={printing}
      variant="default"
    >
      {printing ? t("Printing") : t("Print")}
    </Button>
  );
};

export default InvoicePrintButton;
