import React, { useEffect, useRef, useState } from "react";
import { useForceUpdate } from "@mantine/hooks";
import RemoteSelect2 from "./remoteSelect2";
import {
  Group,
  NumberInput,
  TextInput,
  Select,
  ScrollArea,
} from "@mantine/core";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { DATA_UNIT_OF_MEASURE } from "../data/options";
import { useServerApi } from "../hooks/useServerApi";
import JsonViewer from "./common/jsonViewer";
import _ from "lodash";
// import ReactJson from "react-json-view";

const ProductionItemListRow = ({ item, index, form, name, data }) => {
  const forceUpdate = useForceUpdate();
  const remoteRef = useRef(null);
  const [api] = useServerApi();

  // useDeepCompareEffect(() => {
  //   console.log("useDeepCompareEffect", item);
  //   forceUpdate();
  //   remoteRef?.current?.refresh();
  // }, [item]);

  // useDeepCompareEffect(() => {
  //   // console.log("item changed", item);
  //   forceUpdate();
  // }, [data]);

  // console.log(data);
  return (
    <>
      {/* <ReactJson src={item} collapsed /> */}
      <Group wrap="nowrap" mb={"xs"}>
        {/* <Text size="md"> {item.content["category"]}</Text> */}
        {/* item : {item.id} */}
        {/* data.length : {data.length} */}
        {/* {_.isArray(data) && !_.isEmpty(data) && ( */}
        <>
          <RemoteSelect2
            apiEntity="item"
            labelField="name"
            value={item.content["item"]}
            pageSize={1000}
            labelRender={(data) =>
              data.name?.length > 50
                ? data.name?.substring(0, 50) + "..."
                : data.name
            }
            multiple={false}
            searchFields={["name", "code", "cname"]}
            placeholder="Item"
            clearable={true}
            size="xs"
            w={300}
            sort={{
              by: "name",
              order: "asc",
            }}
            onDataChange={(data) => {
              console.log("data", data);
              item.content["item"] = data ? data._id : null;
              item.content["description"] = data ? data.description : "";
              item.content["unit"] = data ? data.unitsOfMeasure : "PCS";
              forceUpdate();
            }}
          />
          {/* <Select
              itemRef={remoteRef}
              // ref={remoteRef}
              size="xs"
              data={data}
              value={
                _.isObject(item.content["item"])
                  ? item.content["item"]._id
                  : item.content["item"]
              }
              clearable={true}
              searchable={true}
              placeholder="Item"
              w={300}
              onChange={(v, option) => {
                // console.log(v, option);
                item.content["item"] = v;
                item.content["description"] = option?.description;
                item.content["unit"] = option?.unitsOfMeasure;
                forceUpdate();
              }}
            /> */}
          {/* <ReactJson src={data} collapsed /> */}
        </>
        {/* )} */}
        {/* <RemoteSelect2
          ref={remoteRef}
          apiEntity="item"
          labelField="name"
          valueField="_id"
          pageSize={5000}
          labelRender={(data) => `${data.name}`}
          multiple={false}
          searchFields={["name", "code", "cname"]}
          placeholder="Item"
          clearable={true}
          size="xs"
          value={item.content["item"]}
          onDataChange={(data) => {
            // console.log("data", data);
            item.content["item"] = data ? data._id : null;
            item.content["description"] = data ? data.description : null;
            item.content["unit"] = data ? data.unitsOfMeasure : null;
            forceUpdate();
          }}
          sort={{
            by: "name",
            order: "asc",
          }}
          w={300}
        /> */}
        {/* <TextInput
          value={item.content["description"]}
          onChange={(e) => {
            item.content["description"] = e.currentTarget.value;
            forceUpdate();
          }}
          placeholder="Description"
          width={300}
        /> */}
        <NumberInput
          placeholder={"Quantity"}
          defaultValue={1}
          thousandSeparator=","
          {...form.getInputProps(`${name}.${index}.quantity`)}
          // value={item.content["quantity"]}
          // onChange={(n) => {
          //   item.content["quantity"] = n;
          //   forceUpdate();
          // }}
          w={100}
          size="xs"
        />
        <Select
          {...form.getInputProps(`${name}.${index}.unit`)}
          data={DATA_UNIT_OF_MEASURE}
          size="xs"
          w={100}
        />
        <TextInput
          placeholder={"Remark"}
          {...form.getInputProps(`${name}.${index}.remark`)}
          // value={item.content["quantity"]}
          // onChange={(n) => {
          //   item.content["quantity"] = n;
          //   forceUpdate();
          // }}
          size="xs"
          w={300}
        />
      </Group>
    </>
  );
};

export default ProductionItemListRow;
