import JsonViewer from "./common/jsonViewer";
import { ReactP5Wrapper } from "@p5-wrapper/react";
import "to-color-string";
import _ from "lodash";
import { useComputedColorScheme } from "@mantine/core";
// import ReactJson from "react-json-view";
import { useState } from "react";
import { Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

export const GetSessionColorString = (sessionIndex) =>
  ("e" + sessionIndex).toColorString();

const getZoomScale = (floorPlan, canvasSize) => {
  let totalWidth = 0;
  let totalDepth = 0;

  Object.keys(floorPlan).forEach((categoryId) => {
    const cat = floorPlan[categoryId];
    totalWidth += cat.reduce((a, c) => a + c.layout.width, 0);
    totalDepth += cat.reduce((a, c) => a + c.layout.depth, 0);
  });

  return 0.9;
};

const getTotalQuantity = (floorPlan) => {
  if (!floorPlan) return 0;
  let totalQuantity = 0;
  Object.keys(floorPlan).forEach((categoryId) => {
    const cat = floorPlan[categoryId];
    totalQuantity += cat.reduce((a, c) => a + c.quantity, 0);
  });
  return totalQuantity;
};

const ItemsFloorPlan = ({ itemGroup = {}, maxTotalQuantity = 2000 }) => {
  const canvasSize = { w: 700, d: 700 };
  const paddingX = 20;
  const paddingY = 30;
  const textLineHeight = 12;
  const paddingLegendSection = 20;

  const { floorPlan, dimensions } = itemGroup;
  const { t: translate } = useTranslation();

  // console.log("floorPlan=", floorPlan);
  const computedColorScheme = useComputedColorScheme("dark");
  const zoomScale = floorPlan ? getZoomScale(floorPlan, canvasSize) : 1;

  const totalQuantity = getTotalQuantity(floorPlan);
  /**
   *
   * @param {*} p5
   * @param {*} cat
   * @param {*} startY
   */

  const normalizeLength = (w, d, ratio) => {
    return {
      w: w * ratio,
      d: d * ratio,
    };
  };

  const getDimensionRatio = (dimension) => {
    const length = Math.max(dimension.width, dimension.depth);
    const maxCanvasSize = Math.max(canvasSize.w, canvasSize.d);

    return Math.min(2, maxCanvasSize / (length / zoomScale));
  };

  /**
   *
   * @param {*} p5
   * @param {*} session
   * @param {*} sessionIndex
   * @returns
   */
  const drawFloorPlanSession = (p5, session, sessionIndex, ratio) => {
    const { startX: sx, startY: sy, width, depth, row, col } = session.layout;
    // const { width: itemWidth, depth: itemDepth, name } = session.item;
    const itemWidth = session.item[session.dim1];
    const itemDepth = session.item[session.dim2];
    const category = session.category;

    let { w: borderWidth, d: borderDepth } = normalizeLength(
      width,
      depth,
      ratio
    );
    let { w: itemW, d: itemD } = normalizeLength(itemWidth, itemDepth, ratio);
    const { w: startX, d: startY } = normalizeLength(sx, sy, ratio);

    // console.log(startX, ",", startY, ", ratio =", ratio);
    //Draw Outline
    p5.fill(GetSessionColorString(sessionIndex));
    // p5.rect(startX + paddingX, startY + paddingY, borderWidth, borderDepth);
    p5.rect(startX + paddingX, startY + paddingY, borderWidth, borderDepth);

    let endY = 0;
    //Draw division
    for (let c = 0; c < col; c++) {
      for (let r = 0; r < row; r++) {
        p5.rect(
          startX + c * itemW + paddingX,
          startY + r * itemD + paddingY,
          itemW,
          itemD
        );
        endY = startY + r * itemD + paddingY;
      }
    }
    return endY + itemD + paddingY;
  };

  /**
   * @description p5 sketch function
   * @param {*} p5
   */
  const sketch = (p5) => {
    p5.setup = () => {
      p5.createCanvas(canvasSize.w, canvasSize.d);
    };

    p5.draw = () => {
      p5.background(computedColorScheme === "dark" ? "black" : "#eee");
      p5.push();
      p5.scale(zoomScale);

      if (floorPlan) {
        const canvasD = Math.min(canvasSize.d, canvasSize.w);
        const ratio =
          canvasD /
          Math.max(dimensions.width, dimensions.depth, dimensions.height);
        Object.keys(floorPlan).forEach((categoryId) => {
          const cat = floorPlan[categoryId];
          let currentY = 0;
          if (cat) {
            cat.forEach((session, sessionIndex) => {
              const itemD = Math.max(
                session.layout[session.dim1] ?? 1,
                session.layout[session.dim2] ?? 1
              );
              // console.log(session);
              // const canvasD = Math.min(canvasSize.d, canvasSize.w);
              // const ratio = canvasD / itemD;
              // console.log("cat.dim1", cat.dim1, "cat.dim2", cat.dim2);
              // console.log("itemD=", itemD, ", canvasD=", canvasD);
              // console.log("ratio=", ratio);

              //   console.log("session=", sessionIndex, session);
              currentY = drawFloorPlanSession(p5, session, sessionIndex, ratio);
            });
          }
        });
      }

      // p5.scale(Math.min(canvasSize.w / totalWidth, canvasSize.d / totalDepth));

      p5.pop();
    };
  };

  return (
    <>
      {/* <ReactJson src={itemGroup.floorPlan} style={{ background: "white" }} /> */}
      {/* totalQuantity: {totalQuantity}
      maxTotalQuantity: {maxTotalQuantity} */}
      {totalQuantity < maxTotalQuantity ? (
        <ReactP5Wrapper sketch={sketch} />
      ) : (
        <Text c="red" size="md">
          {translate(
            "!! Total quantity of items exceeds the maximum possible quantity. It seem the dimensions of the section are too large."
          )}
        </Text>
      )}
    </>
  );
};

export default ItemsFloorPlan;
