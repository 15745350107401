import { useCellRender } from "./useCellRender";
import ActionCellRender from "../components/actionCellRender";
import { IconPencil } from "@tabler/icons-react";
import moment from "moment";

import { UserMainTableSchema } from "../schema/table/user";
import {
  ClaimTableSchema,
  MyClaimTableSchema,
  ProjectClaimTableSchema,
} from "../schema/table/claim";
import { QuotationTableSchema } from "../schema/table/quotation";
import { InvoiceTableSchema } from "../schema/table/invoice";
import { StatementTableSchema } from "../schema/table/statement";
import { ClientTableSchema } from "../schema/table/client";
import { ProjectTableSchema } from "../schema/table/project";
import { ItemTableSchema } from "../schema/table/item";
import { ClientProjectHistoryTable } from "../schema/table/clientProjectHistory";
import { ClientInvoiceHistoryTable } from "../schema/table/clientInvoiceHistory";
import { ClientStatementHistoryTable } from "../schema/table/clientStatementHistory";
import { ItemCategorySchema } from "../schema/table/itemCategory";
import { ItemGroupSchema } from "../schema/table/itemGroup";

import { ItemTransactionSchema } from "../schema/table/itemTransaction";
import { ClaimTypeTableSchema } from "../schema/table/claimType";
import { ProjectTypeTableSchema } from "../schema/table/projectType";
import { TaskTableSchema } from "../schema/table/task";
import { ProjectQuotationListTableSchema } from "../schema/table/projectQuotationList";
import { ProjectInvoiceListTableSchema } from "../schema/table/projectInvoiceList";
import { ProjectPaymentListTableSchema } from "../schema/table/projectPaymentList";
import { ClaimTPaymentableSchema } from "../schema/table/claimPayment";
import { ClaimSettlementSchema } from "../schema/table/claimSettlement";
import { CalendarEvent } from "../schema/table/calendarEvent";

export const useTableSchema = () => {
  const [cellRender] = useCellRender();

  const getTableSchema = (name, props = {}) => {
    switch (name) {
      case "user":
        return UserMainTableSchema(cellRender);
      case "claim":
        return ClaimTableSchema(cellRender);
      case "myClaim":
        return MyClaimTableSchema(cellRender);

      case "claimPayment":
        return ClaimTPaymentableSchema(cellRender);
      case "claimSettlement":
        return ClaimSettlementSchema(cellRender);

      case "ProjectClaimList":
        return ProjectClaimTableSchema(cellRender);
      case "quotation":
        return QuotationTableSchema(cellRender);
      case "invoice":
        return InvoiceTableSchema(cellRender);
      case "statement":
        return StatementTableSchema(cellRender);
      case "client":
        return ClientTableSchema(cellRender);
      case "task":
        return TaskTableSchema(cellRender);

      case "calendarEvent":
        return CalendarEvent(cellRender);

      case "ClientProjectHistory":
        return ClientProjectHistoryTable(cellRender, props);

      case "ClientStatementHistory":
        return ClientStatementHistoryTable(cellRender, props);
      case "ClientInvoiceHistory":
        return ClientInvoiceHistoryTable(cellRender, props);
      case "project":
        return ProjectTableSchema(cellRender);

      case "ProjectQuotationList":
        return ProjectQuotationListTableSchema(cellRender, props);
      case "ProjectInvoiceList":
        return ProjectInvoiceListTableSchema(cellRender, props);

      case "ProjectPaymentList":
        return ProjectPaymentListTableSchema(cellRender, props);

      case "item":
        return ItemTableSchema(cellRender);

      case "itemCategory":
        return ItemCategorySchema(cellRender);
      case "claimType":
        return ClaimTypeTableSchema(cellRender);
      case "itemGroup":
        return ItemGroupSchema(cellRender);

      case "itemTranscation":
        return ItemTransactionSchema(cellRender);

      case "projectType":
        return ProjectTypeTableSchema(cellRender);
    }
  };

  return [getTableSchema];
};
