import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Button,
  TextInput,
  Grid,
  Table,
  Text,
  ActionIcon,
  Group,
  Textarea,
} from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import {
  IconCircleMinus,
  IconCirclePlus,
  IconExchange,
  IconMultiplier1x,
  IconTrash,
} from "@tabler/icons-react";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";

const Row = ({
  data,
  onRemoveRow,
  onInsertRow,
  index,
  fields,
  onDataChange,
  showRowAddBtn = false,
}) => {
  return (
    <Table.Tr valign="top" width="100%">
      <Table.Td>
        <Text size={"xs"}> {index + 1}</Text>
      </Table.Td>

      {fields.map((f) => (
        <Table.Td>
          <f.component
            value={_.get(data, f.fieldName)}
            onChange={(e) => {
              const newV = e?.currentTarget ? e.currentTarget.value : e;
              _.set(data, f.fieldName, newV);
              onDataChange(index, data);
            }}
            {...f.props}
          />
        </Table.Td>
      ))}

      <Table.Td>
        <Group>
          {/* <ActionIcon size="xs" onClick={() => onRemoveRow(index)}> */}
          <IconCircleMinus
            size={20}
            onClick={() => onRemoveRow(index)}
            style={{ cursor: "pointer" }}
          />
          {/* </ActionIcon> */}
          {showRowAddBtn && (
            <IconCirclePlus
              size={20}
              onClick={() => onInsertRow(index)}
              style={{ cursor: "pointer" }}
            />
          )}
        </Group>
      </Table.Td>
    </Table.Tr>
  );
};

const ArrayTable = ({ form, name, rowInitValue, fields, onDataChange }) => {
  const [arrayValues, setArrayValues] = useState([]);
  const formValue = _.get(form.values, name);
  const { t } = useTranslation();
  // const forceUpdate = useForceUpdate();

  const addRow = () => {
    setArrayValues([...arrayValues, { ...rowInitValue }]);
  };

  const removeRow = (index) => {
    const d = [...arrayValues];
    d.splice(index, 1);
    setArrayValues(d);
  };

  const insertRow = (index) => {
    const d = [...arrayValues];
    d.splice(index, 0, {
      ...rowInitValue,
    });
    setArrayValues(d);
  };

  const dataChange = (index, data) => {
    const d = [...arrayValues];
    d.splice(index, 1, data);
    setArrayValues(d);
    if (onDataChange) return onDataChange(d);
  };

  useEffect(() => {
    // console.log(formValue);
    if (_.isEmpty(formValue)) return;
    setArrayValues(formValue);
  }, [formValue]);

  useDeepCompareEffect(() => {
    form.setFieldValue(name, arrayValues);
    // forceUpdate();
  }, [arrayValues]);

  const ths = (
    <Table.Tr>
      <Table.Th>
        <Text size="xs">#</Text>
      </Table.Th>
      {/* <th width="5%" align="left">
        <Text size="xs">#</Text>
      </th> */}
      {fields.map((f) => (
        <Table.Th width={f.headerWidth} align="left">
          <Text size="xs">{t(f.header)}</Text>
        </Table.Th>
      ))}
      <th width="10%"></th>
    </Table.Tr>
  );

  return (
    <>
      <Table style={{ width: "100%" }}>
        <Table.Thead>{ths}</Table.Thead>
        <Table.Tbody>
          {arrayValues?.map((v, index) => (
            <Row
              key={index}
              data={v}
              index={index}
              onRemoveRow={removeRow}
              onInsertRow={insertRow}
              onDataChange={dataChange}
              fields={fields}
            />
          ))}
        </Table.Tbody>
      </Table>

      <Button size="xs" onClick={() => addRow()} mt="xl">
        {t("Add")}
      </Button>
    </>
  );
};

export default ArrayTable;
