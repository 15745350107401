import React from "react";
import { PageList } from "../components/pageList";
import { ClaimTableSchema } from "../schema/table/claim";
import { ClaimAddForm, ClaimEditForm } from "../schema/form/claim";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";

const ClaimType = ({ title = "Claim Type" }) => {
  const [getTableSchema] = useTableSchema();
  const [getFormSchema] = useFormSchema();

  return (
    <PageList
      title={title}
      apiEntity={"claimType"}
      tableSchema={getTableSchema("claimType")}
      formSchema={getFormSchema("claimType")}
      showImport={false}
      searchByTextField={true}
      // stats={{
      //   component: ProjecPageStats,
      //   props: {
      //     mb: 35,

      //   },
      // }}
    />
  );
};

export default ClaimType;
