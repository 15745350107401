import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { AddActionButton, PageList } from "./pageList";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";
import JsonViewer from "./common/jsonViewer";
import DataTable from "./dataTable";
import { useServerApi } from "../hooks/useServerApi";
import AddNewButton from "./addNewButton";
import { useFormRender } from "../hooks/useFormRender";
import RelatedDataTable from "./relatedDataTable";
import { ProjectFormSchema } from "../schema/form/project";

const ClientOrderList = ({ form: mainForm, ...props }) => {
  const [getFormSchema] = useFormSchema();
  const [getTableSchema] = useTableSchema();

  const formSchema = { ...ProjectFormSchema.add };
  const tableSchema = {
    ...getTableSchema("project"),
    preQuery: { client: _.get(mainForm.values, "_id") },
  };

  // delete column with field :action
  tableSchema.columns = tableSchema.columns.filter(
    (col) => col.field !== "action" && col.field !== "client.name"
  );

  tableSchema.columns[0] = {
    field: "code",
    headerName: "Code",
    sortable: true,
    cellRender: (col, data) => (
      <a
        href={`/project?id=${data._id}&mode=edit`}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        {data.code}
      </a>
    ),
  };
  const clientId = _.get(mainForm.values, "_id");

  return (
    <>
      {/* clientId: {clientId} */}
      {clientId && (
        <PageList
          apiEntity="project"
          initSort={{ by: "createdAt", order: "desc" }}
          tableSchema={tableSchema}
          formSchema={formSchema}
          hideActionButton={true}
          hideSearchBar={false}
          withPaper={false}
          px={0}
          pt={0}
          hideActionButton={true}

          // fieldIn="_id"
          // fieldOut="customer"
          // hidePagination={false}
        />
      )}
    </>
  );
};

const ClientOrderList2 = ({ form: mainForm, ...props }) => {
  const [api] = useServerApi();
  const [data, setData] = useState([]);
  const [getFormSchema] = useFormSchema();
  const [getTableSchema] = useTableSchema();

  const apiEntity = "project";
  //Form

  const customerId = _.get(mainForm.values, "_id");
  const formSchema = ProjectFormSchema.add;

  //Table
  const tableSchema = getTableSchema("project", {
    customerId,
  });

  const fetchData = async () => {
    try {
      if (!customerId) return;
      const result = await api.search({
        apiEntity: "project",
        searchQuery: { customer: customerId },
      });
      if (result.docs) setData(result.docs);
    } catch (error) {
      console.log(error);
    }
  };

  //after succes add
  const onSubmitSuccess = () => {
    fetchData();
  };

  //After delete
  const postDelete = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [customerId]);

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSuccess
  );

  return (
    <>
      <RelatedDataTable
        tableSchema={tableSchema}
        formSchema={formSchema}
        mainForm={mainForm}
        apiEntity={"project"}
        fieldIn="_id"
        fieldOut="customer"
        hidePagination={true}
      />
    </>
  );
};

export default ClientOrderList;
