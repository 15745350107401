import { Select, TextInput, Textarea } from "@mantine/core";
import { ENUM_FORM_DISPLAY } from "../../components/pageList";
import AutoCodeInput from "../../components/autoCodeInput";
import { DATA_UNIT_OF_MEASURE } from "../../data/options";
import RemoteSelect2 from "../../components/remoteSelect2";
import { DateInput, DateTimePicker } from "@mantine/dates";

export const CalendarEventFormSchema = {
  title: "Event",
  isMainForm: true,
  display: {
    mode: ENUM_FORM_DISPLAY.DRAWER,
    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },
  apiEntity: "calendarEvent",

  initialValues: {
    code: "",
    name: "",
    title: "",
    user: null,
    status: "toDo",
  },

  layout: {
    fields: [
      {
        name: "type",
        component: Select,
        props: {
          required: true,
          label: "Type",
          placeholder: "",
          data: [
            { value: "companyEvent", label: "Company Event 公司活動" },
            { value: "meeting", label: "Meeting 會議" },
            { value: "inTraining", label: "In House Training 內部培訓" },
            { value: "other", label: "Others 其他" },
          ],
        },
      },
      {
        name: "title",
        component: TextInput,
        props: {
          required: true,
          label: "Title",
          placeholder: "",
        },
      },

      {
        name: "from",
        component: DateTimePicker,
        type: "datePicker",
        props: {
          label: "From",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
          type: "datePicker",
          clearable: true,
        },
      },
      {
        name: "to",
        component: DateTimePicker,
        type: "datePicker",
        props: {
          label: "To",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
          type: "datePicker",
          clearable: true,
        },
      },

      {
        name: "venues",
        component: TextInput,
        props: {
          //   required: true,
          label: "Venues",
          placeholder: "",
        },
      },

      {
        name: "description",
        component: Textarea,
        props: {
          // required: true,
          label: "Description",
          placeholder: "",
          mb: "xl",
        },
      },
    ],
  },
};
