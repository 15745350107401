import React from "react";
import { PageList } from "../components/pageList";
import { QuotationAddForm, QuotationEditForm } from "../schema/form/quotation";
import { useTableSchema } from "../hooks/useTableSchema";
import moment from "moment";

const Quotation = ({ title = "Quotation" }) => {
  const [getTableSchema] = useTableSchema();

  const formSchema = {
    add: {
      ...QuotationEditForm,
      initialValues: {
        status: "DFT",
        terms: [{ title: "", detail: "" }],
        quoDate: new Date(),
        validDate: moment().add(14, "days").toDate(),
      },
      title: "New Quotation",
    },
    edit: QuotationEditForm,
  };
  return (
    <PageList
      title={title}
      apiEntity={"quotation"}
      tableSchema={getTableSchema("quotation")}
      formSchema={formSchema}
      showImport={false}
      initSort={{ by: "code", order: "desc" }}
      searchByTextField={true}
      // stats={{
      //   component: ProjecPageStats,
      //   props: {
      //     mb: 35,

      //   },
      // }}
    />
  );
};

export default Quotation;
