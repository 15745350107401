import { useServerApi } from "../../hooks/useServerApi";
import { FormSectionCard } from "../../layout/page";
import { PeriodFilter } from "../../components/periodFilter";
import { useState, useEffect } from "react";
import { Group, SimpleGrid, Tabs, Table, Grid } from "@mantine/core";
import { useTranslation } from "react-i18next";
import JsonViewer from "../common/jsonViewer";
import PieChart from "../../components/chartWidget/PieChart";
import BarChart from "../../components/chartWidget/BarChart";
import ProjectStatsTable from "../../components/projectStatsTable";

const DashboardManagement = () => {
  const [period, setPeriod] = useState(null);
  const { t } = useTranslation();
  const [api] = useServerApi();
  const [projectStats, setProjectStats] = useState([]);
  const [projectTypeStats, setProjectTypeStats] = useState([]);
  const [projectStatusStats, setProjectStatusStats] = useState([]);
  const [projectClientIndustryStats, setProjectClientIndustryStats] = useState(
    []
  );

  const [projectTopClientStats, setProjectTopClientStats] = useState([]);

  const fetchStats = async () => {
    if (!period) return;
    const data = await api.Project.getStats(period);
    // console.log("fetchStats", data);
    setProjectStats(data);

    setProjectStatusStats(
      data.statusStats?.map((s) => ({
        id: s.name,
        value: s.count,
      })) ?? []
    );

    setProjectTypeStats(
      data.projectTypeStats?.map((s) => ({
        id: s.name,
        count: s.count,
      })) ?? []
    );

    setProjectClientIndustryStats(
      data.clientIndustryStats?.map((s) => ({
        id: s.industry,
        value: s.count,
      }))
    );

    setProjectTopClientStats(
      data.clientStats?.map((s) => ({
        id: s.name,
        case: s.count,
      })) ?? []
    );

    // setProjectCategoryPieData(
    //   data.orderSummary
    //     .filter((s) => s._id != "ALL")
    //     .map((s) => ({
    //       id: s._id,
    //       value: s.count,
    //     }))
    // );
  };

  useEffect(() => {
    fetchStats();
  }, [period]);

  return (
    <>
      {/* <JsonViewer src={projectStats} /> */}
      <Group justify={"right"} mb={"xl"}>
        <PeriodFilter
          handleRefresh={fetchStats}
          handlePeriodChange={(p) => {
            // console.log("Period Change", p);
            setPeriod(p);
          }}
          numPeriods={8}
          by="Q"
        />
      </Group>

      <Tabs defaultValue="1" variant="outline">
        <Tabs.List>
          <Tabs.Tab value="1">Projects</Tabs.Tab>
          {/* <Tabs.Tab value="2">Customer</Tabs.Tab>
          <Tabs.Tab value="3">Items</Tabs.Tab> */}
        </Tabs.List>

        <Tabs.Panel value="1">
          {/* <FormSectionCard title={"Summary"} mt={"xl"}>
            <ProjectStatsTable data={projectStats.statusStats} />
          </FormSectionCard> */}
          <Grid spacing="xl" mt={"xl"}>
            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <BarChart
                title={"Top Clients"}
                data={projectTopClientStats}
                height={350}
                chartProps={{
                  keys: ["case"],
                  layout: "horizontal",
                  indexBy: "id",
                  marginLeft: 10,
                }}
              />
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <PieChart
                title={"Client Industry"}
                staticData={projectClientIndustryStats}
                height={350}
              />
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <BarChart
                title={"Event Type"}
                data={projectTypeStats}
                height={350}
                chartProps={{
                  keys: ["count"],
                  layout: "horizontal",
                  indexBy: "id",
                  marginLeft: 10,
                }}
              />
            </Grid.Col>

            <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
              <PieChart
                title={"Status"}
                staticData={projectStatusStats}
                height={350}
              />
            </Grid.Col>
          </Grid>
        </Tabs.Panel>

        <Tabs.Panel value="2">
          <FormSectionCard>
            <div>Customer</div>
          </FormSectionCard>
        </Tabs.Panel>

        <Tabs.Panel value="3">
          <FormSectionCard>
            <div>Items</div>
          </FormSectionCard>
        </Tabs.Panel>
      </Tabs>
    </>
  );
};
export default DashboardManagement;
