import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { AddActionButton, PageList } from "./pageList";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";
import JsonViewer from "./common/jsonViewer";
import DataTable from "./dataTable";
import { useServerApi } from "../hooks/useServerApi";
import AddNewButton from "./addNewButton";
import { useFormRender } from "../hooks/useFormRender";
import RelatedDataTable from "./relatedDataTable";

const ProjectInvoiceList = ({ form: mainForm, ...props }) => {
  const [api] = useServerApi();
  const [data, setData] = useState([]);
  const [getFormSchema] = useFormSchema();
  const [getTableSchema] = useTableSchema();

  const apiEntity = "invoice";
  //Form

  const projectId = _.get(mainForm.values, "_id");
  const customer = _.get(mainForm.values, "customer");
  const customerId =
    _.get(mainForm.values, "customer._id") ??
    _.get(mainForm.values, "customer");
  const clientId =
    _.get(mainForm.values, "client._id") ?? _.get(mainForm.values, "client");

  const contactPerson =
    customer && customer.contactPersons && customer?.contactPersons?.length > 0
      ? customer.contactPersons[0]
      : null;

  const formSchema = {
    ...getFormSchema("ProjectInvoice"),
    initialValues: {
      project: projectId,
      client: clientId,
      customer: customerId,
      contactPerson: contactPerson?._id,
      terms: [{ title: "", detail: "" }],
    },
  };

  //Table
  const tableSchema = getTableSchema("ProjectInvoiceList", {
    projectId,
  });

  return (
    <>
      <RelatedDataTable
        tableSchema={tableSchema}
        formSchema={formSchema}
        mainForm={mainForm}
        apiEntity={"invoice"}
        fieldIn="_id"
        fieldOut="project"
      />
    </>
  );
};

export default ProjectInvoiceList;
