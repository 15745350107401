import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil } from "@tabler/icons-react";

export const ClaimTPaymentableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },

      {
        field: "user",
        headerName: "Claim By",
        sortable: true,
        cellRender: (column, data) => cellRender.User(data.user),
      },
      {
        field: "paidDate",
        headerName: "Paid Date",
        cellRender: (col, data) => moment(data?.claimDate).format("LL"),
        sortable: true,
      },

      {
        field: "paidAmount",
        headerName: "Amount",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.amount),
      },

      {
        field: "status",
        headerName: "Status",
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
      },
    ],
  };
};

export const ProjectClaimTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },
      {
        field: "claimType",
        headerName: "Type",
        sortable: true,
        cellRender: (column, data) => data.claimType?.name,
      },

      {
        field: "amount",
        headerName: "Amount",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.amount),
      },
      {
        field: "user",
        headerName: "Claim By",
        sortable: true,
        cellRender: (column, data) => cellRender.User(data.user),
      },
      {
        field: "status",
        headerName: "Status",
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
      },
    ],
  };
};
