import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash } from "@tabler/icons-react";

export const ProjectPaymentListTableSchema = (cellRender, { projectId }) => {
  return {
    preQuery: { project: projectId },
    showActionColumn: true,
    columns: [
      {
        field: "paymentDate",
        headerName: "Payment Date",
        sortable: true,
        cellRender: (col, data) => cellRender.Date(data.paymentDate, "ll"),
      },
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },

      {
        field: "amount",
        headerName: "Amount",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.amount),
      },

      // {
      //   field: "createdBy",
      //   headerName: "Created By",
      //   sortable: true,
      // },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          // {
          //   name: "openEdit",
          //   linkPath: "invoicePayment",
          //   icon: IconPencil,
          //   props: {
          //     //   color:"red"
          //   },
          // },
        ],
        actionMenu: [
          {
            name: "delete",
            label: "Delete",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
  };
};
