import { Tabs, Button, Group } from "@mantine/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const FormTab = ({
  getChildContainers,
  getChildFields,
  renderContainer,
  renderField,
  tabs,
  ...props
}) => {
  const { t: translate } = useTranslation();
  return (
    <Tabs {...tabs.props} key={tabs.key}>
      <Tabs.List>
        {tabs.tab?.map(({ label, key, iconSize = 14, ...t }) => (
          <Tabs.Tab
            key={`tab-${key}-${label}`}
            // icon={<t.icon size={iconSize}/>}
            value={label}
          >
            {translate(label)}
          </Tabs.Tab>
        ))}
      </Tabs.List>

      {tabs.tab?.map(({ label, key }, index) => (
        <Tabs.Panel key={`tab-panel-${label}`} value={label} pt={"xl"}>
          {getChildContainers(key).map((c) => renderContainer(c))}
          {getChildFields(key).map((f) => renderField(f))}
        </Tabs.Panel>
      ))}
    </Tabs>
  );
};

export default FormTab;
