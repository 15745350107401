import { Divider, Group, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

const { FormSectionCard } = require("../layout/page");

const ProjectSetting = ({ form }) => {
  const { t } = useTranslation();

  return (
    <FormSectionCard title={"Project Setting"} p>
      <Group position={"apart"}>
        <Text>{t("Name")}</Text>
        <Text>{form.name}</Text>
      </Group>
    </FormSectionCard>
  );
};

export default ProjectSetting;
