import React from "react";
import { Page, FormSectionCard } from "../layout/page";
import { Grid, SimpleGrid } from "@mantine/core";
import RecentProjectList from "../components/recentProjectList";
import EventSection from "../components/eventSection";
import AnnouncementList from "../components/announcementList";
import MyTaskList from "../components/myTaskList";

const Home = ({}) => {
  const mt = 50;
  return (
    <Page title={"Welcome"}>
      <EventSection />

      <Grid>
        {/*Left Section */}
        <Grid.Col span={{ base: 12, lg: 4, md: 12 }}>
          {/* <FormSectionCard title={"Announcements"} mt={"md"}>
            <AnnouncementList />
          </FormSectionCard> */}
          <FormSectionCard title={"My Tasks"} mt={"md"} h={"420px"}>
            <MyTaskList />
          </FormSectionCard>
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 8, md: 12 }}>
          <FormSectionCard title={"Projects"} mt={"md"}>
            <RecentProjectList />
          </FormSectionCard>
        </Grid.Col>
      </Grid>
    </Page>
  );
};

export default Home;
