import React, { useRef } from "react";
import {
  TextInput,
  Grid,
  Select,
  Group,
  Checkbox,
  NumberInput,
} from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import RemoteSelect2 from "./remoteSelect2";
import { DATA_ITEM_ASSIGN_METHOD } from "../data/options";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export const AssignTeamMemberRow = ({ item, index, form, name }) => {
  const forceUpdate = useForceUpdate();

  const remoteRef = useRef(null);
  useDeepCompareEffect(() => {
    // console.log("item changed", item);
    forceUpdate();
    remoteRef.current?.refresh();
  }, [item]);

  const { t } = useTranslation();
  return (
    <Group wrap="nowrap">
      <TextInput
        value={item.content["roleName"]}
        placeholder={t("Role")}
        {...form.getInputProps(`${name}.${index}.roleName`)}
        w={"350px"}
      />
      {/* {item.content["user"]} */}
      <RemoteSelect2
        ref={remoteRef}
        apiEntity="user"
        labelField="name"
        valueField="_id"
        labelRender={(data) => `${data.aliasName} (${data.staffId ?? " -- "})`}
        multiple={false}
        searchFields={["name", "aliasName", "staffId"]}
        placeholder="Staff"
        clearable={true}
        value={item.content["user"]}
        onDataChange={(data) => {
          // console.log("AssignTeamMemberRow onDataChange", data);
          item.content["user"] = data ? data._id : null;
          // forceUpdate();
        }}
        sort={{
          by: "name",
          order: "asc",
        }}
      />
      {/* user:{item.content["user"]} */}
      <TextInput
        value={item.content["tempStaff"]}
        placeholder={t("Temp Staff")}
        w={"100%"}
        {...form.getInputProps(`${name}.${index}.tempStaff`)}
      />
      {item.content.user === null && item.content.tempStaff === null && (
        <>
          <NumberInput
            placeholder={"No. Staff"}
            defaultValue={1}
            {...form.getInputProps(`${name}.${index}.quantity`)}
          />
        </>
      )}
    </Group>
  );
};
