import React, { useState, useEffect } from "react";
import { Page } from "../layout/page";
import { useAuthUser } from "react-auth-kit";
import { Grid, Group, Tabs } from "@mantine/core";
import { useTranslation } from "react-i18next";

import DashboardManagement from "../components/dashboard/management";
import JsonViewer from "../components/common/jsonViewer";
import { Text } from "@mantine/core";
import _ from "lodash";

const dashboardViews = [
  { id: "DM", label: "Management View", component: DashboardManagement },
  // { id: "DC", label: "Consultant View", component: DashboardConsultant },
  { id: "DO", label: "Operation View", component: DashboardManagement },
];

const DashBoard = ({ title = "Dashboard", numPeriods = 8 }) => {
  const auth = useAuthUser();
  const { userRole } = auth();

  const { t } = useTranslation();

  const availableDashboardViews = dashboardViews.filter((v) =>
    userRole?.dashboards?.includes(v.id)
  );
  const NO_DASHBOARD_VIEW_MSG =
    "There are no dashboard views for the user role";

  return (
    <Page title={title}>
      {/* <JsonViewer src={availableDashboardViews} /> */}
      {_.isEmpty(availableDashboardViews) && (
        <Text> {NO_DASHBOARD_VIEW_MSG}</Text>
      )}

      {!_.isEmpty(availableDashboardViews) && (
        <Tabs defaultValue={availableDashboardViews[0].id}>
          <Tabs.List mb={"xl"}>
            {availableDashboardViews.map((v) => (
              <Tabs.Tab key={v.id} value={v.id}>
                {t(v.label)}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {availableDashboardViews.map((v) => (
            <Tabs.Panel key={v.id} value={v.id}>
              <v.component />
            </Tabs.Panel>
          ))}
        </Tabs>
      )}
    </Page>
  );
};

export default DashBoard;
