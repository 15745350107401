import {
  Text,
  TextInput,
  Select,
  Chip,
  Checkbox,
  Badge,
  Textarea,
} from "@mantine/core";

import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons-react";
import Chips from "../../components/chips";
import { DATA_CLIENT_INDUSTRY, DATA_CLIENT_TYPES } from "../../data/options";
import RemoteSelect2 from "../../components/remoteSelect2";

import ContactList from "../../components/contactList.js";
import FormConfirmation from "../../components/formConfirmation";

const apiEntity = "client";
const initialValues = {
  name: "",
  cname: "",
  aliasName: "",
  type: "DIRECT",
  address: "",
  consultants: [],
  language: "CANTONESE",
  status: "ACTIVE",
};

export const clientAddFormSchema = {
  name: "clientAddForm1",
  title: "New Client",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "1000px", //xs, sm, xl  ....100%
    // position:"right"
  },

  showSaveBar: false,
  apiEntity,
  initialValues,

  validate: {},

  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Basic Info",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          // { key: "t1-1", label: "Type", icon: IconSettings },
          { key: "t1-3", label: "Basic Info", icon: IconSettings },
          { key: "t1-6", label: "Address", icon: IconSettings },
          { key: "t1-4", label: "Contacts", icon: IconSettings },
          { key: "t1-5", label: "Confirm", icon: IconSettings },
        ],
      },
      {
        key: "section-type",
        parent: "t1-1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Client Types",
          description: "Please choose type for client",
          withBorder: false,
          withPaper: false,
          ml: 10,
        },
      },

      {
        key: "section-basicInfo",
        parent: "t1-3",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Basic Info",
          titleOrder: 5,
          description: "Please enter basic info of client",
          withBorder: false,
          withPaper: false,

          ml: 10,
          mt: 0,
          mb: 10,
        },
      },

      {
        key: "basicInfo-g",
        parent: "section-basicInfo",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "basicInfo-g0",
            props: {
              span: 12,
              mt: 0,
            },
          },
          {
            key: "basicInfo-g1",
            props: {
              span: 6,
            },
          },
          {
            key: "basicInfo-g2",
            props: {
              span: 6,
            },
          },
        ],
      },

      {
        key: "section-contact",
        parent: "t1-4",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Contact Person",
          titleOrder: 5,
          description: "Please assign the biz contact person details",
          withBorder: false,
          withPaper: false,
        },
      },

      {
        key: "section-confirm",
        parent: "t1-5",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Confirm",
          titleOrder: 3,
          description:
            "Please confirm to add client or you can go back to revise.",
          withBorder: false,
          withPaper: false,

          ml: 10,
          mt: 0,
          mb: 10,
        },
      },

      {
        key: "section-address",
        parent: "t1-6",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Business address",
          titleOrder: 3,
          description: "Please fill in the address info",
          withBorder: false,
          withPaper: false,

          ml: 10,
          mt: 0,
          mb: 10,
        },
      },
    ],

    fields: [
      {
        name: "type",
        component: Chips,
        parent: "section-type",
        props: {
          required: true,
          labelOrder: 5,
          description: "Please Select the type of client.",
          items: DATA_CLIENT_TYPES,
          multiple: false,
        },
      },

      {
        name: "name",
        component: TextInput,
        parent: "basicInfo-g0",
        props: {
          required: true,
          placeholder: "Company Name",
          label: "Company Name (ENG)",
        },
      },
      {
        name: "cname",
        component: TextInput,
        parent: "basicInfo-g0",
        props: {
          placeholder: "公司名稱",
          label: "Chinese Name (CHI)",
        },
      },
      {
        name: "industry",
        component: Select,
        parent: "basicInfo-g0",
        props: {
          label: "Business Industry",
          placeholder: "",
          data: DATA_CLIENT_INDUSTRY.map((ind) => ({
            label: `${ind.label}  ${ind.clabel} (${ind.value})`,
            value: ind.value,
          })),
          searchable: true,
          clearable: true,
        },
      },

      {
        name: "email",
        component: TextInput,
        parent: "basicInfo-g2",
        props: {
          // required: true,
          placeholder: "taiman@gmail.com",
          label: "Email",
        },
      },
      {
        name: "phoneNumber",
        component: TextInput,
        parent: "basicInfo-g1",
        props: {
          // required: true,
          placeholder: "30005000",
          label: "Business phone",
        },
      },
      {
        name: "saleman",
        component: RemoteSelect2,
        parent: "basicInfo-g1",
        props: {
          apiEntity: "user",
          required: true,
          label: "Saleman",
          placeholder: "Assign Saleman(s)",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) =>
            `${data.name}, ${data.aliasName} (${data.staffId ?? " -- "})`,
          multiple: false,
          // preQuery,
          // preQuery:  {isConsultant : true} ,
          searchFields: ["name", "staffId", "cname", "email", "aliasName"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "businessRegistrationNumber",
        component: TextInput,
        parent: "basicInfo-g2",
        props: {
          label: "BR No.",
          placeholder: "Bussiness Registration Number",
          mt: 0,
        },
      },

      {
        name: "address",
        component: Textarea,
        parent: "section-address",
        props: {
          label: "Business Address",
        },
      },

      {
        name: "contactPersons",
        component: ContactList,
        parent: "section-contact",
        props: {
          title: "Contact Persons",
          width: "200px",
          saveOnSubmit: false,
        },
      },
      {
        name: "confirm",
        component: FormConfirmation,
        parent: "section-confirm",
        props: {},
      },
    ],
  },
};
