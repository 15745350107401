import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { AddActionButton, PageList } from "./pageList";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";
import { useServerApi } from "../hooks/useServerApi";
import AddNewButton from "./addNewButton";
import { useFormatter } from "../hooks/useFomatter";

import RelatedDataTable from "./relatedDataTable";
import {
  Card,
  Group,
  InputWrapper,
  Table,
  Divider,
  Text,
  Title,
  ActionIcon,
  TextInput,
  Textarea,
  NumberInput,
} from "@mantine/core";
import { useTranslation } from "react-i18next";

const ProjectPaymentList = ({ form: mainForm, ...props }) => {
  const [api] = useServerApi();
  const [getFormSchema] = useFormSchema();
  const [getTableSchema] = useTableSchema();
  const [formatter] = useFormatter();
  // const [receivedAmount, setPaidAmount] = useState(0);
  // const [totalReceivable, setTotalAmount] = useState(0);
  // const [balance, setBalance] = useState(0);
  const { t } = useTranslation();
  const apiEntity = "invoicePayment";
  //Form
  const projectId = _.get(mainForm.values, "_id");
  const clientId =
    _.get(mainForm.values, "client._id") ?? _.get(mainForm.values, "client");
  const customerId = _.get(mainForm.values, "customer");
  const { receivedAmount, totalReceivable, balance } = mainForm.values;

  //Set formschema with initial values
  const formSchema = {
    ...getFormSchema("ProjectPayment"),
    initialValues: {
      project: projectId,
      client: clientId,
      customer: customerId,
      terms: [{ title: "", detail: "" }],
    },
  };

  //Table
  const tableSchema = getTableSchema("ProjectPaymentList", {
    projectId,
  });

  // const fetchData = async () => {
  //   try {
  //     // console.log("ProjectPaymentList>fetchData", projectId);
  //     if (!projectId) return;
  //     const result = await api.search({
  //       apiEntity: "invoice",
  //       searchQuery: {
  //         //status not equal to CANCEL
  //         status: { $ne: "CANCEL" },
  //         project: projectId,
  //       },
  //     });

  //     // console.log("ProjectPaymentList>fetchData>result", result);

  //     const totalReceivable = result.docs.reduce((a, b) => a + b.total, 0) ?? 0;
  //     const receivedAmount =
  //       result.docs.reduce((a, b) => a + b.paidAmount, 0) ?? 0;
  //     setTotalAmount(totalReceivable);
  //     setPaidAmount(receivedAmount);
  //     setBalance(totalReceivable - receivedAmount);

  //     mainForm.updateAndSave(
  //       {
  //         totalReceivable,
  //         receivedAmount,
  //         balance: totalReceivable - receivedAmount,
  //       },
  //       false
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [projectId]);

  return (
    <>
      <RelatedDataTable
        tableSchema={tableSchema}
        formSchema={formSchema}
        mainForm={mainForm}
        apiEntity={"invoicePayment"}
        fieldIn="_id"
        fieldOut="project"
        hideAddButton={true}
        onPostRefresh={() => mainForm.reload()}
      />

      <Group justify="right" px="md">
        <Text size="xs" c={"dimmed"}>
          {t("Total Amount")}
        </Text>
        <Text>{formatter.currency(totalReceivable)}</Text>
      </Group>

      <Group justify="right" px="md">
        <Text size="xs" c={"dimmed"}>
          {t("Paid Amount")}
        </Text>
        <Text>{formatter.currency(receivedAmount)}</Text>
      </Group>
      <Group justify="right" px="md">
        <Divider variant="dotted" my={"lg"} />
        <Text size="xs" c={"dimmed"}>
          {t("Total due")}
        </Text>
        <Title order={3} c={balance > 0 ? "red" : "green"}>
          {formatter.currency(balance)}
        </Title>
      </Group>
    </>
  );
};

export default ProjectPaymentList;
