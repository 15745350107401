import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { DateInput } from "@mantine/dates";
import remoteSelect2 from "../../components/remoteSelect2";
import { TextInput, NumberInput } from "@mantine/core";
import FileList from "../../components/fileList";
import DummyWidget from "../../components/dummyWidget";
import { Box, InputWrapper } from "@mantine/core";
import ClaimSelttlementList from "../../components/claimSelttlementList";
// import { useTranslation } from "react-i18next";

const initialValues = {
  paidDate: new Date(),
};

const apiEntity = "claimSettlement";

const addForm = {
  name: "addSchema",
  title: "New Claim Settlement",
  description: "Please follow the step to create a claim settlement",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "md", //xs, sm, xl  ....100%
  },

  apiEntity,
  initialValues,

  layout: {
    containers: [],
    fields: [
      {
        name: "paidDate",
        component: DateInput,
        parent: "tab-overview",
        props: {
          label: "Paid Date",
          required: true,
          placeholder: "Paid Date",
          withSelect: true,
          withTime: true,
          clearable: true,
        },
      },
    ],
  },
};

const editForm = {
  title: "Claim Settlement",
  titleField: "code",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
  },
  apiEntity,
  layout: {
    containers: [
      {
        key: "section-1",
        parent: null,

        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Basic Information",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "basic",
        parent: "section-1",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "basic-g1",
            props: {
              span: 4,
            },
          },
          {
            key: "basic-g2",
            props: {
              span: 4,
            },
          },
          {
            key: "basic-g3",
            props: {
              span: 4,
            },
          },
        ],
      },

      {
        key: "section-2",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Settlements",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
    ],
    fields: [
      {
        name: "code",
        component: DummyWidget,
        parent: "basic-g1",
        props: {
          dummyRender: (data, cellRender) => {
            return (
              <InputWrapper label="Code" mb="xs">
                <Box>{data?.code}</Box>
              </InputWrapper>
            );
          },
        },
      },
      {
        name: "status",
        component: DummyWidget,
        parent: "basic-g2",
        props: {
          dummyRender: (data, cellRender) => {
            return (
              <InputWrapper label="Status" mb="xs">
                <Box>{data?.status}</Box>
              </InputWrapper>
            );
          },
        },
      },
      {
        name: "date",
        component: DateInput,
        parent: "basic-g3",
        type: "datePicker",
        props: {
          // required: true,
          label: "Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
        },
      },

      {
        name: "amount",
        component: DummyWidget,
        parent: "basic-g1",
        props: {
          dummyRender: (data, cellRender) => {
            return (
              <InputWrapper label="Total Amount" mb="xs">
                <Box>{cellRender.Currency(data?.amount)}</Box>
              </InputWrapper>
            );
          },
        },
      },
      {
        name: "count",
        component: DummyWidget,
        parent: "basic-g2",
        props: {
          dummyRender: (data, cellRender) => {
            return (
              <InputWrapper label="Total Claims" mb="xs">
                <Box>{data?.count}</Box>
              </InputWrapper>
            );
          },
        },
      },

      {
        name: "settlements",
        component: ClaimSelttlementList,
        parent: "section-2",
        props: {},
      },
    ],
  },
};

export const ClaimSettlementSchema = {
  add: addForm,
  edit: editForm,
};
