import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash, IconCopy } from "@tabler/icons-react";

export const ItemTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    searchableFields: ["name", "code"],
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },
      {
        field: "name",
        headerName: "Name",
        sortable: true,
        width: 450,
      },
      {
        field: "category.name",
        headerName: "Category",
        sortable: true,
      },
      {
        field: "totalInventory",
        headerName: "Total inventory",
        sortable: true,
      },
      {
        field: "unitsOfMeasure",
        headerName: "Unit",
        sortable: true,
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
          {
            name: "copy",
            icon: IconCopy,

            props: {
              //   color:"red"
            },
          },
          {
            name: "delete",
            icon: IconTrash,
            props: {
              //   color:"red"
            },
          },
        ],
      },
    ],
  };
};
