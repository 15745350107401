import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash } from "@tabler/icons-react";
import { Text } from "@mantine/core";

export const TaskTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    // initSort: { by: "code", order: "desc" },
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },

      {
        field: "user.name",
        headerName: "User",
        sortable: true,
        cellRender: (col, data) => cellRender.User(data.user),
      },

      {
        field: "title",
        headerName: "Title",
        sortable: true,
        cellRender: (col, data) => (
          <>
            <Text size="md">{data.title} </Text>
            {moment(data?.createdAt).format("LL")
              ? ` ${moment(data?.createdAt).format("LL")}`
              : ""}
          </>
        ),
      },
      // {
      //   field: "description",
      //   headerName: "Description",
      //   sortable: true,
      // },

      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
          {
            name: "delete",
            icon: IconTrash,
            props: {
              //   color:"red"
            },
          },
        ],
      },
    ],
    // searchByTextField: true,

    searchableFields: ["status", "code", "user.name", "user.aliasName"],
  };
};
