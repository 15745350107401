import React, { useEffect, useState } from "react";
import { Page, FormSectionCard } from "../layout/page";
import { Calendar } from "@mantine/dates";

import {
  Group,
  Indicator,
  Table,
  Text,
  Box,
  Grid,
  Flex,
  Tabs,
  ScrollArea,
  Button,
  UnstyledButton,
} from "@mantine/core";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { useCellRender, openWhatsapp } from "../hooks/useCellRender";
import {
  IconBrandWhatsapp,
  IconCalendar,
  IconClock,
  IconPhone,
  IconExternalLink,
  IconMap,
} from "@tabler/icons-react";
import { useServerApi } from "../hooks/useServerApi";
import { useAuthUser } from "react-auth-kit";

export const getEventColor = (type) => {
  switch (type) {
    case "companyEvent":
      return "cyan";
    case "inTraining":
      return "yellow";
    case "meeting":
      return "orange";
    case "Setup":
      return "blue";
    case "Dismantle":
      return "red";
    default:
      return "blue";
  }
};

const EventCalendar = ({
  events,
  onMonthChange,
  onSelectedDateChange,
  selectedDate,
}) => {
  const eventDates = events?.map((e) => moment(e.date)) ?? [];

  const isEventDate = (date) => {
    if (moment().isSame(date, "date")) return true;
    for (const d of eventDates) {
      if (d?.isSame(date, "date")) return true;
    }
    if (eventDates.include(date.get)) return false;
  };

  const { t } = useTranslation();

  return (
    <div style={{ width: "100%" }}>
      <Calendar
        zIndex={0}
        w={"100%"}
        // onMonthChange={(d) => {
        //   console.log("onMonthChange", d);
        //   onMonthChange(d);
        // }}
        onDateChange={(d) => {
          // console.log("onDateChange", d);
          onMonthChange(d);
        }}
        getDayProps={(date) => ({
          selected: moment(selectedDate).isSame(date, "date"),
          onClick: () => onSelectedDateChange(date),
        })}
        onChange={() => {}}
        renderDay={(date) => {
          const day = date.getDate();
          const event = events?.find((e) =>
            moment(e.date).isSame(date.toISOString(), "date")
          );
          const isToday = moment().isSame(date.toISOString(), "date");
          return (
            <>
              {event && (
                <Indicator
                  size={6}
                  color={getEventColor(event.type)}
                  zIndex={0}
                >
                  <div>{day}</div>
                </Indicator>
              )}
              {!event && isToday && (
                <Indicator size={6} color="teal" zIndex={0}>
                  <div>{day}</div>
                </Indicator>
              )}
              {!event && !isToday && <div>{day}</div>}
            </>
          );
        }}
      />

      <Box mt={"xl"}>
        <Group wrap="nowrap">
          <Indicator size={6} color="teal" zIndex={0} />
          <Text size="xs">{t("Today")}</Text>
        </Group>
        <Group wrap="nowrap">
          <Indicator size={6} color={getEventColor("Setup")} zIndex={0} />
          <Text size="xs">{t("Setup")}</Text>
        </Group>

        <Group wrap="nowrap">
          <Indicator size={6} color={getEventColor("Dismantle")} zIndex={0} />
          <Text size="xs">{t("Dismantle")}</Text>
        </Group>
        <Group wrap="nowrap">
          <Indicator size={6} color="cyan" zIndex={0} />
          <Text size="xs">{t("Company Event")}</Text>
        </Group>
        <Group wrap="nowrap">
          <Indicator size={6} color="yellow" zIndex={0} />
          <Text size="xs">{t("Internal Training")}</Text>
        </Group>
        <Group wrap="nowrap">
          <Indicator size={6} color="orange" zIndex={0} />
          <Text size="xs">{t("Meeting")}</Text>
        </Group>
      </Box>
    </div>
  );
};
const EventSection = ({
  title,
  txtSize = "xs",
  headerSize = "xs",
  iconSize = 12,
  ...props
}) => {
  const [cellRender] = useCellRender();
  const [events, setEvents] = useState([]);
  const [clients, setClients] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [api] = useServerApi();

  const auth = useAuthUser();

  const fetchEvent = async () => {
    try {
      // const e = await api.CalendarEvent.getByMonth(currentDate);
      // if (!auth().id) return;
      let result = null;
      if (!selectedDate) {
        result = await api.Project.getEventByMonth(currentDate);
        result?.events?.map((e) => {
          e.title = e.event;
          e.subtitle = e.client;
        });
      } else {
        result = await api.Project.getEventByDateRange(
          selectedDate,
          moment(currentDate).endOf("month").toDate()
        );
        result?.events?.map((e) => {
          e.title = e.event;
          e.subtitle = e.client;
        });
      }

      // console.log("Event section > result", currentDate, result);

      const result2 = await api.CalendarEvent.getByMonth(currentDate);
      result2?.events?.map((e) => {
        e.title = e.title;
        e.date = e.from;
        e.subtitle = e.venues;
      });

      // console.log("Event section > result2", currentDate, result2);

      const events = [...result?.events, ...result2?.events];
      //events sort by date
      // Sort events by date
      events.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      });

      setEvents(events);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchEvent();
  }, [currentDate, selectedDate]);

  const onSelectedDateChange = (date) => {
    if (moment(selectedDate).isSame(date, "date")) {
      setSelectedDate(null);
      return;
    }
    setSelectedDate(date);
  };

  const rows = events?.map((ev) => (
    <Table.Tr key={ev._id}>
      <Table.Td>
        <Group noWrap>
          <IconCalendar size={iconSize} />
          <Text size={txtSize}>
            {moment(ev.date).format("YYYY-MM-DD HH:mm")}
          </Text>
          {/* <Text size={txtSize}>{moment(ev.date).format("HH:mm")}</Text> */}
        </Group>
      </Table.Td>

      <Table.Td>
        <Group noWrap>
          <Indicator size={6} color={getEventColor(ev.type)} zIndex={1} />
          <Text size={txtSize}>{ev.type}</Text>
        </Group>
      </Table.Td>

      <Table.Td
        onClick={() => window.open(`/project?id=${ev.id}&mode=edit`)}
        style={{ cursor: "pointer" }}
      >
        <Group noWrap>
          <Text size={txtSize} lineClamp={1}>
            {ev.title}
          </Text>
        </Group>
        <Group noWrap>
          <Text size={"xs"} color="dimmed">
            {ev.subtitle}
          </Text>
        </Group>
      </Table.Td>

      {/* <Table.Td>{ev.description}</Table.Td> */}
    </Table.Tr>
  ));

  const { t } = useTranslation();
  return (
    <FormSectionCard title={title} {...props}>
      {/* currentDate: {moment(currentDate).format("LLL")} */}
      <Grid>
        <Grid.Col span={3}>
          <EventCalendar
            events={events}
            onMonthChange={(d) => {
              setSelectedDate(null);
              setCurrentDate(d);
            }}
            onSelectedDateChange={onSelectedDateChange}
            selectedDate={selectedDate}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 9, md: 12 }}>
          <ScrollArea h={"420px"} type="auto">
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th w={200}>
                    <Text size={headerSize}>{t("Date")}</Text>
                  </Table.Th>

                  <Table.Th w={150}>
                    <Text size={headerSize}>{t("Type")} </Text>
                  </Table.Th>
                  <Table.Th>
                    <Text size={headerSize}>{t("Event")}</Text>
                  </Table.Th>

                  <Table.Th></Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </ScrollArea>
        </Grid.Col>
      </Grid>
    </FormSectionCard>
  );
};

export default EventSection;
