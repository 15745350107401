import { Card, useComputedColorScheme } from "@mantine/core";
import { FormSectionCard } from "../layout/page";
import { Title, Group, Text } from "@mantine/core";
import _ from "lodash";
import JsonViewer from "./common/jsonViewer";
import { ReactP5Wrapper } from "@p5-wrapper/react";
import { on } from "events";
import { useServerApi } from "../hooks/useServerApi";
import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import p5 from "p5";
import { useId } from "@mantine/hooks";

const ObjViewer = ({ file, key, files, canvasSize = { w: 650, h: 400 } }) => {
  let obj;
  let texture;
  let normalMapImage;
  let isMouseInside = false;

  const [api] = useServerApi();
  const computedColorScheme = useComputedColorScheme("dark");
  const p5Ref = useRef();

  const handleMouseEnter = () => {
    isMouseInside = true;
  };
  const handleMouseLeave = () => {
    isMouseInside = false;
  };

  const onLoad = (model) => {
    console.log("On load success", model);
  };

  const onError = (error) => {
    console.log("On load error", error);
  };

  const loadTexture = (p5) => {
    //get texture file name
    const filname = file.name.split(".")[0];
    let textureFiles = files.filter(
      (f) =>
        f.name.includes(filname) &&
        (f.name.includes(".jpg") || f.name.includes(".png"))
    );
    if (textureFiles.length > 0) {
      texture = p5.loadImage(textureFiles[0].url);
    }
  };

  const loadModel = (p5) => {
    obj = p5.loadModel(file.url, true, onLoad, onError);
  };

  const sketch = (p5) => {
    // console.log("file");
    p5.setup = () => {
      p5.createCanvas(canvasSize.w, canvasSize.h, p5.WEBGL);
      // p5.loop();

      p5.canvas.addEventListener("mouseenter", handleMouseEnter);
      p5.canvas.addEventListener("mouseleave", handleMouseLeave);
    };

    p5.preload = () => {
      try {
        if (!file || !file.url) return;
        loadModel(p5);
        loadTexture(p5);
      } catch (error) {
        console.log("Preload Error", error);
      }
    };

    p5.draw = () => {
      //   p5.background(computedColorScheme === "dark" ? "black" : "#eee");
      p5.background("#aaa");

      //   p5.directionalLight(250, 250, 250, 0, 0, -1);

      if (obj) {
        p5.model(obj);
      }

      //seem not working

      if (normalMapImage) {
        // p5.normalMaterial(); // Apply default material with lighting
        p5.texture(normalMapImage);
      }
      if (texture) {
        p5.texture(texture);
        p5.noStroke();
      }
      //orbitcontrol only limit within the canvas area

      if (isMouseInside) {
        p5.orbitControl();
      }

      p5.loop();
    };
  };

  useEffect(() => {
    const mp5 = new p5(sketch, p5Ref.current);
    return mp5.remove;
  }, []);

  return (
    file && (
      <Card.Section inheritPadding py={"xs"} key={key} title={file.name}>
        <Group justify="space-between" mb="sm">
          <Text size="sm" fw={500} tt={"uppercase"}>
            {file.name}
          </Text>
        </Group>
        {/* <ReactP5Wrapper sketch={sketch} /> */}
        <div ref={p5Ref}></div>
      </Card.Section>
    )
  );
};

const isObjFile = (fileName) => {
  var fileExtension = fileName.split(".").pop();
  if (fileExtension === "obj") {
    return true;
  }
  return false;
};

const Illustrator = ({ form, name }) => {
  const files = _.get(form.values, name) || [];
  const { t } = useTranslation();
  //check extension of file which is *.obj
  const objFiles = files.filter((file) => isObjFile(file.name));
  return (
    <Card inheritPadding mih={300}>
      {/* <JsonViewer src={objFiles} /> */}
      {_.isEmpty(objFiles) && <Text>{t("No 3D object files upload")}</Text>}
      {/* <JsonViewer src={objFiles} /> */}
      {/* <ObjViewer key={0} file={objFiles[0]} files={files} /> */}

      {objFiles.map((file, index) => (
        <ObjViewer key={index} file={file} files={files} />
      ))}
    </Card>
  );
};

export default Illustrator;
