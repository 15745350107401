import React from "react";
import { Button, Text, TextInput, Chip, Select } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useFormRender } from "../hooks/useFormRender";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { IconSettings } from "@tabler/icons-react";
import FormConfirmation from "./formConfirmation";
import RemoteSelect2 from "../components/remoteSelect2";
import ConsultantList from "../components/consultantList";

import Chips from "../components/chips";
import {
  DATA_COUNTRY,
  DATA_EDUCATION_LEVEL,
  DATA_LANGUAGE,
  DATA_NATIONALITY,
  DATA_SALUATATION,
  DATA_ID_TYPE,
  DATA_CLIENT_TYPES,
  DATA_GENDERS,
  DATA_MARIAGE_STATUS,
} from "../data/options";

import AddressList from "../components/addressList";
import { useTranslation } from "react-i18next";
import { addFormSchema } from "../schema/form/client";

const ClientUpdateActionButton = ({ mainForm }) => {
  const { t } = useTranslation();
  const onSubmit = async ({ values, formMode }) => {
    try {
      mainForm.setValues(values);
      await formAction.updateAndSave(values);
    } catch (e) {
      console.log("UserEditButton error", e);
    }
  };

  const handleActionClick = () => {
    const id = mainForm?.values._id;
    if (!id) return;
    formAction.open({ mode: "edit", id, data: mainForm.values });
  };

  const onSubmitSuccess = () => {
    formAction.close();
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    addFormSchema,
    onSubmitSuccess,
    onSubmit
  );
  return (
    <>
      <Button fullWidth onClick={handleActionClick}>
        {t("Update Information")}
      </Button>

      {renderForm()}
    </>
  );
};

export default ClientUpdateActionButton;
