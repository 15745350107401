import { Select, TextInput, Textarea } from "@mantine/core";
import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import AutoCodeInput from "../../components/autoCodeInput";
import { DATA_UNIT_OF_MEASURE } from "../../data/options";
import ItemCateogryOptionsRow from "../../components/ItemCateogryOptionsRow";
import OrderList from "../../components/orderList";

export const ItemCategoryFormSchema = {
  title: "Item Category",
  isMainForm: true,
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },
  apiEntity: "itemCategory",

  initialValues: {
    code: "",
    name: "",
    cname: "",
    unitsOfMeasure: "",
  },

  layout: {
    containers: [
      {
        key: "section-basicInfo",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Basic Info",
          description: "Please fill in the basic info of product",
        },
      },
      {
        key: "section-options",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Options",
          description: "Please fill in the options of product",
        },
      },
    ],
    fields: [
      //   {
      //     name: "code",
      //     component: AutoCodeInput,
      //     props: {
      //       required: true,
      //       label: "Code",
      //       placeholder: "",
      //     },
      //   },
      {
        name: "name",
        component: TextInput,
        parent: "section-basicInfo",
        props: {
          required: true,
          label: "English Name",
          placeholder: "",
        },
      },
      {
        name: "cname",
        component: TextInput,
        parent: "section-basicInfo",

        props: {
          // required: true,
          label: "Chinese Name  ",
          placeholder: "",
        },
      },
      {
        name: "unitsOfMeasure",
        component: Select,
        parent: "section-basicInfo",

        props: {
          required: true,
          label: "Unit of Measure",
          placeholder: "",
          data: DATA_UNIT_OF_MEASURE,
          searchable: true,
          clearable: true,
        },
      },

      {
        name: "description",
        component: Textarea,

        parent: "section-basicInfo",

        props: {
          // required: true,
          label: "Description",
          placeholder: "",
          mb: "xl",
        },
      },
      {
        name: "options",
        component: OrderList,
        parent: "section-options",
        props: {
          onEnterSave: false,
          defaultRowValue: {},
          row: {
            component: ItemCateogryOptionsRow,
          },
        },
      },
    ],
  },
};
