import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash, IconCopy } from "@tabler/icons-react";
import { Text } from "@mantine/core";
import ProjectTableStatus from "../../components/projectTableStatus";

export const ProjectTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    searchByTextField: true,
    searchableFields: [
      "name",
      "code",
      "status",
      "client.name",
      "customer.name",
      "projectType",
    ],
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },

      {
        field: "name",
        headerName: "Name",
        sortable: true,
        width: 300,
      },
      {
        field: "duration.setup",
        headerName: "Setup",
        sortable: true,
        width: 150,
        cellRender: (col, data) =>
          data?.duration.setup
            ? moment(data?.duration.setup).format("YYYY-MM-DD HH:mm")
            : "--",
      },
      {
        field: "duration.start",
        headerName: "Start",
        sortable: true,
        width: 150,
        cellRender: (col, data) =>
          data?.duration.start
            ? moment(data?.duration.start).format("YYYY-MM-DD HH:mm")
            : "--",
      },
      {
        field: "client.name",
        headerName: "Client",
        sortable: true,
        width: 200,
      },

      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, data) => (
          <ProjectTableStatus
            status={data.status}
            id={data._id}
            formValue={data}
          />
        ),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
          {
            name: "copy",
            icon: IconCopy,
            props: {
              addCopyToCode: false,

              //   color:"red"
            },
          },
          {
            name: "delete",
            icon: IconTrash,
            props: {
              //   color:"red"
            },
          },
        ],
      },
    ],
  };
};
