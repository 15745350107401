import React, { useState, useEffect } from "react";
import { useServerApi } from "../hooks/useServerApi";
import {
  Badge,
  Box,
  Card,
  ScrollArea,
  Text,
  Group,
  Title,
  UnstyledButton,
  Flex,
} from "@mantine/core";
import moment from "moment";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const RecentProjectList = () => {
  const [groupedProjects, setgroupedProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [api] = useServerApi();
  const { t } = useTranslation();

  const fetchRecentProject = async () => {
    try {
      const { projects } = await api.Project.getRecentList();
      // console.log("projects", projects);
      if (!projects) return;
      const grouped = [];
      projects.forEach((project, index) => {
        project.startDate = moment(project.duration.start).format("YYYY/MM/DD");

        if (
          index === 0 ||
          project.startDate !== projects[index - 1].startDate
        ) {
          grouped.push({ date: project.startDate, projects: [project] });
        } else {
          grouped[grouped.length - 1].projects.push(project);
        }
      });
      // console.log(grouped);

      setgroupedProjects(grouped);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    fetchRecentProject();
  }, []);

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <ScrollArea h={350}>
            {groupedProjects.map((group, gIndex) => (
              <Box mb="md" key={gIndex}>
                <Group align="center" mb={"sm"}>
                  <Text size="xs" fw={"bold"}>
                    {`${moment(group.date).format("YYYY/MM/DD")} ${moment(
                      group.date
                    ).format("ddd")}`}
                  </Text>
                  <Badge color="indigo" variant="filled" size="xs">
                    {group.projects.length}
                  </Badge>
                </Group>
                {group.projects.map((project, index) => (
                  <Card
                    key={project.code}
                    mb="sm"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        `/project?id=${project._id}&mode=edit`,
                        "_blank"
                      )
                    }
                  >
                    <Text size="xs" fw={"bold"}>
                      {project?.name}
                    </Text>
                    <Text size="xs">{project?.client?.name}</Text>
                    <Text c="dimmed" size="xs" mb="md">
                      {project.address}
                    </Text>

                    {project.duration?.setup && (
                      <Group gap={"xl"}>
                        <Text c="dimmed" size="xs">
                          [ {t("Setup")} ]
                        </Text>
                        <Text c="dimmed" size="xs">
                          {moment(project.duration?.setup).format("LLL")} -
                          {moment(project.duration?.setupEnd).format("LLL")}
                        </Text>
                      </Group>
                    )}

                    <Group gap={"xl"}>
                      <Text c="dimmed" size="xs">
                        [ {t("Event")} ]
                      </Text>
                      <Text c="dimmed" size="xs">
                        {moment(project.duration?.start).format("LLL")} -
                        {moment(project.duration?.end).format("LLL")}
                      </Text>
                    </Group>

                    {project.duration?.dismantle && (
                      <Group>
                        <Text c="dimmed" size="xs">
                          [ {t("Dismantle")} ]
                        </Text>
                        <Text c="dimmed" size="xs">
                          {moment(project.duration?.dismantle).format("LLL")} -
                          {moment(project.duration?.dismantleEnd).format("LLL")}
                        </Text>
                      </Group>
                    )}
                  </Card>
                ))}
              </Box>
            ))}
          </ScrollArea>
          <Group justify="right">
            <UnstyledButton onClick={() => window.open("/project", "_self")}>
              <Text size="sm" color="dimmed">
                {t("View More")}
              </Text>
            </UnstyledButton>
          </Group>
        </>
      )}
    </div>
  );
};

export default RecentProjectList;
