import React from "react";
import { PageList } from "../components/pageList";
import { ClaimTableSchema } from "../schema/table/claim";
import { ClaimForm } from "../schema/form/claim";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";

const Claim = ({ title = "Claim" }) => {
  const [getTableSchema] = useTableSchema();
  const [getFormSchema] = useFormSchema();

  return (
    <PageList
      title={title}
      apiEntity={"claim"}
      tableSchema={getTableSchema("claim")}
      formSchema={getFormSchema("claim")}
      showImport={false}
      initSort={{ by: "code", order: "desc" }}
      searchByTextField={true}
      // stats={{
      //   component: ProjecPageStats,
      //   props: {
      //     mb: 35,

      //   },
      // }}
    />
  );
};

export default Claim;
