import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import RemarkList from "../../components/remarkList";
import FileList from "../../components/fileList";
import ActivityLog from "../../components/activityLog";
import ProjectSummaryCard from "../../components/projectSummaryCard";
import QuotationProjectDetail from "../../components/quotationProjectDetail";
import QuotationChargeItem from "../../components/quotationChargeItem";
import QuotationCostItem from "../../components/quotationCostItem";
import Terms from "../../components/terms";
import { DateInput } from "@mantine/dates";
import moment from "moment";
import remoteSelect2 from "../../components/remoteSelect2";
import {
  InputWrapper,
  TextInput,
  Textarea,
  Text,
  Box,
  Select,
} from "@mantine/core";
import AutoCodeInput from "../../components/autoCodeInput";
import QuotationProjectSection from "../../components/quotationProjectSection";
import QuotationActionList from "../../components/quotationActionList";
import DummyWidget from "../../components/dummyWidget";
import { useCellRender } from "../../hooks/useCellRender";
import DataSelect from "../../components/dataSelect";
import DateOffPicker from "../../components/dateOffPicker";
import QuotationDateInput from "../../components/quotationDateInput";
import remoteSelect from "../../components/remoteSelect";

const initialValues = {
  terms: [{ title: "", detail: "" }],
  quoDate: new Date(),
  validDate: moment().add(14, "days").toDate(),
};
const apiEntity = "quotation";

export const QuotationAddForm = {
  name: "addSchema",
  title: "New Quotation",
  description: "Please follow the step to create a New Quotation",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "1000px", //xs, sm, xl  ....100%
  },

  apiEntity,
  initialValues,

  layout: {
    containers: [],
    fields: [],
  },
};

export const QuotationEditForm = {
  name: "editSchema",
  title: "Edit Quotation",
  titleField: "code",
  description: "Please follow the step to create a New Quotation",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
  },

  apiEntity,
  initialValues,

  layout: {
    containers: [
      {
        key: "main-g",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "main-g1",
            props: {
              span: 3,
            },
          },
          {
            key: "main-g2",
            props: {
              span: 9,
            },
          },
        ],
      },
      {
        key: "main-tab",
        parent: "main-g2",
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Overview",
        },
        tab: [
          {
            key: "tab-overview",
            label: "Overview",
          },
          {
            key: "tab-file",
            label: "Files",
          },
          {
            key: "tab-log",
            label: "Logs",
          },
        ],
      },

      {
        key: "section-quotation-basic",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Basic Information",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      // {
      //   key: "section-project-info",
      //   parent: "tab-overview",
      //   type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
      //   props: {
      //     title: "Project Information",
      //     // titleOrder: 5,
      //     // description: "Please assign the corresponding consultant",
      //     withBorder: false,
      //     minHeight: "0px",
      //     mt: 0,
      //     mb: 20,
      //   },
      // },
      // {
      //   key: "section-customer-info",
      //   parent: "tab-overview",
      //   type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
      //   props: {
      //     title: "Customer Information",
      //     // titleOrder: 5,
      //     // description: "Please assign the corresponding consultant",
      //     withBorder: false,
      //     minHeight: "0px",
      //     mt: 0,
      //     mb: 20,
      //   },
      // },

      {
        key: "section-detail",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Details",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },

      {
        key: "detail-tab",
        parent: "section-detail",
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Charge Items",
        },
        tab: [
          {
            key: "tab-charge",
            label: "Charge Items",
          },
          {
            key: "tab-cost",
            label: "Cost Items",
          },
        ],
      },

      {
        key: "detail-tab",
        parent: "section-quotation-basic",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "basic-g1",
            props: {
              span: 6,
            },
          },

          {
            key: "basic-g2",
            props: {
              span: 6,
            },
          },
        ],
      },
      {
        key: "customer-tab",
        parent: "section-customer-info",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "customer-g1",
            props: {
              span: 6,
            },
          },
          {
            key: "customer-g2",
            props: {
              span: 6,
            },
          },
        ],
      },
      {
        key: "section-term",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Terms",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-remarks",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Remarks",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-file",
        parent: "tab-file",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Files",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
      {
        key: "section-log",
        parent: "tab-log",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Logs",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },
    ],
    fields: [
      {
        name: "project",
        component: QuotationProjectDetail,
        parent: "main-g1",
        props: {
          title: "Project Details",
        },
      },
      {
        name: "code",
        component: TextInput,
        parent: "basic-g1",
        props: {
          label: "Quo No. (Auto)",
        },
      },

      {
        name: "quoDate",
        component: QuotationDateInput,
        parent: "basic-g2",
        type: "dateInput",
        props: {
          label: "Quo Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
          defaultValue: new Date(),
          validDateField: "validDate",
        },
      },

      {
        name: "validDate",
        component: DateInput,
        parent: "basic-g2",
        type: "dateInput",
        props: {
          label: "Valid Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
          defaultValue: moment().add(14, "days").toDate(),
        },
      },

      {
        name: "project",
        component: remoteSelect2,
        parent: "basic-g1",
        props: {
          label: "Project",
          apiEntity: "project",
          valueField: "_id",
          labelField: "name",
          searchFields: ["name", "code"],
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },

      {
        name: "customer",
        component: remoteSelect2,
        parent: "basic-g2",
        props: {
          label: "Payer",
          apiEntity: "client",
          valueField: "_id",
          labelField: "name",
          searchFields: ["name", "cname", "code"],
          pageSize: 100,
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "contactPerson",
        component: DataSelect,
        parent: "basic-g1",
        props: {
          label: "Contact Person",
          sourceField: "customer.contactPersons",
          valueField: "_id",
          labelField: "name",

          sourceId: "customer._id",
          sourceApiEntity: "client",
          sourceApiField: "contactPersons",
          clearable: true,

          labelRender: (data) => data?.name,
        },
      },
      {
        name: "status",
        component: DummyWidget,
        parent: "basic-g1",
        props: {
          dummyRender: (data, cellRender) => {
            return (
              <InputWrapper label="Status" mb="xs">
                <Box>{cellRender.Quotation.status(data?.status)}</Box>
              </InputWrapper>
            );
          },
        },
      },

      // {
      //   name: "project",
      //   component: QuotationProjectSection,
      //   parent: "section-project-info",
      //   props: {},
      // },

      // {
      //   name: "Attn",
      //   component: TextInput,
      //   parent: "customer-g1",
      //   props: {
      //     label: "Attn",
      //   },
      // },
      // {
      //   name: "tel",
      //   component: TextInput,
      //   parent: "customer-g1",
      //   props: {
      //     label: "Tel",
      //   },
      // },
      // {
      //   name: "email",
      //   component: TextInput,
      //   parent: "customer-g2",
      //   props: {
      //     label: "Email",
      //   },
      // },
      // {
      //   name: "fax",
      //   component: TextInput,
      //   parent: "customer-g1",
      //   props: {
      //     label: "Fax",
      //   },
      // },
      // {
      //   name: "address",
      //   component: Textarea,
      //   parent: "customer-g2",
      //   props: {
      //     label: "Address",
      //     autosize: true,
      //     minRows: 1,
      //   },
      // },

      {
        name: "chargeItems",
        component: QuotationChargeItem,
        parent: "tab-charge",
        props: {},
      },

      {
        name: "costItems",
        component: QuotationCostItem,
        parent: "tab-cost",
        props: {},
      },

      {
        name: "terms",
        component: Terms,
        parent: "section-term",
        props: {
          templateCategory: "quotationTerms",
        },
      },
      {
        name: "remarks",
        component: RemarkList,
        parent: "section-remarks",
      },

      {
        name: "files",
        component: FileList,
        parent: "section-file",
      },
      {
        name: "log",
        component: ActivityLog,
        parent: "section-log",
      },
      {
        name: "actions",
        component: QuotationActionList,
        parent: "main-g1",
        props: {},
      },
    ],
  },
};

export const QuotationFormSchema = {
  add: QuotationAddForm,
  edit: QuotationEditForm,
};
