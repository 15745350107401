import {
  Avatar,
  Group,
  Button,
  Divider,
  Text,
  ActionIcon,
  Title,
  Box,
  SimpleGrid,
  UnstyledButton,
  Flex,
  useMantineTheme,
  Space,
  useComputedColorScheme,
} from "@mantine/core";

import {
  IconBuildingBank,
  IconBuildingSkyscraper,
  IconCalendar,
  IconCategory,
  IconCategory2,
  IconCircleLetterG,
  IconCircleNumber1,
  IconComponents,
  IconCurrency,
  IconExchange,
  IconGift,
  IconGlass,
  IconLadder,
  IconMoneybag,
  IconNumber,
  IconNumber1,
  IconServicemark,
  IconStar,
  IconTicket,
} from "@tabler/icons-react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "../layout/page";
import "to-color-string";
import { useTranslation } from "react-i18next";

const HomeSettings = [
  {
    icon: IconCalendar,
    label: "Company Events",

    description: "Add or edit company events.",
    link: "/management/companyEvents",
  },
];

const ProjectSettings = [
  {
    icon: IconCalendar,
    label: "Google Calendar",

    description: "Add or edit google Calednar.",
    link: "/management/googleCalendar",
  },
  {
    icon: IconCalendar,
    label: "Project Type",

    description: "Add or edit project types.",
    link: "/setting/projectType",
  },
];

const ItemSettings = [
  {
    icon: IconCategory,
    // color: "#ffec00",
    label: "Item Category",
    description: "Add or edit item category.",
    link: "/setting/itemCategory",
  },
  {
    icon: IconCategory,
    // color: "#ffec00",
    label: "Item Group",
    description: "Add or edit item group.",
    link: "/setting/ItemGroup",
  },
];

const AccountSetting = [
  {
    icon: IconCategory,
    // color: "#ffec00",
    label: "Claim Type",
    description: "Add or edit claim Type.",
    link: "/setting/claimType",
  },
];

const OtherSettings = [
  {
    icon: IconBuildingBank,
    // color: "#ffec00",
    label: "Organization",
    description: "Edit the organization details.",
    link: "/setting/organization",
  },
];
const SettingSections = [
  {
    label: "Home",
    settings: HomeSettings,
  },
  {
    label: "Project",
    settings: ProjectSettings,
  },
  {
    label: "Items",
    settings: ItemSettings,
  },
  {
    label: "Accounting",
    settings: AccountSetting,
  },

  {
    label: "Others",
    settings: OtherSettings,
  },
];

const SettingButton = ({ setting }) => {
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const { label, description, color, link } = setting;
  return (
    <UnstyledButton onClick={() => Navigate(link)}>
      <Flex maw={300} noWrap gap={"md"}>
        <ActionIcon
          size="xl"
          radius="xl"
          variant="light"
          bg={label.toColorString()}
          // bgp={color}
          onClick={() => Navigate(link)}
        >
          <setting.icon size={15} color={"white"} />
        </ActionIcon>
        <span>
          <Title order={6}> {t(label)} </Title>
          <Text size={"xs"} color="dimmed" lineClamp={2}>
            {t(description)}
          </Text>
        </span>
      </Flex>
    </UnstyledButton>
  );
};

const SettingSection = ({ section, spacing = 40, cols = 3 }) => {
  const { label, settings } = section;
  const theme = useMantineTheme();
  const colorScheme = useComputedColorScheme();
  const { t } = useTranslation();

  return (
    <Box mb={100}>
      <Text size={"sm"} weight={500}>
        {t(label)}
      </Text>
      <Divider
        mb={"xl"}
        mt="xs"
        color={
          colorScheme === "dark" ? theme.colors.gray[9] : theme.colors.gray[4]
        }
        size={"0.2"}
      />

      <SimpleGrid cols={cols} spacing={spacing}>
        {settings.map((setting) => (
          <SettingButton key={setting.label} setting={setting} />
        ))}
      </SimpleGrid>
    </Box>
  );
};

const Setting = ({}) => {
  return (
    <Page title={"Settings"}>
      <Space h={"xl"} />
      {SettingSections.map((s) => (
        <SettingSection key={s.label} section={s} />
      ))}
    </Page>
  );
};

export default Setting;
