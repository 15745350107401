import React from "react";
import {
  TextInput,
  Grid,
  Select,
  Group,
  Checkbox,
  NumberInput,
  Box,
  InputWrapper,
  Button,
} from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import RemoteSelect2 from "./remoteSelect2";
import { DATA_ITEM_ASSIGN_METHOD } from "../data/options";
import OrderList from "./orderList";
import { AssignTeamMemberRow } from "./AssignTeamMemberRow";
import { useTranslation } from "react-i18next";

export const TeamMemberAssign = ({ form, name }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <InputWrapper label="Members">
        <OrderList
          form={form}
          name={name}
          row={{
            component: AssignTeamMemberRow,
          }}
          defaultRowValue={{ user: null, quantity: 1 }}
        />
      </InputWrapper>
    </Box>
  );
};
