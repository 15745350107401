import React, { useRef, useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { Group, Text, Tabs, Center } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons-react";
import GroupMenu from "./groupMenu";
import ProductionSectionGroup from "./productionSectionGroup";

const ProductionSectionItemGroups = ({
  itemGroups,
  sectionIndex,
  form,
  handleRemoveGroup,
  handleAddGroup,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Tabs defaultValue={"0"} variant="outline">
        <Tabs.List>
          <Center w={50}>
            <IconCirclePlus
              size={20}
              onClick={handleAddGroup}
              style={{ cursor: "pointer" }}
            />
          </Center>
          {/* <Button size="xs" variant="default" onClick={handleAddGroup} mr="xs">
              Add Group
            </Button> */}
          {itemGroups.map((group, groupIndex) => (
            <Tabs.Tab value={groupIndex + ""} size={"xs"} key={groupIndex}>
              <Group>
                <Text size="xs">{group.name}</Text>
                <GroupMenu
                  menu={[
                    {
                      label: t("Remove Group"),
                      color: "red",
                      onClick: () => handleRemoveGroup(groupIndex),
                    },
                  ]}
                />
              </Group>
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {itemGroups.map((group, groupIndex) => (
          <ProductionSectionGroup
            group={group}
            form={form}
            sectionIndex={sectionIndex}
            groupIndex={groupIndex}
          />
        ))}
      </Tabs>
    </>
  );
};

export default ProductionSectionItemGroups;
