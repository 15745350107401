import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/useServerApi";
import { useLocalStorage } from "@mantine/hooks";
import { Button, Center, Title, Box, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

const GoogleAuthRedirect = () => {
  //get the code from the url by using the URLSearchParams API
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const [api] = useServerApi();
  const [token, setToken] = useLocalStorage({
    key: "one2work-googleCalendar",
    defaultValue: null,
  });

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchToken = async (code) => {
    try {
      if (!code) return;
      const result = await api.GoogleCalendar.getToken(code);
      if (!result || !result.token) throw "No token returned from Google API.";

      //save to localstorage
      setToken(JSON.stringify(result.token));
      setLoading(false);
      // console.log(result.token);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (!code) return;
    fetchToken(code);
  }, [code]);

  return (
    <>
      <Box ml={"xl"} mt="xl">
        <Title order={3} mb="xl">
          Google Authorization
        </Title>
        <Button loading={loading} variant="default">
          {t(loading ? "Authorizing" : error ? t("Error") : t("Authorized"))}
        </Button>
        {error && <Text>{t("Please go back try again")}</Text>}
      </Box>
    </>
  );
};

export default GoogleAuthRedirect;
