import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { AddActionButton, PageList } from "./pageList";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";
import JsonViewer from "./common/jsonViewer";
import DataTable from "./dataTable";
import { useServerApi } from "../hooks/useServerApi";
import AddNewButton from "./addNewButton";
import { useFormRender } from "../hooks/useFormRender";
import { Button } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";

const RelatedDataTable = ({
  mainForm,
  tableSchema,
  formSchema,
  relatedField,
  fieldIn,
  fieldOut,
  apiEntity,
  onPostSubmit,
  onPostDelete,
  onPostRefresh,
  onItemPrint,
  hideAddButton = false,
  hidePagination = true,
  pageSize = 1000,

  ...props
}) => {
  const [api] = useServerApi();
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const relatedFieldValue = _.get(mainForm.values, fieldIn);

  const fetchData = async () => {
    try {
      if (!relatedFieldValue) return;
      const result = await api.search({
        apiEntity,
        searchQuery: { [fieldOut]: relatedFieldValue },
        pageSize,
      });
      // console.log("RelateDataTable > fetchData", result);
      if (result.docs) setData(result.docs);
      if (onPostRefresh) onPostRefresh(result.docs);
    } catch (error) {
      console.log(error);
    }
  };

  //after succes add
  const onSubmitSuccess = async () => {
    await fetchData();
    if (onPostSubmit) onPostSubmit();
  };

  //After delete

  useEffect(() => {
    fetchData();
  }, [relatedFieldValue]);

  const copyToNew = async (data) => {
    const newData = { ...data };
    delete newData._id;
    delete newData.createdAt;
    delete newData.updatedAt;
    delete newData.__v;
    delete newData.status;

    if (newData.code) {
      newData.code += "(copy)";
    }
    let result = await api.add({ apiEntity, values: newData });
    return result?.data;
  };

  const onActionBtnClick = async ({ action, data }) => {
    // console.log("onActionBtnClick", action, data);
    const { name: actionName, linkPath: actionLinkPath } = action;
    switch (actionName) {
      case "show":
        formAction.open({ mode: "show", id: data._id });
        break;
      case "edit":
        // console.log("location edit", `${location.pathname}?id=${data._id}`);
        // formAction.openIdByLink({ mode: "edit", id: data._id });
        formAction.open({ mode: "edit", id: data._id });
        break;
      case "copy":
        const newRecord = await copyToNew(data);
        formAction.open({ mode: "edit", id: newRecord._id });
        break;

      case "print":
        if (!onItemPrint) return;
        onItemPrint(data._id);
        break;

      case "openEdit":
        console.log("openEdit", actionLinkPath, data._id);
        // formAction.open({ mode: "edit", id: data._id });
        // navigate(`/${actionLinkPath}?id=${data._id}&mode=edit`, {
        //   target: "_blank",
        // });

        window.open(`${actionLinkPath}?id=${data._id}&mode=edit`, "_blank");
        // navigate(`/${actionLinkPath}?id=${data._id}&mode=edit`, {
        //   target: "_blank",
        // });

        break;

      case "delete":
        // console.log("delete", data._id);
        const confirm = window.confirm(
          `Are you sure to delete the data ${data.code}?`
        );
        if (confirm) {
          await api.removeById({ apiEntity, id: data._id });
          await fetchData();
          if (onPostDelete) onPostDelete();
        }
        break;
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSuccess
  );

  const addNew = () => {
    if (!formSchema) return;
    // console.log("add new", formSchema);
    formAction.open({ mode: "add" });
  };

  return (
    <>
      {/* relatedFieldValue: {relatedFieldValue}
      relatedField: {relatedField} */}
      <DataTable
        columns={tableSchema.columns}
        data={data}
        onActionBtnClick={onActionBtnClick}
        hidePagination={hidePagination}
        hideSearchBar={true}
        hideActionButton={true}
        pageSize={100}
        withPaper={false}
      />
      {
        //Important! relatedFieldValue is required before render the form
      }

      <Group gap="xs">
        {relatedFieldValue && !hideAddButton && (
          <AddNewButton
            formSchema={formSchema}
            onSubmitSuccess={onSubmitSuccess}
            onChange={fetchData}
          />
        )}
        {
          <Button onClick={fetchData} variant="default" size="xs">
            {t("Refresh")}
          </Button>
        }
      </Group>
      {/* {relatedFieldValue && (
        <AddActionButton justify="left" mb="0" handleActionClick={addNew} />
      )} */}
      {relatedFieldValue && renderForm()}
    </>
  );
};

export default RelatedDataTable;
