import React, { useEffect, useState } from "react";
import { FormSectionCard } from "../layout/page";
import {
	Title,
	Group,
	Card,
	Divider,
	Text,
	Avatar,
	Container,
	Space,
} from "@mantine/core";
import { useCellRender, openWhatsapp } from "../hooks/useCellRender";
import _ from "lodash";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import {
	IconAddressBook,
	IconMap,
	IconMessage,
	IconPin,
} from "@tabler/icons-react";
import { IconPinned } from "@tabler/icons-react";
import { useServerApi } from "../hooks/useServerApi";
import { useTranslation } from "react-i18next";
// import ReactJson from "react-json-view";
// import JsonViewer from "./common/jsonViewer";

const TeamMemberAvatar = ({ member, roleName }) => {
	const [user, setUser] = useState();
	const [api] = useServerApi();

	const fetchUser = async (userId) => {
		if (!userId) return;
		const u = await api.getById({ apiEntity: "user", id: userId });
		// console.log(u);
		setUser(u);
	};

	useEffect(() => {
		// console.log("member", member);
		fetchUser(member.user);
	}, [member.user]);

	//   <Avatar
	//   src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-3.png"
	//   alt="Jacob Warnhalter"
	//   radius="xl"
	// />

	return (
		<Group my="xs" gap="xs">
			<Avatar src={user?.image} alt={user?.aliasName ?? "dummy"} radius="xl" />
			<span>
				<Text color="dimmed" size="xs">
					{member.roleName || roleName}
				</Text>
				{user && (
					<Group justify="space-between" w={150}>
						<Text size="xs">{user?.aliasName}</Text>
						<Group
							gap="2"
							onClick={() => openWhatsapp(user?.phone)}
							style={{ cursor: "pointer" }}
						>
							<IconMessage size="15" />
							<Text size="xs">{user?.phone}</Text>
						</Group>
					</Group>
				)}
				{!user && <Text size="xs"> x {member.quantity}</Text>}
			</span>
		</Group>
	);
};
/**
 *
 * @param {viewMode} place view directions streetview search
 * @param {maptype} roadmap or satellite
 * @returns
 *
 */
function TeamCard({
	form,
	name,
	shadow = "md",
	viewMode = "view",
	maptype = "roadmap",
}) {
	const [cellRender] = useCellRender();
	const team = _.get(form.values, name);
	const projectManager = _.get(form.values, "projectManager");
	const salesPerson = _.get(form.values, "salesPerson");
	const { t } = useTranslation();

	return (
		<Card shadow={shadow}>
			{/* <JsonViewer src={projectManager} /> */}
			<Card.Section withBorder inheritPadding py="xs">
				<Text size="sm" fw={500}>
					{t("Team")}
				</Text>
			</Card.Section>
			<Card.Section inheritPadding py="xs" withBorder>
				{/* {cellRender.User(projectManager, false, "Project Manager")} */}
				{projectManager && (
					<TeamMemberAvatar
						member={{
							user: projectManager._id,
							roleName: "Project Manager",
							quantity: 1,
						}}
					/>
				)}

				{salesPerson && (
					<TeamMemberAvatar
						member={{
							user: salesPerson._id,
							roleName: "Sales Person",
							quantity: 1,
						}}
					/>
				)}

				{team.map((member, index) => (
					<TeamMemberAvatar member={member} key={`member-${index}`} />
				))}
			</Card.Section>
		</Card>
	);
}

export default TeamCard;
