import {
  Accordion,
  Button,
  Text,
  Title,
  Table,
  Select,
  Group,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useServerApi } from "../../hooks/useServerApi";
import { DatePicker, DateInput } from "@mantine/dates";

import { DATA_REPORT_DATE_TYPE } from "../../data/options";
import { useTranslation } from "react-i18next";

const ReportClaimSettlement = ({ description }) => {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [api] = useServerApi();

  const downloadReport = async () => {
    try {
      setLoading(true);
      // console.log(fromDate, toDate);
      const result = await api.Report.getClaimSettlementReport({
        fromDate,
        toDate,
      });
      setLoading(false);
      // console.log(result);
      if (result && result.data && result.data.url) {
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Text size="xs">{description}</Text>
      <Group mt="xs">
        <DateInput
          title="From"
          size="xs"
          allowFreeInput
          placeholder="From"
          onChange={(d) => setFromDate(d)}
        />
        -
        <DateInput
          title="To"
          size="xs"
          allowFreeInput
          placeholder="To"
          onChange={(d) => setToDate(d)}
        />
      </Group>
      <Button
        size="xs"
        mt="xl"
        onClick={() => downloadReport()}
        loading={loading}
      >
        {t("Download")}
      </Button>
      <></>
    </div>
  );
};

export default ReportClaimSettlement;
