import {
  IconCalendarStats,
  IconUser,
  IconFileText,
  IconHome,
  IconUsers,
  IconFileInvoice,
  IconCalendarEvent,
  IconLayoutDashboard,
  IconSettings2,
  IconReport,
  IconCoinMonero,
  IconBox,
  IconSubtask,
  IconReportAnalytics,
} from "@tabler/icons-react";

import Login from "../pages/login";
import ForgetPassword from "../pages/forgetPassword";
import ResetPassword from "../pages/resetPassword";
import Home from "../pages/home";
import Report from "../pages/report";
import DashBoard from "../pages/dashboard";
import Calendars from "../pages/calendars";
import Project from "../pages/project";
import Client from "../pages/client";
import Item from "../pages/item";
import Claim from "../pages/claim";
import MyClaim from "../pages/myClaim";
import ClaimPayment from "../pages/claimPayment";
import ClaimSettlement from "../pages/claimSettlement";
import Task from "../pages/task";
import Quotation from "../pages/quotation";
import Invoice from "../pages/invoice";
import Statement from "../pages/statement";
import User from "../pages/user";
import Setting from "../pages/setting";
import ItemCategory from "../pages/itemCategory";
import ItemGroup from "../pages/itemGroup";
import Organization from "../pages/organization";
import ClaimType from "../pages/claimType";
import ProjectType from "../pages/projectType";
import GoogleAuthRedirect from "../pages/googleAuthRedirect";
import GoogleCalendarSetting from "../pages/googleCalendarSetting";
import CalendarEvents from "../pages/calendarEvents";

export const routes = [
  {
    link: "/login",
    id: 0, //Public
    showOnNavBar: false,
    description: "Login Page",
    private: false,
    element: <Login />,
    isLoginRoute: true,
  },
  {
    link: "/forgetPassword",
    id: 0, //Public
    showOnNavBar: false,
    description: "Forget Password Page",
    private: false,
    element: <ForgetPassword />,
  },

  {
    link: "/google/auth/redirect",
    id: 0, //Public
    showOnNavBar: false,
    description: "Google Auth redirect",
    private: false,
    element: <GoogleAuthRedirect />,
  },

  {
    icon: IconHome,
    label: "Home",
    link: "/home",
    id: "1",
    showOnNavBar: true,
    description: "Home page",
    private: true,
    element: <Home />,
    isDefaultHome: true,
  },

  {
    icon: IconLayoutDashboard,
    label: "Dashboard",
    link: "/dashboard",
    id: "2",
    showOnNavBar: true,
    description: "Dashboard Page",
    private: true,
    element: <DashBoard />,
  },

  {
    icon: IconCalendarEvent,
    label: "Calendar",
    link: "/calendar",
    id: "3",
    showOnNavBar: true,
    description: "Calendar Page",
    private: true,
    element: <Calendars />,
  },

  {
    icon: IconCalendarStats,
    label: "Project",
    link: "/project",
    id: "4",
    showOnNavBar: true,
    description: "Project Page",
    private: true,
    element: <Project />,
  },

  {
    icon: IconUsers,
    label: "Client",
    link: "/client",
    id: "5",
    showOnNavBar: true,
    description: "Client Page",
    private: true,
    element: <Client />,
  },

  {
    icon: IconBox,
    label: "Item",
    link: "/item",
    id: "6",
    showOnNavBar: true,
    description: "Item Page",
    private: true,
    element: <Item />,
  },
  {
    icon: IconCoinMonero,
    label: "Claim",
    link: "/claim",
    id: "7",
    showOnNavBar: true,
    description: "Claim Page",
    private: true,
    element: <Claim />,
  },
  {
    icon: IconCoinMonero,
    label: "My Claim",
    link: "/myClaim",
    id: "7-2",
    showOnNavBar: true,
    description: "My Claim Page",
    private: true,
    element: <MyClaim />,
  },
  // {
  //   icon: IconCoinMonero,
  //   label: "Claim Payment",
  //   link: "/claimPayment",
  //   id: "7",
  //   showOnNavBar: true,
  //   description: "Claim Payment Page",
  //   private: true,
  //   element: <ClaimPayment />,
  // },
  {
    icon: IconCoinMonero,
    label: "Claim Settlement",
    link: "/claimSettlement",
    id: "7",
    showOnNavBar: true,
    description: "Claim Settlement Page",
    private: true,
    element: <ClaimSettlement />,
  },

  {
    icon: IconSubtask,
    label: "Task",
    link: "/task",
    id: "8",
    showOnNavBar: true,
    description: "Task Page",
    private: true,
    element: <Task />,
  },

  {
    icon: IconFileText,
    label: "Quotation",
    link: "/quotation",
    id: "9",
    showOnNavBar: true,
    description: "Quotation Page",
    private: true,
    element: <Quotation />,
  },
  {
    icon: IconFileInvoice,
    label: "Invoice",
    link: "/invoice",
    id: "10",
    showOnNavBar: true,
    description: "Invoice Page",
    private: true,
    element: <Invoice />,
  },
  // {
  //   icon: IconReportAnalytics,
  //   label: "Statement",
  //   link: "/statement",
  //   id: "11",
  //   showOnNavBar: true,
  //   description: "Statement Page",
  //   private: true,
  //   element: <Statement />,
  // },

  {
    icon: IconReport,
    label: "Report",
    link: "/report",
    id: "12",
    showOnNavBar: true,
    description: "Report Page",
    private: true,
    element: <Report />,
  },

  {
    icon: IconUser,
    label: "User",
    link: "/user",
    id: "13",
    showOnNavBar: true,
    description: "User Setting Page",
    private: true,
    element: <User />,
  },

  {
    icon: IconSettings2,
    label: "Settings",
    link: "/setting",
    id: "14",
    showOnNavBar: true,
    description: "Setting Page",
    private: true,
    element: <Setting />,
  },

  {
    link: "/resetPassword",
    id: "15",
    showOnNavBar: false,
    description: "Reset Password Page",
    private: true,
    element: <ResetPassword />,
  },

  {
    link: "/setting/itemCategory",
    id: "16",
    showOnNavBar: false,
    description: "Item Category Setting",
    private: true,
    element: <ItemCategory />,
  },

  {
    link: "/setting/itemGroup",
    id: "17",
    showOnNavBar: false,
    description: "Item Group Type Setting",
    private: true,
    element: <ItemGroup />,
  },

  {
    link: "/setting/organization",
    id: "18",
    showOnNavBar: false,
    description: "Orgranaiztion Setting ",
    private: true,
    element: <Organization />,
  },
  {
    link: "/setting/claimType",
    id: "19",
    showOnNavBar: false,
    description: "Claim type setting ",
    private: true,
    element: <ClaimType />,
  },
  {
    link: "/setting/projectType",
    id: "20",
    showOnNavBar: false,
    description: "Project type setting ",
    private: true,
    element: <ProjectType />,
  },
  {
    link: "/management/googleCalendar",
    id: "20",
    showOnNavBar: false,
    description: "Google Calendar Setting.",
    private: true,
    element: <GoogleCalendarSetting />,
  },
  {
    link: "/management/companyEvents",
    id: "20",
    showOnNavBar: false,
    description: "Company Events Setting.",
    private: true,
    element: <CalendarEvents />,
  },
];
