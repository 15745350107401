import { Tabs, Button, Group, Container, Text, Divider } from "@mantine/core";
import React, { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
const FormWizard = ({
  wizard,
  getChildContainers,
  getChildFields,
  renderContainer,
  renderField,
  confirmSubmit,
  showDivider = true,
  form,
  ...props
}) => {
  const [currentTab, setCurrentTab] = useState(wizard.defaultValue);
  const [confirming, setConfirming] = useState(false);
  const { t: translate } = useTranslation();
  const handleConfirm = async () => {
    if (!_.isFunction(confirmSubmit)) return console.log("Confirm Submit");
    if (confirming) return;

    setConfirming(true);
    await confirmSubmit();
    setConfirming(false);
  };

  const toPage = (index) => {
    if (index < 0 || index > wizard.steps.length - 1) return;
    setCurrentTab(wizard.steps[index].label);
  };

  const renderNextButton = (index) => {
    return index === wizard.steps.length - 1 ? (
      <Button
        onClick={handleConfirm}
        disabled={!form.isValid()}
        loading={confirming}
      >
        Confirm
      </Button>
    ) : (
      <Button onClick={() => toPage(index + 1)}>Next </Button>
    );
  };

  const renderPrevButton = (index) => {
    return index === 0 ? null : (
      <Button variant="default" onClick={() => toPage(index - 1)}>
        Prev
      </Button>
    );
  };
  return (
    <>
      {/* {<ReactJson src={form} style={{ background: "white" }} collapsed />} */}

      {showDivider && <Divider />}
      <Tabs value={currentTab} {...wizard.props} key={wizard.key}>
        <Tabs.List pt={"md"}>
          {wizard.steps?.map(({ label, key, iconSize = 14, ...t }, index) => (
            <Tabs.Tab
              key={key}
              // icon={<t.icon size={iconSize} />}
              onClick={() => toPage(index)}
              value={label}
            >
              <Text size={"xs"}>{translate(label)}</Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {wizard.steps?.map(({ label, key }, index) => (
          <Tabs.Panel key={key} value={label} ml="xl" pt={"xl"}>
            <Container
              style={{
                // background: "red",
                minHeight: "100%",
                justifyContent: "space-between",
              }}
            >
              <>
                {getChildContainers(key).map((c) => renderContainer(c))}
                {getChildFields(key).map((f) => renderField(f))}
              </>
              <Group justify="right" mt={"xl"} ml={"sm"}>
                <span>{renderPrevButton(index)}</span>
                <span>{renderNextButton(index)}</span>
              </Group>
            </Container>
          </Tabs.Panel>
        ))}
      </Tabs>
    </>
  );
};

export default FormWizard;
