import CalendarChart from "./chartWidget/CalendarChart";
import ItemTransactionHistory from "./itemTransactionHistory";
import { useServerApi } from "../hooks/useServerApi";
import React, { useEffect, useState } from "react";
import { FormSectionCard } from "../layout/page";
import { Button, Group, Text } from "@mantine/core";

import { ResponsiveCalendar } from "@nivo/calendar";
import { useFormSchema } from "../hooks/useFormSchema";
import _ from "lodash";
import JsonViewer from "./common/jsonViewer";
import { PeriodFilter } from "./periodFilter";

const ItemReservationCalendar = ({
  form,
  title,
  height,
  p,
  description,
  chartProps,
  showPeriod = 3,
  margin = { top: 0, right: 0, bottom: 0, left: 0 },
}) => {
  const itemId = _.get(form.values, "_id");
  const [data, setData] = useState([]);
  const [api] = useServerApi();
  const [period, setPeriod] = useState({});

  const onPeriodChange = (period) => {
    setPeriod(period);
  };
  const fetchData = async () => {
    try {
      if (!itemId || _.isEmpty(period)) return;
      const result = await api.Item.getReservationCalendar(
        itemId,
        period.startDate,
        period.endDate
      );
      console.log(result);

      if (result.data) setData(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [itemId, period]);

  return (
    <>
      {/* <JsonViewer src={data} /> */}
      {/* {itemId} */}

      <Group justify="right">
        <PeriodFilter
          numPeriods={showPeriod}
          handlePeriodChange={onPeriodChange}
          handleRefresh={fetchData}
          position="right"
          forward={false}
          by="Y"
        />
      </Group>
      <CalendarChart
        title={title}
        height={height}
        description={description}
        chartProps={chartProps}
        staticData={data}
        margin={margin}
        useSampleData={false}
        p={p}
        onRefresh={fetchData}
        onPeriodChange={onPeriodChange}
        showPeriod={3}
      />
    </>
  );
};

export default ItemReservationCalendar;
