import React from "react";
import {
  Badge,
  InputWrapper,
  NumberInput,
  Progress,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { useFormatter } from "../hooks/useFomatter";
import _ from "lodash";
import { IconAlertTriangle } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const ClientCreditSetting = ({ form, name }) => {
  const [formatter] = useFormatter();

  const overDuePayment = _.get(form.values, "overDuePayment");
  const maximumCredit = _.get(form.values, "maximumCredit");
  const usage = _.round(100 * (overDuePayment / maximumCredit), 2);
  const safePercent = 80;
  const { t } = useTranslation();
  const { totalAmount, paidAmount, invoiceCount, unpaidCount } = form.values;
  return (
    <SimpleGrid cols={3}>
      <InputWrapper label={t("Total Invoice Count")}>
        <Text size="md">{invoiceCount}</Text>
      </InputWrapper>

      <InputWrapper label={t("Upaid Invoice")}>
        <Text size="md">{unpaidCount}</Text>
      </InputWrapper>
      <InputWrapper label={t("Overdue Amount")}>
        <Text size="md">{formatter.currency(overDuePayment)}</Text>
      </InputWrapper>
      <InputWrapper label={t("Total Invoice Amount")}>
        <Text size="md">{formatter.currency(totalAmount)}</Text>
      </InputWrapper>

      <InputWrapper label={t("Paid Amount")}>
        <Text size="md">{formatter.currency(paidAmount)}</Text>
      </InputWrapper>

      <NumberInput
        {...form.getInputProps("maximumCredit")}
        precision={2}
        thousandSeparator={true}
        label={t("Maximum Credit")}
      />

      <InputWrapper label={t("Credit Usage")}>
        <Text size="md">{formatter.percentage(usage)}</Text>
      </InputWrapper>
      {/* <span>
        <Text size="sm" mb="md">
          Credit Usage
          <Badge
            radius={0}
            ml="md"
            size="xs"
            color={usage >= 100 ? "red" : "blue"}
          >
            {formatter.percentage(usage)}
          </Badge>
        </Text>

        <Progress
          value={usage}
          radius={0}
          h={15}
          color={usage >= 100 ? "red" : "blue"}
        />
      </span> */}
    </SimpleGrid>
  );
};

export default ClientCreditSetting;
