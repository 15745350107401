import React from "react";
import { PageList } from "../components/pageList";
import { ClaimTableSchema } from "../schema/table/claim";
import { ClaimForm } from "../schema/form/claim";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";
import { useTranslation } from "react-i18next";

const ClaimSettlement = ({ title = "Claim Settlement" }) => {
  const [getTableSchema] = useTableSchema();
  const [getFormSchema] = useFormSchema();
  const { t } = useTranslation();
  return (
    <PageList
      title={t(title)}
      apiEntity={"claimSettlement"}
      tableSchema={getTableSchema("claimSettlement")}
      formSchema={getFormSchema("claimSettlement")}
      showImport={false}
      initSort={{ by: "code", order: "desc" }}
      searchByTextField={true}
      // stats={{
      //   component: ProjecPageStats,
      //   props: {
      //     mb: 35,

      //   },
      // }}
    />
  );
};

export default ClaimSettlement;
