import {
  Text,
  TextInput,
  Select,
  Chip,
  Checkbox,
  Badge,
  Textarea,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React, { useState, useEffect } from "react";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { IconTrash, IconPencil, IconSettings } from "@tabler/icons-react";
import ActionCellRender from "../../components/actionCellRender";
import Chips from "../../components/chips";
import { DATA_CLIENT_INDUSTRY, DATA_CLIENT_TYPES } from "../../data/options";
import RemoteSelect2 from "../../components/remoteSelect2";
import ConsultantList from "../../components/consultantList";
import ClientBasicInfo from "../../components/clientBasicInfo";
import ClientActionList from "../../components/clientActionList";
import ClientOrderHistory from "../../components/clientOrderHistory";
import ClientRiskStats from "../../components/clientRiskStats";
import ClientAMLList from "../../components/clientAMLList";
import ClientRPQList from "../../components/clientRPQList";

import ClientPageStats from "../../components/clientPageStats";
import AddressList from "../../components/addressList";

import ActivityLog from "../../components/activityLog.js";
import FollowupActionTable from "../../components/followupActionTable.js";
import UniqueTextInput from "../../components/uniqueTextInput";
import FormConfirmation from "../../components/formConfirmation";
import { useCellRender } from "../../hooks/useCellRender";

import FileList from "../../components/fileList";
import { useAuthHeader, useAuthUser } from "react-auth-kit";

import { authName } from "../../App";
import { useNavigate } from "react-router-dom";
import RemarkList from "../../components/remarkList";
import ContactList from "../../components/contactList.js";
import ClientInvoiceHistory from "../../components/clientInvoiceHistory.js";
import ClientStatementHistory from "../../components/clientStatementHistory.js";
import ClientCreditSettting from "../../components/clientCreditSettting.js";

import ClientInvoiceList from "../../components/clientInvoiceList.js";
import ClientOrderList from "../../components/clientOrderList.js";

import { clientAddFormSchema } from "./clientAddForm.js";
const apiEntity = "client";
const initialValues = {
  name: "",
  cname: "",
  aliasName: "",
  type: "DIRECT",
  address: "",
  consultants: [],
  language: "CANTONESE",
  status: "ACTIVE",
};

export const addFormSchema = {
  title: "New Client",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "xl", //xs, sm, xl  ....100%
    // position:"right"
  },

  showSaveBar: false,
  apiEntity,
  initialValues,

  validate: {},

  layout: {
    containers: [
      {
        key: "t1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.WIZARD,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Basic Info",
          orientation: "vertical",
          style: { minHeight: "400px" },
        },
        steps: [
          // { key: "t1-1", label: "Type", icon: IconSettings },
          { key: "t1-3", label: "Basic Info", icon: IconSettings },
          { key: "t1-6", label: "Address", icon: IconSettings },
          { key: "t1-4", label: "Contacts", icon: IconSettings },
          { key: "t1-5", label: "Confirm", icon: IconSettings },
        ],
      },
      {
        key: "section-type",
        parent: "t1-1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Client Types",
          description: "Please choose type for client",
          withBorder: false,
          withPaper: false,
          ml: 10,
        },
      },

      {
        key: "section-basicInfo",
        parent: "t1-3",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Basic Info",
          titleOrder: 5,
          description: "Please enter basic info of client",
          withBorder: false,
          withPaper: false,

          ml: 10,
          mt: 0,
          mb: 10,
        },
      },

      {
        key: "basicInfo-g",
        parent: "section-basicInfo",
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "basicInfo-g0",
            props: {
              span: 12,
              mt: 0,
            },
          },
          {
            key: "basicInfo-g1",
            props: {
              span: 6,
            },
          },
          {
            key: "basicInfo-g2",
            props: {
              span: 6,
            },
          },
        ],
      },

      {
        key: "section-contact",
        parent: "t1-4",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Contact Person",
          titleOrder: 5,
          description: "Please assign the biz contact person details",
          withBorder: false,
          withPaper: false,
        },
      },

      {
        key: "section-confirm",
        parent: "t1-5",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Confirm",
          titleOrder: 3,
          description:
            "Please confirm to add client or you can go back to revise.",
          withBorder: false,
          withPaper: false,

          ml: 10,
          mt: 0,
          mb: 10,
        },
      },

      {
        key: "section-address",
        parent: "t1-6",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          // title: "Business address",
          titleOrder: 3,
          description: "Please fill in the address info",
          withBorder: false,
          withPaper: false,

          ml: 10,
          mt: 0,
          mb: 10,
        },
      },
    ],

    fields: [
      {
        name: "type",
        component: Chips,
        parent: "section-type",
        props: {
          required: true,
          labelOrder: 5,
          description: "Please Select the type of client.",
          items: DATA_CLIENT_TYPES,
          multiple: false,
        },
      },

      {
        name: "name",
        component: TextInput,
        parent: "basicInfo-g0",
        props: {
          required: true,
          placeholder: "Company Name",
          label: "Company Name (ENG)",
        },
      },
      {
        name: "cname",
        component: TextInput,
        parent: "basicInfo-g0",
        props: {
          placeholder: "公司名稱",
          label: "Chinese Name (CHI)",
        },
      },
      {
        name: "industry",
        component: Select,
        parent: "basicInfo-g0",
        props: {
          label: "Business Industry",
          placeholder: "",
          data: DATA_CLIENT_INDUSTRY.map((ind) => ({
            label: `${ind.label}  ${ind.clabel} (${ind.value})`,
            value: ind.value,
          })),
          searchable: true,
          clearable: true,
        },
      },

      {
        name: "email",
        component: TextInput,
        parent: "basicInfo-g2",
        props: {
          // required: true,
          placeholder: "taiman@gmail.com",
          label: "Email",
        },
      },
      {
        name: "phoneNumber",
        component: TextInput,
        parent: "basicInfo-g1",
        props: {
          // required: true,
          placeholder: "30005000",
          label: "Business phone",
        },
      },
      {
        name: "saleman",
        component: RemoteSelect2,
        parent: "basicInfo-g1",
        props: {
          apiEntity: "user",
          required: true,
          label: "Saleman",
          placeholder: "Assign Saleman(s)",
          valueField: "_id",
          labelField: "name",
          labelRender: (data) =>
            `${data.name}, ${data.aliasName} (${data.staffId ?? " -- "})`,
          multiple: false,
          // preQuery,
          // preQuery:  {isConsultant : true} ,
          searchFields: ["name", "staffId", "cname", "email", "aliasName"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "businessRegistrationNumber",
        component: TextInput,
        parent: "basicInfo-g2",
        props: {
          label: "BR No.",
          placeholder: "Bussiness Registration Number",
          mt: 0,
        },
      },

      {
        name: "address",
        component: Textarea,
        parent: "section-address",
        props: {
          label: "Business Address",
        },
      },

      {
        name: "contactPersons",
        component: ContactList,
        parent: "section-contact",
        props: {
          title: "Contact Persons",
          width: "200px",
        },
      },
      {
        name: "confirm",
        component: FormConfirmation,
        parent: "section-confirm",
        props: {},
      },
    ],
  },
};

export const editFormSchema = {
  title: "Client",
  titleField: "name",

  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
    size: "xl", //xs, sm, xl  ....100%
    // position:"right"
  },
  apiEntity,
  initialValues,

  validate: {
    code: (value) =>
      value?.length < 2 ? "Code must have at least 2 letters" : null,
    name: (value) =>
      value?.length < 4 ? "Name must have at least 4 letters" : null,
    id: (value) =>
      value?.length < 8 ? "ID must have at least 8 letters" : null,
  },

  layout: {
    containers: [
      {
        key: "main-g",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
        cols: [
          {
            key: "main-g1",
            props: {
              span: 3,
            },
          },
          {
            key: "main-g2",
            props: {
              span: 9,
            },
          },
        ],
      },

      {
        key: "section-action-list",
        parent: "main-g1",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Action List",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 0,
          mb: 20,
        },
      },

      {
        key: "main-tab",
        parent: "main-g2",
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Overview",
        },
        tab: [
          {
            key: "tab-overview",
            label: "Overview",
            icon: IconSettings,
          },

          {
            key: "tab-account",
            label: "Account",
            icon: IconSettings,
          },

          {
            key: "tab-files",
            label: "Files",
            icon: IconSettings,
          },
          {
            key: "tab-log",
            label: "Logs",
            icon: IconSettings,
          },
        ],
      },
      {
        key: "section-basic-info",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Basic Info",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 0,
          mb: 20,
        },
      },

      {
        key: "section-order-history",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Project History",
          titleOrder: 5,
          withBorder: true,

          p: 0,
        },
      },
      {
        key: "section-contact",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Contact Person",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-remarks",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Client Remarks",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: 0,
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-client-log",
        parent: "tab-log",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Client Log",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: 350,
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-files",
        parent: "tab-files",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Files",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-credit",
        parent: "tab-account",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Credit",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 0,
          mb: 20,
        },
      },

      {
        key: "section-invoice",
        parent: "tab-account",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Invoices",
          // titleOrder: 5,
          // description: "Please assign the corresponding consultant",

          mt: 0,
          mb: 20,
        },
      },
    ],

    fields: [
      {
        name: "display-basic-info",
        parent: "section-basic-info",
        component: ClientBasicInfo,
        props: {},
      },
      {
        name: "orders",
        parent: "section-order-history",
        component: ClientOrderList,
        props: {},
      },

      {
        name: "invoice",
        component: ClientInvoiceList,
        parent: "section-invoice",
        props: {},
      },

      {
        name: "contactPersons",
        component: ContactList,
        parent: "section-contact",
        props: {},
      },

      {
        name: "clientActionList",
        component: ClientActionList,
        parent: "section-action-list",
        props: {},
      },

      {
        name: "maximumCredit",
        component: ClientCreditSettting,
        parent: "section-credit",
        props: {},
      },

      {
        name: "files",
        component: FileList,
        parent: "section-files",
        props: {},
      },

      {
        name: "remarks",
        component: RemarkList,
        parent: "section-remarks",
        props: {
          scrollHeight: 100,
        },
      },
      {
        name: "log",
        component: ActivityLog,
        parent: "section-client-log",
        props: {},
      },

      {
        name: "confirm",
        component: FormConfirmation,
        parent: "section-confirm",
        props: {},
      },
    ],
  },
};

export const ClientFormSchema = {
  add: clientAddFormSchema,
  edit: editFormSchema,
};
