import React, { useState, useEffect } from "react";
import { Page } from "../layout/page";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Card, Group, Loader, LoadingOverlay, Text } from "@mantine/core";
import styles1 from "../sass/react-big-calendar/sass/styles.scss"; // Import the default styles
import { useServerApi } from "../hooks/useServerApi";

import { useFormNotification } from "../layout/page";
import JsonViewer from "../components/common/jsonViewer";

const localizer = momentLocalizer(moment);

const calendarStyle = {
  backgroundColor: "white",
  color: "black",
  height: 600,
  borderColor: "gray",
  borderWidth: 1,
  borderRadius: 1,
  padding: 10,
  margin: "0 auto",
  fontFamily: "Arial, sans-serif",
  fontSize: 11,
  fontWeight: "normal",
  textAlign: "center",
  btnColor: "red",
  // other styles
};

const Calendars = ({ title = "Calendar" }) => {
  const [api] = useServerApi();
  const [events, setEvents] = useState([]);
  const [notifyFormSuccess, notifyFormError] = useFormNotification();
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(
    moment().startOf("month").subtract(7, "d").toISOString()
  );
  const [endDate, setEndDate] = useState(
    moment().startOf("month").add(7, "d").toISOString()
  );

  const handleRangeChange = (range) => {
    // console.log(range);
    if (range.end) {
      setEndDate(moment(range.end).endOf("month").add(7, "d").toISOString());
      setStartDate(
        moment(range.start).startOf("month").subtract(7, "d").toISOString()
      );
    }
  };

  const fetchEvents = async () => {
    try {
      if (!startDate || !endDate) return;
      setLoading(true);
      const data = await api.Project.getEvents(startDate, endDate);
      // console.log("fetchEvents", data);
      if (!data || !data.events) throw "No events found";

      setLoading(false);
      data.events.forEach((event) => {
        event.start = new Date(event.start);
        event.end = new Date(event.end);
      });
      setEvents(data.events ?? []);
    } catch (error) {
      setLoading(false);
      // console.log(error);
      notifyFormError({
        title: "Error",
        message: "Error fetching events",
      });
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [startDate, endDate]);

  return (
    <Page title={title}>
      <div
        style={{
          width: "100%",
          maxWidth: "1200px",

          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#ffffff", // Match Google Calendar background
        }}
      >
        <iframe
          src={process.env.REACT_APP_CALENDAR_URL}
          style={{ border: 0, width: "100%", height: "1000px" }}
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </div>
    </Page>
  );
};

export default Calendars;
