import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash } from "@tabler/icons-react";

export const ClientTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    columns: [
      {
        field: "name",
        headerName: "Company name",
        sortable: true,
      },
      {
        field: "cname",
        headerName: "Chinese Name",
        sortable: true,
      },
      {
        field: "type",
        sortable: true,
      },
      {
        field: "phoneNumber",
        headerName: "Phone Number",
        sortable: true,
      },
      {
        field: "email",
        headerName: "Email",
        sortable: true,
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
          {
            name: "delete",
            icon: IconTrash,
            props: {
              //   color:"red"
            },
          },
        ],
      },
    ],
    searchableFields: [
      "name",
      "cname",
      "aliasName",
      "contactPersons.name",
      "email",
      "phoneNumber",
      "industry",
      "status",
    ],
  };
};
