import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import { DateInput } from "@mantine/dates";
import remoteSelect2 from "../../components/remoteSelect2";
import { TextInput, NumberInput } from "@mantine/core";
import FileList from "../../components/fileList";

const initialValues = {};

export const ClaimPaymentFormSchema = {
  name: "addSchema",
  title: "Claim Payment",
  description: "Please follow the step to create a New Claim",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "md", //xs, sm, xl  ....100%
  },

  apiEntity: "claimPayment",
  initialValues,

  layout: {
    containers: [],
    fields: [
      {
        name: "user",
        component: remoteSelect2,
        parent: "tab-overview",

        props: {
          label: "Staff",
          apiEntity: "user",
          required: true,
          valueField: "_id",
          labelField: "name",
          searchFields: ["name"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
    ],
  },
};
