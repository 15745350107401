import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Title,
  Text,
  Divider,
  Button,
  Box,
  Flex,
  Card,
} from "@mantine/core";

import ArrayTable from "./arrayTable";
import { DATA_CURRENCY } from "../data/options";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useFormatter } from "../hooks/useFomatter";
import { useForceUpdate } from "@mantine/hooks";
import { IconAlertTriangle } from "@tabler/icons-react";
import JsonViewer from "./common/jsonViewer";
import { useTranslation } from "react-i18next";
// import ReactJson from "react-json-view";

const calculateTotal = (charges, discountAmount, costTotal) => {
  if (_.isEmpty(charges)) return { sumTotal: 0, total: 0 };
  // console.log(charges, discountAmount);
  charges.forEach((c) => {
    c.price = (c.qty ?? 1) * c.unitPrice;
  });
  const sumTotal = +charges
    ?.reduce((a, c) => a + (c.qty ?? 1) * c.unitPrice, 0)
    .toFixed(2);
  const total = +(sumTotal - discountAmount).toFixed(2);

  const profit = +(total - costTotal).toFixed(2);
  const profitMargin = +((profit / costTotal) * 100).toFixed(2);
  return { sumTotal, total, profit, profitMargin };
};

const QuotationChargeItems = ({ form, name }) => {
  const chargeItems = _.get(form.values, "chargeItems");
  const costTotal = _.get(form.values, "costTotal");
  const discountAmount = _.get(form.values, "discountAmount") ?? 0;

  const [targetMargin, setTargetMargin] = useState(
    _.get(form.values, "targetMargin") ?? 0
  );

  const [total, setTotal] = useState(_.get(form.values, "total") ?? 0);
  const [sumTotal, setSumTotal] = useState(_.get(form.values, "sumTotal") ?? 0);
  const [profit, setProfit] = useState(_.get(form.values, "profit") ?? 0);
  const [profitMargin, setProfitMargin] = useState(
    _.get(form.values, "profitMargin") ?? 0
  );
  const [formatter] = useFormatter();

  const forceUpdate = useForceUpdate();

  const rowInitValue = {
    item: "",
    currency: "HKD",
    qty: 1,
    unitPrice: 0,
    price: 0,
  };

  const fields = [
    {
      header: "Item",
      headerWidth: "50%",
      fieldName: "item",
      component: Textarea,
      props: {
        autosize: true,
        minRows: 1,
      },
    },

    {
      header: "Qty",
      headerWidth: "10%",
      fieldName: "qty",
      component: NumberInput,
      props: {},
    },
    // {
    //   header: "Currency",
    //   headerWidth: "10%",
    //   fieldName: "currency",
    //   component: Select,
    //   props: {
    //     data: DATA_CURRENCY,
    //     defaultValue: "HKD",
    //     searchable: true,
    //   },
    // },
    {
      header: "Unit Price",
      headerWidth: "15%",
      fieldName: "unitPrice",
      component: NumberInput,
      props: {
        thousandSeparator: ",",
        precision: 2,
      },
    },
    {
      header: "Price",
      headerWidth: "15%",
      fieldName: "price",
      component: NumberInput,
      props: {
        thousandSeparator: ",",
        precision: 2,
      },
    },
  ];

  const onChargeChange = (charges) => {
    const { sumTotal, total, profit, profitMargin } = calculateTotal(
      charges,
      discountAmount,
      costTotal
    );

    setTotal(total);
    setSumTotal(sumTotal);
    setProfit(profit);
    setProfitMargin(profitMargin);
    // form.setFieldValue("sumTotal", sumTotal);
    // form.setFieldValue("total", total);
    // form.setFieldValue("profit", profit);
  };

  const copyCostItemsAndSetMargin = () => {
    const costItems = _.get(form.values, "costItems");
    const newChargeItems = costItems.map((c) => {
      const marginMultiplier = 1 + targetMargin / 100;
      const updatedUnitPrice = +(c.unitPrice * marginMultiplier).toFixed(2);
      const updatedPrice = +(c.price * marginMultiplier).toFixed(2);

      return {
        ...c,
        unitPrice: updatedUnitPrice,
        price: updatedPrice,
      };
    });
    form.setFieldValue("chargeItems", newChargeItems);
  };

  useEffect(() => {
    form.setFieldValue("targetMargin", targetMargin);
  }, [targetMargin]);

  useEffect(() => {
    onChargeChange(chargeItems);
  }, [chargeItems]);

  useEffect(() => {
    form.setFieldValue("total", total);
  }, [total]);

  useEffect(() => {
    form.setFieldValue("sumTotal", sumTotal);
  }, [sumTotal]);

  useEffect(() => {
    form.setFieldValue("profit", profit);
  }, [profit]);

  useEffect(() => {
    form.setFieldValue("profitMargin", profitMargin);
  }, [profitMargin]);

  const { t } = useTranslation();
  return (
    <>
      {/* <ReactJson src={form.values} /> */}

      <Group align="end" justify="right">
        {/* <Text>{`Approximate Cost Total : ${formatter.currency(
          costTotal
        )}`}</Text> */}
        <NumberInput
          precision={2}
          label={t("Target Margin (%)")}
          placeholder="30"
          value={targetMargin}
          onChange={setTargetMargin}
          thousandSeparator=","
          size="xs"
        />
        <Button size="xs" onClick={() => copyCostItemsAndSetMargin()}>
          Apply
        </Button>
      </Group>
      {total > 0 && form.values.costTotal === 0 && (
        <Group mb="xl">
          <IconAlertTriangle size="14" color="red" />
          <Text color={"red"} size="md">
            Cost Items is 0
          </Text>
        </Group>
      )}
      <Group align={"end"} mb="xl"></Group>
      <ArrayTable
        form={form}
        name={name}
        rowInitValue={rowInitValue}
        fields={fields}
        onDataChange={onChargeChange}
      />
      <Divider my="xl" />
      <Group justify="space-between">
        <Card w={300} inheritPadding px={"xl"}>
          <Card.Section withBorder inheritPadding py="xs">
            <Group justify="space-between" mb={5}>
              <Text size="xs" c={"dimmed"}>
                {t("Total Receive")}
              </Text>
              <Text>{formatter.currency(total)}</Text>
            </Group>
            <Group justify="space-between" mb={5}>
              <Text size="xs" c={"dimmed"}>
                {t("Cost Total")}
              </Text>
              <Text>{formatter.currency(costTotal)}</Text>
            </Group>
            <Divider my="xs" />
            <Group justify="space-between" mb={5}>
              <Text size="xs" c={"dimmed"}>
                {t("Profit")}
              </Text>
              <Text fw={500} c={profit > 0 ? "green" : "red"}>
                {formatter.currency(profit)}
              </Text>
            </Group>
            <Group justify="space-between" mb={5}>
              <Text size="xs" c={"dimmed"}>
                {t("Profit Margin")}
              </Text>
              <Text>{formatter.percentage(profitMargin)}</Text>
            </Group>
          </Card.Section>
        </Card>
        <Box w={300}>
          <Group justify="space-between" mb="xs">
            <Text size="xs" c={"dimmed"}>
              {t("Sum Total")}
            </Text>
            <Title order={3}>{formatter.currency(sumTotal)}</Title>
          </Group>
          <Group justify="space-between" mb={"xs"}>
            <Text size="xs" c={"dimmed"}>
              {t("Discount")}
            </Text>
            <NumberInput
              precision={2}
              thousandSeparator=","
              placeholder="10"
              value={discountAmount}
              onChange={(v) => form.setFieldValue("discountAmount", v)}
            />
          </Group>
          <Divider variant="dotted" my={"lg"} />
          <Group justify="space-between" mb="xs">
            <Text size="xs" c={"dimmed"}>
              {t("Total")}
            </Text>
            <Title order={3}>{formatter.currency(total)}</Title>
          </Group>
        </Box>
      </Group>
    </>
  );
};

export default QuotationChargeItems;
