import React, { useState, useEffect } from "react";
import { Page } from "../layout/page";
import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../components/pageList";
import { useFormRender } from "../hooks/useFormRender";
import { useServerApi } from "../hooks/useServerApi";
import _ from "lodash";
import PageListHeader from "../components/pageListHeader";
import { TextInput } from "@mantine/core";
import ImagePicker from "../components/imagePicker";
import OrderList from "../components/orderList";
import JsonViewer from "../components/common/jsonViewer";
import GoogleMemberRow from "../components/GoogleMemberRow";
import ProductionItemListRow from "../components/productionItemListRow";

const formSchema = {
  title: "Google Calendar Setting",
  apiEntity: "setting",
  display: {
    mode: ENUM_FORM_DISPLAY.INSIDE,
    size: "xl", //xs, sm, xl  ....100%
    // position:"right"
  },
  layout: {
    containers: [
      {
        key: "section1",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Basic Info",
          description: "Please fill in details",
        },
      },
    ],
    fields: [
      {
        name: "googleCalendar.summary",
        component: TextInput,
        parent: "section1",
        props: {
          label: "Calendar Name",
        },
      },
      {
        name: "googleCalendar.calendarId",
        component: TextInput,
        parent: "section1",
        props: {
          label: "Calendar ID",
        },
      },
      {
        name: "googleCalendar.members",
        component: OrderList,
        parent: "section1",
        props: {
          onEnterSave: false,
          defaultRowValue: {},
          label: "Members",
          row: {
            component: GoogleMemberRow,
          },
        },
      },
    ],
  },
};
const GoogleCalendarSetting = () => {
  // const [rates, setRates] = useState([]);
  const [api] = useServerApi();
  const [renderForm, formAction] = useFormRender(formSchema);

  const fetchSetting = async () => {
    try {
      const result = await api.search({
        apiEntity: "setting",
        pageSize: 1,
      });

      if (!result || _.isEmpty(result.docs)) throw "No Settings";

      const setting = result.docs[0];
      if (!setting.googleCalendar) {
        setting.googleCalendar = {
          summary: "",
          calendarId: "",
          members: [],
        };
      }
      formAction.open({
        mode: "edit",
        id: setting._id,
        data: setting,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSetting();
  }, []);

  const handleSave = () => {
    formAction.save();
  };

  const handleClose = () => {
    window.history.back();
  };

  return (
    <Page>
      {/* <JsonViewer src={form.values} /> */}
      <PageListHeader
        title={"Google Calendar"}
        actionButtons={[
          { label: "Save", onClick: () => handleSave() },
          {
            label: "Close",
            onClick: () => window.history.back(),
            variant: "default",
          },
        ]}
      />
      {renderForm()}
    </Page>
  );
};

export default GoogleCalendarSetting;
