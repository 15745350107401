import React, { useRef, useState } from "react";
import FormSection from "./formSection";
import OrderList from "./orderList";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { Card, TextInput, Textarea, Space } from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import ProductionSectionItemGroups from "./productionSectionItemGroups";

const ProductionSectionInfo = ({ section, form, sectionIndex }) => {
  const forceUpdate = useForceUpdate();
  const defaultItemGroupValue = {
    name: `Group ${section.itemGroups.length + 1}`,
    dimensions: {
      width: 0,
      depth: 0,
      height: 0,
    },
    itemList: [],
    floorPlan: [],
  };

  const handleAddGroup = () => {
    section.itemGroups.push(defaultItemGroupValue);
    forceUpdate();
  };

  const handleRemoveGroup = (index) => {
    section.itemGroups.splice(index, 1);
    forceUpdate();
  };
  const { t } = useTranslation();

  return (
    <>
      <Card mb="xl">
        {/* <Card ml={0}>{section.name}</Card> */}
        <TextInput
          label={t("Section Name")}
          mb="xs"
          size="xs"
          {...form.getInputProps(`productionSections.${sectionIndex}.name`)}
        />
        <Textarea
          value={section.description}
          label={t("Description")}
          mb="xs"
          size="xs"
          {...form.getInputProps(
            `productionSections.${sectionIndex}.description`
          )}
        />
        <Space h={50} />
        {section.itemGroups.length > 0 && (
          <ProductionSectionItemGroups
            form={form}
            sectionIndex={sectionIndex}
            itemGroups={section.itemGroups}
            handleRemoveGroup={handleRemoveGroup}
            handleAddGroup={handleAddGroup}
          />
        )}
      </Card>
    </>
  );
};

export default ProductionSectionInfo;
