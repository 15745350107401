import React from "react";
import { FormSectionCard } from "../layout/page";
import {
  Title,
  Group,
  Card,
  Divider,
  Text,
  Anchor,
  InputWrapper,
} from "@mantine/core";
import { useCellRender } from "../hooks/useCellRender";
import InfoRowList from "./infoRowList";
import InfoGridList from "./infoGridList";
import moment from "moment";
import { IconDotsVertical } from "@tabler/icons-react";

function ProjectSummaryCard({ form, shadow = "md" }) {
  const [cellRender] = useCellRender();

  const project = form.values;

  const fields = [
    {
      title: "Code",
      value: project?.code,
    },
    {
      title: "Client",
      //value: project?.client?.name,

      render: () => (
        <Anchor
          href={`/client?id=${project?.client?._id}`}
          target="_blank"
          underline="hover"
        >
          <Text size="xs" lineClamp={1}>
            {project?.client?.name}
          </Text>
        </Anchor>
      ),
    },
    { title: "Status", value: project?.status },
    // { title: "Date", value: moment(project?.duration.start).format("ll") },
    // { title: "Time", value: moment(project?.duration.start).format("LT") },
  ];
  return (
    <Card shadow={shadow}>
      <Card.Section withBorder inheritPadding py="xs">
        <Group justify="space-between">
          <Text size="sm" fw={500}>
            {project.name}
          </Text>
          {/* <IconDotsVertical size={12} /> */}
        </Group>
      </Card.Section>

      <Card.Section inheritPadding py="md">
        <InfoRowList fields={fields} values={project} cols={2} />
      </Card.Section>
    </Card>
  );
}

export default ProjectSummaryCard;
