import React, { useState } from "react";
import { Button, Checkbox, Group, List, Title } from "@mantine/core";
import { useServerApi } from "../hooks/useServerApi";

import { useDisclosure } from "@mantine/hooks";

import { showNotification } from "@mantine/notifications";
import { IconCircleCheck, IconExclamationMark } from "@tabler/icons-react";
import ConfirmationModal from "./confirmationModal";
import { useTranslation } from "react-i18next";

const QuotationConfirmButton = ({ mainForm }) => {
  const [api] = useServerApi();
  const { _id: id, code } = mainForm.values;
  const [opened, { open, close }] = useDisclosure(false);
  const { t } = useTranslation();
  const confirm = async () => {
    try {
      const result = await api.Quotation.confirm(id);
      close();

      if (result.success) {
        mainForm.setFieldValue("status", "CONFIRM");
        showNotification({
          title: `Quotation Confirmed`,
          color: "Green",
          icon: <IconCircleCheck size={18} />,
          message: "This quotation is confirmed.",
        });
      } else {
        throw result.error;
      }
    } catch (error) {
      // console.log(error);
      showNotification({
        title: `Confirm Error`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: error?.msg ?? error,
      });
    }
  };

  return (
    <>
      <ConfirmationModal
        opened={opened}
        onClose={close}
        withCloseButton={false}
        size="lg"
        title={`Are you ready to confirm the quotation ${code}?`}
        confirmList={[
          "Please make sure the quotation details are correct and agreed by client.",
          "Please make sure the quotation is approved by your supervisor.",
          "Once confirmed: ",
          " - Related items will be booked.",
          " - Please create invoices for the order.",
        ]}
        onConfirm={confirm}
      />

      <Button fullWidth color={"green"} onClick={open} variant="default">
        {t("Confirm")}
      </Button>
    </>
  );
};

export default QuotationConfirmButton;
