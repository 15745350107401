import React from "react";
import { FormSectionCard } from "../layout/page";
import {
  Title,
  Group,
  Card,
  Divider,
  Text,
  List,
  ThemeIcon,
} from "@mantine/core";
import { useCellRender } from "../hooks/useCellRender";

import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { IconAddressBook, IconMap, IconPin } from "@tabler/icons-react";
import { IconPinned } from "@tabler/icons-react";

/**
 *
 * @param {viewMode} place view directions streetview search
 * @param {maptype} roadmap or satellite
 * @returns
 *
 */
function LocationCard({
  form,
  shadow = "md",
  viewMode = "view",
  maptype = "roadmap",
  height = 300,
}) {
  const [cellRender] = useCellRender();
  const project = form.values;

  const center = project.location?.center ?? {
    lat: 22.312401, // Latitude of the location
    lng: 114.2226471, // Longitude of the location
  };

  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  return (
    <Card shadow={shadow}>
      <Card.Section>
        <iframe
          width="100%"
          height={height}
          frameborder="0"
          src={`https://www.google.com/maps/embed/v1/place?q=${center.lat},${center.lng}&key=${API_KEY}`}
        ></iframe>
      </Card.Section>
      <Card.Section inheritPadding py="xs" withBorder>
        <List>
          <List.Item
            icon={
              <ThemeIcon color="blue" size={24} radius="xl">
                <IconPinned size="15" />
              </ThemeIcon>
            }
          >
            <Text size="sm" lineClamp={1}>
              {project.location?.label ?? "Address not given"}{" "}
            </Text>
          </List.Item>

          <List.Item
            icon={
              <ThemeIcon color="blue" size={24} radius="xl">
                <IconMap size="15" />
              </ThemeIcon>
            }
          >
            <Text size="sm">{project.address ?? "Address not given"} </Text>
          </List.Item>
        </List>
        {/* <Group gap="xs">
          <IconPinned size="15" />
          <Text size="sm">
            {project.location?.label ?? "Address not given"}{" "}
          </Text>
        </Group>
        <Group gap="xs">
          <IconMap size="15" />
          <Text size="sm">{project.address ?? "Address not given"} </Text>
        </Group> */}
      </Card.Section>
    </Card>
  );
}

export default LocationCard;
