import { createTheme, MantineProvider, Button, Paper } from "@mantine/core";
import { AuthProvider, RequireAuth } from "react-auth-kit";
import { Notifications } from "@mantine/notifications";

import { routes } from "./data/routes";
import { APIProvider, Map } from "@vis.gl/react-google-maps";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";

import themeObject from "./themeObject";
import { LanguageProvider } from "./context/language";

const theme = createTheme(themeObject);
const authName = `${process.env.REACT_APP_AUTH_NAME}`;
const languageFromLocalStorage = localStorage.getItem("ONE_language") || "en";

const App = () => {
  const privateRoutes = routes.filter((l) => l.private);
  const publicRoutes = routes.filter((l) => !l.private);
  const defaultHomeRoute = routes.find((l) => l.isDefaultHome);
  const loginRoute = routes.find((l) => l.isLoginRoute);

  return (
    <MantineProvider theme={theme} defaultColorScheme="light">
      <LanguageProvider initialLanguage={languageFromLocalStorage}>
        <AuthProvider authType={"localstorage"} authName={authName}>
          <Notifications />
          <BrowserRouter>
            <Routes>
              {
                //Public Routes
                publicRoutes.map((r, index) => (
                  <Route
                    key={"route-public" + index}
                    index
                    path={r.link}
                    element={r.element}
                  />
                ))
              }

              <Route
                path="/"
                key="route-home"
                element={
                  <RequireAuth loginPath={loginRoute.link}>
                    <Layout />
                  </RequireAuth>
                }
              >
                {
                  //Default Home Page
                  <Route
                    key="route-default"
                    index
                    path="/"
                    element={defaultHomeRoute?.element}
                  />
                }
                {
                  //Private Route
                  privateRoutes.map((r, index) => (
                    <Route
                      key={"route-private" + index}
                      index
                      path={r.link}
                      element={r.element}
                    />
                  ))
                }
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </LanguageProvider>
    </MantineProvider>
  );
};

export default App;
