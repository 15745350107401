import React, { useRef } from "react";
import { Text, TextInput, Group, Box } from "@mantine/core";
import { TimeInput, DateTimePicker } from "@mantine/dates";
import { useForceUpdate } from "@mantine/hooks";
import RemoteSelect2 from "./remoteSelect2";
import { DATA_ITEM_ASSIGN_METHOD } from "../data/options";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import moment from "moment";

export const RundownRow = ({ item, index, form, name }) => {
  const forceUpdate = useForceUpdate();
  const remoteRef = useRef(null);
  useDeepCompareEffect(() => {
    // console.log("item changed", item);
    forceUpdate();
    remoteRef.current?.refresh();
  }, [item]);
  return (
    <Box mb="xl">
      <Group wrap="nowrap" mb="sm">
        <Text>{index + 1}. </Text>
        <DateTimePicker
          placeholder="Input placeholder"
          value={moment(item.content["start"]).toDate()}
          onChange={(value) => {
            form.setFieldValue(`${name}.${index}.start`, value);
          }}
        />
        -
        <DateTimePicker
          placeholder="Input placeholder"
          value={moment(item.content["end"]).toDate()}
          onChange={(value) => {
            form.setFieldValue(`${name}.${index}.end`, value);
          }}
        />
      </Group>
      <Group wrap="nowrap" grow mb="sm" ml="25">
        <TextInput
          value={item.content["visual"]}
          placeholder="Visual"
          {...form.getInputProps(`${name}.${index}.visual`)}
        />
        <TextInput
          value={item.content["audio"]}
          placeholder="Auido"
          {...form.getInputProps(`${name}.${index}.audio`)}
        />
      </Group>
      <Group ml="25" grow>
        <TextInput
          value={item.content["remark"]}
          placeholder="Remark"
          {...form.getInputProps(`${name}.${index}.remark`)}
        />
      </Group>
    </Box>
  );
};
