import { Page } from "../layout/page";
import {
  Accordion,
  Title,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import React, { useState, useEffect } from "react";

import ReportUser from "../components/report/reportUser";
import ReportProject from "../components/report/reportProject";
import ReportCustomer from "../components/report/reportCustomer";
import ReportClaim from "../components/report/reportClaim";
import reportClaimSettlement from "../components/report/reportClaimSettlement";
import ReportItemBooking from "../components/report/reportItemBooking";
import ReportItemInventory from "../components/report/reportItemInventory";
import ReportPendingInvoice from "../components/report/reportPendingInvoice";
import ReportInvoice from "../components/report/reportInvoice";

import { useTranslation } from "react-i18next";

const Report = ({}) => {
  const { t } = useTranslation();
  const reports = [
    {
      title: "Project Report",
      reportComponent: ReportProject,
      description: "Please select the period of records",
    },
    {
      title: "Customer Report",
      reportComponent: ReportCustomer,
      description: "Please click the below button to download the report.",
    },
    {
      title: "Pending Invoice Report",
      reportComponent: ReportPendingInvoice,
      description: "Please click the below button to download the report.",
    },
    {
      title: "Invoice Report",
      reportComponent: ReportInvoice,
      description: "Please click the below button to download the report.",
    },
    {
      title: "Claim Report",
      reportComponent: ReportClaim,
      description: "Please select the period of records",
    },
    {
      title: "Claim Settlement Report",
      reportComponent: reportClaimSettlement,
      description: "Please select the period of records",
    },

    {
      title: "Item Booking Report",
      reportComponent: ReportItemBooking,
      description: "Please select the period of records",
    },
    {
      title: "Item Inventory Report",
      reportComponent: ReportItemInventory,
      description: "Please select the period of records",
    },
    {
      title: "User Report",
      reportComponent: ReportUser,
      description: "Please click the below button to download the report.",
    },
  ];
  const colorScheme = useComputedColorScheme();
  const theme = useMantineTheme();

  const getColor = () =>
    colorScheme === "dark" ? theme.colors.dark[8] : "white";

  return (
    <Page>
      <Title> {t("Report")} </Title>

      <Accordion
        variant="separated"
        radius="xs"
        defaultValue="customization"
        mt={"xl"}
      >
        {reports.map((r) => (
          <Accordion.Item value={r.title}>
            <Accordion.Control bg={getColor()}>
              <Title order={5}>{t(r.title)}</Title>
            </Accordion.Control>
            <Accordion.Panel bg={getColor()}>
              <r.reportComponent description={t(r.description)} />
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Page>
  );
};

export default Report;
