import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Title,
  Text,
  Divider,
  Button,
  Menu,
  Popover,
  Modal,
  ScrollArea,
} from "@mantine/core";
import ArrayTable from "./arrayTable";
import { DATA_CURRENCY } from "../data/options";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useFormatter } from "../hooks/useFomatter";
import { useDisclosure, useForceUpdate } from "@mantine/hooks";
import {
  IconDeviceFloppy,
  IconDownload,
  IconSeparatorVertical,
} from "@tabler/icons-react";
import { IconDotsVertical } from "@tabler/icons-react";
import { useServerApi } from "../hooks/useServerApi";
import TemplateSaveLoadButtons from "./templateSaveLoadButtons";
import JsonViewer from "./common/jsonViewer";
import { useTranslation } from "react-i18next";

const calculateTotal = (charges) => {
  if (_.isEmpty(charges)) return 0;
  charges.forEach((c) => {
    c.price = (c.qty ?? 1) * c.unitPrice;
  });
  const total = charges.reduce((a, c) => a + (c.qty ?? 1) * c.unitPrice, 0);
  return +total.toFixed(2);
};

const QuotationCostItems = ({ form, name }) => {
  const costItems = _.get(form.values, "costItems");
  const [total, setTotal] = useState(0);
  const [formatter] = useFormatter();
  const [api] = useServerApi();

  const rowInitValue = {
    item: "",
    currency: "HKD",
    qty: 1,
    unitPrice: 0,
    price: 0,
  };

  const fields = [
    {
      header: "Item",
      headerWidth: "50%",
      fieldName: "item",
      component: Textarea,
      props: {
        autosize: true,
        minRows: 1,
      },
    },

    {
      header: "Qty",
      headerWidth: "10%",
      fieldName: "qty",
      component: NumberInput,
      props: {},
    },

    {
      header: "Unit Price",
      headerWidth: "20%",
      fieldName: "unitPrice",
      component: NumberInput,
      props: {
        thousandSeparator: ",",
        precision: 2,
      },
    },
    {
      header: "Price",
      headerWidth: "20%",
      fieldName: "price",
      component: NumberInput,
      props: {
        thousandSeparator: ",",
        precision: 2,
      },
    },
  ];

  const onChargeChange = (charges) => {
    const total = calculateTotal(charges);
    setTotal(total);
  };

  useEffect(() => {
    onChargeChange(costItems);
  }, [costItems]);

  useEffect(() => {
    form.setFieldValue("costTotal", total);
  }, [total]);

  const loadFromTemplate = (template) => {
    // console.log("loadFromTemplate", template?.content);
    const cosItems = _.get(form.values, "costItems");
    form.setFieldValue("costItems", [...cosItems, ...template?.content]);
  };

  const { t } = useTranslation();

  //Load Cost Item from Production
  const loadProductionByGroup = async () => {
    try {
      const projectId = _.isObject(form.values?.project)
        ? form.values?.project?._id
        : form.values?.project;
      if (!projectId) return;
      const result = await api.Project.getProductionCostItemsByGroup(projectId);
      // console.log(result);
      if (!result || _.isEmpty(result.costItems)) return;

      const costItems = _.get(form.values, "costItems") ?? [];
      form.setFieldValue("costItems", [...costItems, ...result?.costItems]);
    } catch (e) {
      console.log(e);
    }
  };

  const loadProduction = async () => {
    try {
      const projectId = _.isObject(form.values?.project)
        ? form.values?.project?._id
        : form.values?.project;
      if (!projectId) return;
      const result = await api.Project.getProductionCostItems(projectId);
      // console.log("loadProductionByItem", result);
      if (!result || _.isEmpty(result.costItems)) return;

      const costItems = _.get(form.values, "costItems") ?? [];
      form.setFieldValue("costItems", [...costItems, ...result?.costItems]);
    } catch (e) {
      console.log(e);
    }
  };

  const clearItems = () => {
    const ans = window.confirm("Are you sure to clear all items?");
    if (!ans) return;
    // console.log("clearItems");
    costItems.length = 0;
    form.setFieldValue("costItems", []);
  };

  return (
    <>
      <JsonViewer src={form.values} style={{ background: "white" }} />

      <Group justify="right" mb="xl" gap={5}>
        <TemplateSaveLoadButtons
          templateCategory="costItem"
          onLoadTemplate={loadFromTemplate}
          currentContent={costItems}
        />
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button variant="default" size="xs">
              {t("Load Production")}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>By</Menu.Label>
            <Menu.Item onClick={loadProductionByGroup}>
              {t("Section Groups")}
            </Menu.Item>
            <Menu.Item onClick={loadProduction}>
              {t("Individual Items")}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <Button variant="default" size="xs" onClick={clearItems}>
          {t("Clear All")}
        </Button>

        {/* <Button variant="default" size="xs" onClick={loadProductionByGroup}>
          {t("Load Production By Group")}
        </Button>
        <Button variant="default" size="xs" onClick={loadProduction}>
          {t("Load Production By Item")}
        </Button> */}
      </Group>
      <ArrayTable
        form={form}
        name={name}
        rowInitValue={rowInitValue}
        fields={fields}
        onDataChange={onChargeChange}
      />
      <Divider my="xl" />
      <Group justify="right">
        <div>
          <Title order={3}>{formatter.currency(total)}</Title>
          <Text size="xs" c={"dimmed"}>
            {t("Total Cost")}
          </Text>
        </div>
      </Group>
    </>
  );
};

export default QuotationCostItems;
