import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";

import Terms from "../../components/terms";
import { DateInput } from "@mantine/dates";
import remoteSelect2 from "../../components/remoteSelect2";
import { InputWrapper, TextInput, Textarea, Text, Box } from "@mantine/core";
import moment from "moment";
import QuotationDateInput from "../../components/quotationDateInput";

export const ProjectQuotationSchema = {
  title: "Quotation",
  isMainForm: false,
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
    size: "md", //xs, sm, xl  ....100%
    position: "right",
  },
  apiEntity: "quotation",

  initialValues: {
    terms: [{ title: "", detail: "" }],
  },
  closeAfterSave: true,
  layout: {
    fields: [
      {
        name: "quoDate",
        component: QuotationDateInput,
        type: "dateInput",
        props: {
          label: "Quo Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
          defaultValue: new Date(),
          dateDiff: 14,
        },
      },

      {
        name: "validDate",
        component: DateInput,
        type: "dateInput",
        props: {
          label: "Valid Date",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
          defaultValue: moment().add(14, "days").toDate(),
        },
      },
      {
        name: "project",
        component: remoteSelect2,
        props: {
          label: "Project",
          apiEntity: "project",
          valueField: "_id",
          labelField: "name",
          searchFields: ["name", "code"],
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },
      {
        name: "customer",
        component: remoteSelect2,
        props: {
          label: "Customer",
          apiEntity: "client",
          valueField: "_id",
          labelField: "name",
          searchFields: ["name", "cname", "code"],
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },
      {
        name: "client",
        component: remoteSelect2,
        props: {
          label: "Client",
          apiEntity: "client",
          valueField: "_id",
          labelField: "name",
          searchFields: ["name", "cname", "code"],
          sort: {
            by: "name",
            order: "desc",
          },
        },
      },
    ],
  },
};
