import React from "react";
import {
  Container,
  Title,
  Group,
  Button,
  Text,
  Paper,
  Box,
  Space,
  Grid,
} from "@mantine/core";
import { Breadcrumbs, Anchor } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";

import { IconExclamationMark, IconCircleCheck } from "@tabler/icons-react";

const items = [
  { title: "Home", href: "/" },
  { title: "Setting", href: "/setting/" },
  { title: "Reset Password", href: "/resetPassword" },
].map((item, index) => (
  <Anchor href={item.href} key={index}>
    {item.title}
  </Anchor>
));

export const Page = ({
  title,
  size = "xl",
  children,
  actions,
  pt = "xl",
  px = "xl",
  shadow = 0,
  titleMargin = "xl",
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Container size={size} {...props} pt={pt} px={px}>
      {/* <Breadcrumbs separator=">">{items}</Breadcrumbs> */}
      <Group position={"apart"}>
        {title && (
          <Title align="left" mb={titleMargin}>
            {t(title)}
          </Title>
        )}
        <Group>{actions}</Group>
      </Group>
      {/* <Space h="xl" /> */}

      {shadow == 0 && children}
      {shadow != 0 && <Paper shadow={shadow}>{children}</Paper>}
    </Container>
  );
};

export const FormPage = ({ children, title, actions }) => {
  return (
    <form>
      <Page title={title} actions={actions()}>
        {children}
      </Page>
    </form>
  );
};

export const FormSectionCard = ({
  children,
  title,
  withPaper = true,
  paperShadow = "md",
  mt = 0,
  mb = "xl",
  h,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Box
      mt={mt}
      mb={mb}
      // {...props}
      style={{
        "@media print": {
          display: "block",
          "page-break-before": "always",
        },
      }}
    >
      <Text transform="uppercase" size="xs" mb="xs">
        {t(title)}
      </Text>
      {!withPaper && children}
      {withPaper && (
        <Paper shadow={paperShadow} p="xl" radius={0} h={h}>
          {children}
        </Paper>
      )}
    </Box>
  );
};

export const useFormNotification = () => {
  const notifyFormSuccess = ({
    title = "Saved",
    message = "Data submitted successfully.",
  }) => {
    return showNotification({
      title,
      // color: "red",
      icon: <IconCircleCheck size={18} />,
      message,
    });
  };

  const notifyFormError = ({
    title = "Error",
    message = "Form submit error",
  }) => {
    return showNotification({
      title,
      color: "red",
      icon: <IconExclamationMark size={18} />,
      message,
    });
  };

  return [notifyFormSuccess, notifyFormError];
};
