import { SimpleGrid, Text, Title, Group, Badge } from "@mantine/core";
import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const FieldDisplay = ({
  title,
  titleTextSize = "sm",
  dataTextSize = "sm",
  value,
  render,
  dataFontWeight,
  lineClamp = 1,
}) => {
  const { t } = useTranslation();
  return (
    <Group justify="space-between" wrap="nowrap" gap={"xl"}>
      <Text size={titleTextSize}>{t(title)}</Text>
      {_.isFunction(render) && render()}
      {value && (
        <Text size={dataTextSize} weight={dataFontWeight} lineClamp={lineClamp}>
          {value}
        </Text>
      )}
      {_.isNull(value) && !render && <Text size={"xs"}>{"-"}</Text>}
    </Group>
  );
};

const InfoRowList = ({
  fields,
  cols = 3,
  spacing = "md",
  titleTextSize = "xs",
  dataTextSize = "sm",
  values,
  dataFontWeight = 600,
  ...props
}) => {
  return (
    !_.isEmpty(fields) &&
    values &&
    fields?.map(({ title, value, render, array }, index) => (
      <FieldDisplay
        key={index}
        title={title}
        value={value}
        render={render}
        dataFontWeight={dataFontWeight}
        titleTextSize={titleTextSize}
        dataTextSize={dataTextSize}
      />
    ))
  );
};

export default InfoRowList;
