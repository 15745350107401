import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil } from "@tabler/icons-react";

export const ClaimSettlementSchema = (cellRender) => {
  return {
    showActionColumn: true,
    columns: [
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },

      {
        field: "date",
        headerName: "Paid Date",
        cellRender: (col, data) => moment(data?.date).format("LL"),
        sortable: true,
      },

      {
        field: "amount",
        headerName: "Total Amount",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.amount),
      },

      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
      },
    ],
    searchableFields: ["code", "status"],
  };
};
