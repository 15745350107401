import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { AddActionButton, PageList } from "./pageList";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";
import JsonViewer from "./common/jsonViewer";
import DataTable from "./dataTable";
import { useServerApi } from "../hooks/useServerApi";
import AddNewButton from "./addNewButton";
import { useFormRender } from "../hooks/useFormRender";
import RelatedDataTable from "./relatedDataTable";

const ClientInvoiceList = ({ form: mainForm, ...props }) => {
  const [api] = useServerApi();
  const [data, setData] = useState([]);
  const [getFormSchema] = useFormSchema();
  const [getTableSchema] = useTableSchema();

  const apiEntity = "invoice";
  //Form

  const customerId = _.get(mainForm.values, "_id");
  const formSchema = {
    ...getFormSchema("ProjectInvoice"),
    initialValues: {
      customer: customerId,
      terms: [{ title: "", detail: "" }],
    },
  };

  //Table
  const tableSchema = getTableSchema("ProjectInvoiceList", {
    customerId,
  });

  const fetchData = async () => {
    try {
      if (!customerId) return;
      const result = await api.search({
        apiEntity: "invoice",
        searchQuery: { customer: customerId },
      });
      if (result.docs) setData(result.docs);
    } catch (error) {
      console.log(error);
    }
  };

  //after succes add
  const onSubmitSuccess = () => {
    fetchData();
  };

  //After delete
  const postDelete = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [customerId]);

  const copyToNew = async (data) => {
    const newData = { ...data };
    delete newData._id;
    delete newData.createdAt;
    if (newData.code) {
      newData.code += "(copy)";
    }
    let result = await api.add({ apiEntity, values: newData });
    return result?.data;
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    onSubmitSuccess
  );

  return (
    <>
      <RelatedDataTable
        tableSchema={tableSchema}
        formSchema={formSchema}
        mainForm={mainForm}
        apiEntity={"invoice"}
        fieldIn="_id"
        fieldOut="customer"
      />
    </>
  );
};

export default ClientInvoiceList;
