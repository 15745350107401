import React, { useRef } from "react";
import { Flex, Group, NumberInput, TextInput } from "@mantine/core";

import { IconExternalLink } from "@tabler/icons-react";
import JsonViewer from "./common/jsonViewer";

const ProductionItemResourceLinkRow = ({ item, index, form, name }) => {
  return (
    <>
      {/* name:{name} , index:{index} */}
      {/* <JsonViewer src={form} /> */}
      <Group index={index} wrap="noWrap">
        <Group grow miw={"95%"}>
          <TextInput
            // label="Name"
            placeholder="Name ( e.g Lighting effect / Sound effect )"
            {...form.getInputProps(`${name}.${index}.name`)}
          />

          <TextInput
            placeholder="URL ( e.g https://www.youtube.com/watch?v=123456 )"
            {...form.getInputProps(`${name}.${index}.url`)}
          />
        </Group>
        <IconExternalLink
          size="18"
          cursor={"pointer"}
          onClick={() => window.open(item.content["url"])}
        />
      </Group>
    </>
  );
};

export default ProductionItemResourceLinkRow;
