import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import {
  IconTrash,
  IconPencil,
  IconCheck,
  IconCircleCheck,
  IconAlertOctagon,
} from "@tabler/icons-react";
import _ from "lodash";
import Chips from "./chips";

import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import LocalPageList from "./localPageList";
import FileList from "./fileList";
import ActionCellRender from "./actionCellRender";

import { useServerApi } from "../hooks/useServerApi";

import { TextInput, Select, Text } from "@mantine/core";
import { DATA_COUNTRY, DATA_ADDRESS_TYPE } from "../data/options";
import { useCellRender } from "../hooks/useCellRender";

const ContactList = ({
  form: mainForm,
  name,
  title = "Contact",
  saveOnSubmit = true,
  ...props
}) => {
  const contacts = _.get(mainForm.values, name) ?? [];

  const [cellRender] = useCellRender();

  const tableSchema = {
    hidePagination: true,
    columns: [
      {
        field: "name",
        headerName: "Name",
        cellRender: (column, data) => cellRender.ContactPersonName(data),
      },
      {
        field: "phone",
        headerName: "Phone",
      },
      { field: "email", headerName: "Email" },

      {
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            label: "Edit",
            icon: IconPencil,
          },
          {
            name: "delete",
            label: "Delete",
            icon: IconTrash,
            props: {},
          },
        ],
        actionMenu: [],
      },
    ],
    searchableFields: [
      // 'line',
      // 'name',
      // 'cname',
      // 'email',
    ],
  };

  const formSchema = {
    title: "Contact Information",
    // description:"please fill in the form",

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "xl",
    },
    initialValues: {},

    layout: {
      containers: [
        {
          key: "section-contact",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
          props: {
            description: "Please fill in the contact info",
            withBorder: false,
            ml: 10,
            mt: 0,
            mb: 10,
          },
        },
        {
          key: "g",
          parent: "section-contact",
          type: ENUM_FORM_LAYOUT_CONTAINER.GRID,
          cols: [
            {
              key: "g1",
              props: {
                span: 6,
              },
            },
            {
              key: "g2",
              props: {
                span: 6,
              },
            },
            {
              key: "g3",
              props: {
                span: 12,
              },
            },
          ],
        },
      ],

      fields: [
        {
          name: "name",
          component: TextInput,
          parent: "g1",
          props: {
            required: true,
            label: "Name",
          },
        },
        {
          name: "phone",
          component: TextInput,
          parent: "g1",
          props: {
            required: true,
            label: "Mobile Phone",
          },
        },

        {
          name: "email",
          component: TextInput,
          parent: "g1",
          props: {
            // required: true,
            label: "Email",
          },
        },
        {
          name: "title",
          component: TextInput,
          parent: "g2",
          props: {
            // required: true,
            label: "Work Title",
            placeholder: "Manager",
          },
        },
        {
          name: "phone2",
          component: TextInput,
          parent: "g2",
          props: {
            // required: true,
            label: "Direct Line",
          },
        },
        {
          name: "department",
          component: TextInput,
          parent: "g2",
          props: {
            label: "Department",
            placeholder: "",
          },
        },
      ],
    },
  };

  const onFormSubmit = (values) => {
    // console.log("onFormSubmit", values, mainForm);
    mainForm.setFieldValue(name, values);
    if (saveOnSubmit) mainForm.save();
  };

  return (
    <>
      {/* {JSON.stringify(rows)} */}
      {/* {<ReactJson src={rows} name="rows" collapsed style={{background:'white'}}/>} */}
      {/* {<ReactJson src={addresses} name="Addresses" collapsed style={{background:'white'}}/>} */}

      <LocalPageList
        rows={contacts}
        onFormSubmit={onFormSubmit}
        tableSchema={tableSchema}
        formSchema={formSchema}
        {...props}
      />
    </>
  );
};

export default ContactList;
