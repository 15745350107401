import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  useCallback,
} from "react";
import {
  createStyles,
  Table,
  ScrollArea,
  Select,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  Pagination,
  Space,
  Button,
  Menu,
  ActionIcon,
  Badge,
  Paper,
  Container,
  Box,
} from "@mantine/core";
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
  IconDots,
  IconCross,
  IconClearAll,
  IconLetterX,
  IconSquareLetterX,
  IconX,
} from "@tabler/icons-react";
import _ from "lodash";
import { useDebouncedValue, useForceUpdate } from "@mantine/hooks";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useTranslation } from "react-i18next";
import JsonViewer from "./common/jsonViewer";

function Th({ children, reversed, sorted, onSort, w }) {
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <Table.Th w={w}>
      <Group
        justify="space-between"
        onClick={onSort}
        style={{ cursor: "pointer" }}
        wrap="nowrap"
      >
        {children}
        <Center>
          <Icon size={14} stroke={1.5} />
        </Center>
      </Group>
    </Table.Th>
  );
}

const SearchBar = ({ onChange = console.log, searchText }) => {
  const { t } = useTranslation();
  return (
    <>
      <TextInput
        placeholder={t("Search")}
        mb="md"
        icon={<IconSearch size={14} stroke={1.5} />}
        value={searchText}
        // clearable={true}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
        rightSection={
          searchText && (
            <UnstyledButton
              onClick={() => {
                onChange("");
              }}
            >
              <IconX size={14} stroke={1.5} />{" "}
            </UnstyledButton>
          )
        }
        // className={classes.search}
      />
    </>
  );
};

const PaginationBar = ({
  pageSize,
  onPageSizeChange,
  pagination,
  onPageChange,
}) => {
  return (
    <>
      {/* {JSON.stringify(pagination)} */}
      {pagination && (
        <Group justify="right">
          <Group>
            <Pagination
              size="xs"
              page={pagination.page}
              total={pagination.totalPage}
              siblings={1}
              onChange={onPageChange}
            />
            <Select
              data={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "30", label: "30" },
                { value: "40", label: "40" },
                { value: "50", label: "50" },
                //   { value: 100, label: "100" },
              ]}
              value={pageSize}
              onChange={onPageSizeChange}
              size="xs"
            />
            <Text size="xs" color="dimmed">
              {pagination.total} Records
            </Text>
          </Group>
        </Group>
      )}
    </>
  );
};

const DataTable = (
  {
    data = [],
    columns = [],
    hidePagination = false,
    hideSearchBar = false,
    minHeight = "200px",
    pageSize = 10,
    pagination = { total: 10 },
    searchText = "",
    onPageSizeChange = () => {},
    onPageChange = () => {},
    onSearchChange = () => {},
    onSortChange = () => {},
    onActionBtnClick = () => {},
    onRowDoubleClick = () => {},

    padding = "xl",
    tableHeader,
    showRefreshButton = false,
    withPaper = true,
    emptyNotShow = false,
  },
  ref
) => {
  useImperativeHandle(ref, () => {
    return {
      // refresh: ()=>fetchData()
    };
  });

  const [scrolled, setScrolled] = useState(false);

  const [sortBy, setSortBy] = useState(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const [rows, setRows] = useState([]);

  const forceUpdate = useForceUpdate();

  const setSorting = (field) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  const setDataRow = () => {
    // console.log("datatable effect", data);
    if (!data) return;
    setRows(
      data?.map((d, index) => (
        <Table.Tr
          key={index}
          // onDoubleClick={() => onRowDoubleClick({ data: d, rowIndex: index })}
          // onDoubleClick={() => console.log("click")}
        >
          {columns?.map((col, index_c) => (
            <Table.Td key={index_c} style={{ verticalAlign: "top" }}>
              {!col.cellRender && _.get(d, col.field)}
              {col.cellRender && !col.isAction && col.cellRender(col, d)}
              {col.cellRender &&
                col.isAction &&
                col.cellRender(col, index, d, onActionBtnClick)}
            </Table.Td>
          ))}
        </Table.Tr>
      ))
    );
    forceUpdate();
  };
  useEffect(() => {
    setDataRow();
  }, [data]);

  useEffect(() => {
    onSortChange(sortBy, reverseSortDirection);
  }, [sortBy, reverseSortDirection]);

  const container = { component: withPaper ? Paper : Box };
  const { t } = useTranslation();
  const TableHeader = () => (
    <Table.Thead>
      <Table.Tr>
        {columns.map((col, index) => {
          return col.sortable ? (
            <Th
              key={col.field + index}
              sorted={sortBy === col.field}
              reversed={reverseSortDirection}
              onSort={() => setSorting(col.field)}
              w={col.width}
            >
              {t(col.headerName ?? _.capitalize(col.field))}
            </Th>
          ) : (
            <Table.Th key={col.field + index}>
              {t(col.headerName ?? _.capitalize(col.field))}
            </Table.Th>
          );
        })}
      </Table.Tr>
    </Table.Thead>
  );

  return (
    <>
      {/* <JsonViewer src={data[0].createdBy} /> */}
      {/* {JSON.stringify(columns)} */}
      {/* {sortBy} - {reverseSortDirection?'true':'false'} */}
      {/* <ReactJson src={data} collapsed style={{ background: "white" }} /> */}
      <container.component
        p={padding}
        mx={0}
        shadow={0}
        style={{ width: "100%" }}
      >
        {!hideSearchBar && (
          <>
            <SearchBar onChange={onSearchChange} searchText={searchText} />
            <Space h={"md"} />
          </>
        )}
        {tableHeader && <tableHeader.component {...tableHeader.props} />}
        {/* <ScrollArea sx={{ minHeight }} className={classes.scrollArea}> */}
        {/* onScrollPositionChange={({ y }) => setScrolled(y !== 0)}> */}
        {(!emptyNotShow || rows.length > 0) && (
          <Table.ScrollContainer minWidth={800} scrollbars="xy">
            <Table striped highlightOnHover withTableBorder>
              <TableHeader />
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        )}
        {/* </ScrollArea> */}
        {showRefreshButton && (
          <Button
            onClick={() => setDataRow()}
            size="xs"
            mt="xl"
            variant="light"
          >
            Refresh
          </Button>
        )}
        <Space h={"xl"} />
        {!hidePagination && (
          <PaginationBar
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            pagination={pagination}
            onPageChange={onPageChange}
          />
        )}
      </container.component>
    </>
  );
};

export default React.forwardRef(DataTable);
