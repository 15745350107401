import React, { useEffect } from "react";
import { FormSectionCard } from "../layout/page";
import { Title, Group, Card, Divider, Text, Anchor } from "@mantine/core";
import { useCellRender } from "../hooks/useCellRender";
import InfoRowList from "./infoRowList";
import InfoGridList from "./infoGridList";
import moment from "moment";
import { IconDotsVertical } from "@tabler/icons-react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useServerApi } from "../hooks/useServerApi";

function QuotationProjectDetail({ form, title, shadow = "md" }) {
  const [cellRender] = useCellRender();
  const { t } = useTranslation();
  const project = _.get(form.values, "project") || "";
  const [api] = useServerApi();

  const fetchProject = async (id) => {
    try {
      const data = await api.getById({ apiEntity: "project", id });
      if (!data) return;
      form.setFieldValue("project", data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!project) return;
    if (_.isString(project)) {
      fetchProject(project);
    }
  }, [project]);

  const fields = [
    {
      title: "Code",
      value: project?.code,
    },
    {
      title: "Client",
      // value: project?.client?.name,
      render: () => (
        <Anchor
          href={`/client?id=${project?.client?._id}&mode=edit`}
          target="_blank"
          underline="hover"
        >
          <Text size="xs" lineClamp={1}>
            {project?.client?.name}
          </Text>
        </Anchor>
      ),
    },
    { title: "Event Type", value: project?.projectType?.name },
    { title: "Project Status", value: project?.status },
    // { title: "Date", value: moment(project?.duration.start).format("ll") },
    // { title: "Time", value: moment(project?.duration.start).format("LT") },
  ];
  return (
    <>
      {project && (
        <>
          <Text size="xs" tt={"uppercase"} mb={"xs"}>
            {t(title)}
          </Text>

          <Card shadow={shadow}>
            <Card.Section withBorder inheritPadding py="xs">
              <Group justify="space-between">
                <Text size="sm" fw={500}>
                  {project.name}
                </Text>
                {/* <IconDotsVertical size={12} /> */}
              </Group>
            </Card.Section>
            <Card.Section inheritPadding py="md">
              <InfoRowList fields={fields} values={project} cols={2} />
            </Card.Section>
          </Card>
        </>
      )}
    </>
  );
}

export default QuotationProjectDetail;
