import React from "react";
import { Button, Text, TextInput, Chip, Select } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconExclamationMark, IconCircleCheck } from "@tabler/icons-react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { useServerApi } from "../hooks/useServerApi";
const UserActiveEnableButton = ({ mainForm, ...props }) => {
  const { status, _id } = mainForm.values;
  const [api] = useServerApi();
  const { t } = useTranslation();
  const handleClick = async () => {
    const newStatus = status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    const activeMessage = "User now can access the system.";
    const inactiveMessage = "User cannot access the system anymore.";
    const result = await api.User.setStatus(_id, newStatus);
    if (result.success) {
      showNotification({
        title: `User status updated to ${newStatus}.`,
        message: newStatus === "ACTIVE" ? activeMessage : inactiveMessage,
        icon: <IconCircleCheck size={18} />,
        color: "green",
      });
      console.log("Set Status", result);
      mainForm.setFieldValue("status", newStatus);
      mainForm.setFieldValue("isActive", newStatus === "Active" ? true : false);
    } else {
      showNotification({
        title: `User status can't updated`,
        color: "red",
        icon: <IconExclamationMark size={18} />,
        message: result.message,
      });
    }
  };

  const isActive = () => {
    return status === "ACTIVE";
  };

  return (
    <>
      {/* Status: {status} */}
      <Button
        fullWidth
        onClick={handleClick}
        // color={isActive() ? "orange" : "green"}
        disabled={status === "TERMINATED"}
        variant="light"
        color="gray"
      >
        {isActive() ? t("Deactive User") : t("Active User")}
      </Button>
    </>
  );
};

export default UserActiveEnableButton;
