import { Card, Text, Title, Divider, Box } from "@mantine/core";
import _ from "lodash";
import { FormSectionCard } from "../layout/page";
import { useTranslation } from "react-i18next";

const K_SECTION_MIN_HEIGHT = "350px";

const FormSection = ({
  title,
  titleOrder = 5,
  description,
  children,
  radius = "sm",
  p = "xs",
  withBorder = true,
  withPaper = true,
  minHeight = K_SECTION_MIN_HEIGHT,

  ...props
}) => {
  const { t } = useTranslation();
  return (
    <>
      {/* {JSON.stringify(props)} */}

      <FormSectionCard title={t(title)} mt={"md"} withPaper={withPaper} p={p}>
        {children}
      </FormSectionCard>
      {/* <Card
                withBorder={withBorder}
                p={p}
                radius={radius}
                {...props}
                style={{ minHeight }}
            >
                {title && (
                    <Box mb={20}>
                        {<Title order={titleOrder}>{title}</Title>}

                        {description && (
                            <Text size="xs" color="dimmed" mt={3} mb="xl">
                                {description}
                            </Text>
                        )}
                    </Box>
                )}

                {children}
            </Card> */}
    </>
  );
};

export default FormSection;
