import { Button, Group } from "@mantine/core";
import React from "react";

import UserActiveEnableButton from "./userActiveEnableButton";
import UserLeaveActionButton from "./userLeaveActionButton";
import UserEditButton from "./userEditButton";
import { FormSectionCard } from "../layout/page";

const UserActionList = ({ form, role }) => {
  // const showEditOrderButton = role == "HR" || role === "MANAGEMENT";

  return (
    <FormSectionCard title={"Actions"}>
      <Group mt={10}>
        <UserEditButton mainForm={form} />
        <UserActiveEnableButton mainForm={form} />
        {/* <UserLeaveActionButton mainForm={form} /> */}
      </Group>
    </FormSectionCard>
  );
};

export default UserActionList;
