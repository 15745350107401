import { useCellRender } from "./useCellRender";

import { UserformSchema } from "../schema/form/user";
import { formSchema as UserRoleFormSchema } from "../schema/form/userRole";
import { ClaimformSchema } from "../schema/form/claim";
import { QuotationFormSchema } from "../schema/form/quotation";
import { InvoiceformSchema } from "../schema/form/invoice";
import { StatementformSchema } from "../schema/form/statement";
import { ClientFormSchema } from "../schema/form/client";
import { ProjectformSchema } from "../schema/form/project";
import { formSchema as ItemFormSchema } from "../schema/form/item";

import { ItemCategoryFormSchema } from "../schema/form/itemCategory";
import { ClaimTypeFormSchema } from "../schema/form/claimType";
import { ItemTransactionFormSchema } from "../schema/form/itemTransaction";
import { ItemGroupFormSchema } from "../schema/form/itemGroup";
import { ProjectTypeFormSchema } from "../schema/form/projectType";
import { ProjectFormSchema } from "../schema/form/project";
import { TaskFormSchema } from "../schema/form/task";
import { ProjectQuotationSchema } from "../schema/form/projectQuotation";
import { ProjectInvoiceSchema } from "../schema/form/projectInvoice";
import { ClaimFormSchema } from "../schema/form/claim";
import { ClaimPaymentFormSchema } from "../schema/form/claimPayment";
import { ClaimSettlementSchema } from "../schema/form/claimSettlement";
import { CalendarEventFormSchema } from "../schema/form/calendarEvent";

export const useFormSchema = () => {
  const getFormSchema = (name) => {
    switch (name) {
      case "user":
        return UserformSchema;
      case "userRole":
        return UserRoleFormSchema;
      case "client":
        return ClientFormSchema;

      case "itemCategory":
        return ItemCategoryFormSchema;

      case "itemGroup":
        return ItemGroupFormSchema;

      case "item":
        return ItemFormSchema;

      case "task":
        return TaskFormSchema;

      case "itemTransaction":
        return ItemTransactionFormSchema;

      case "claimType":
        return ClaimTypeFormSchema;

      case "calendarEvent":
        return CalendarEventFormSchema;

      case "projectType":
        return ProjectTypeFormSchema;

      case "project":
        return ProjectFormSchema;

      case "quotation":
        return QuotationFormSchema;

      case "ProjectQuotation":
        return ProjectQuotationSchema;

      case "ProjectInvoice":
        return ProjectInvoiceSchema;

      case "claim":
        return ClaimFormSchema;

      case "claimPayment":
        return ClaimPaymentFormSchema;

      case "claimSettlement":
        return ClaimSettlementSchema;

      // case "claim":
      //   return ClaimformSchema(cellRender);
      // case "invoice":
      //   return InvoiceformSchema(cellRender);
      // case "statement":
      //   return StatementformSchema(cellRender);

      // case "project":
      //   return ProjectformSchema(cellRender);
      // case "item":
      //   return ItemformSchema(cellRender);
    }
  };

  return [getFormSchema];
};
