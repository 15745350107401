import React, { useRef, useState } from "react";
import FormSection from "./formSection";
import OrderList from "./orderList";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import {
  Card,
  Group,
  NumberInput,
  Title,
  Text,
  Button,
  Tabs,
} from "@mantine/core";

import FileList from "./fileList";
import GroupMenu from "./groupMenu";
import Illustrator from "./illustrator";
import ProductionSectionInfo from "./productionSectionInfo";
import { useServerApi } from "../hooks/useServerApi";

const ProductionSection = ({ section, form, index }) => {
  const sectionFormName = `productionSections.${index}`;
  const { t } = useTranslation();

  return (
    <>
      {/* <Title order={5} mb="xl">
        {section.name}
      </Title> */}
      <Tabs defaultValue={"basic"} mt="xl">
        <Tabs.List>
          <Tabs.Tab value="basic"> {t("Section Info")}</Tabs.Tab>
          <Tabs.Tab value="illustration"> {t("Illustration")}</Tabs.Tab>
          <Tabs.Tab value="files"> {t("Files")}</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="basic">
          <ProductionSectionInfo
            section={section}
            form={form}
            sectionIndex={index}
          />
        </Tabs.Panel>

        <Tabs.Panel value="illustration">
          <Illustrator form={form} name={`${sectionFormName}.files`} />
        </Tabs.Panel>

        <Tabs.Panel value="files">
          <Card>
            {/* <JsonViewer src={_.get(form.values, `${sectionFormName}.files`)} /> */}
            <FileList form={form} name={`${sectionFormName}.files`}></FileList>
          </Card>
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export const defaultSectionValue = {
  name: "Section 1",
  files: [],
  description: "",
  itemGroups: [
    {
      name: "Main Group",
      groupType: null,
      dimensions: {
        width: 0,
        depth: 0,
        height: 0,
      },
      itemList: [],
      floorPlan: [],
    },
  ],
};

const ProductionSections = ({ form, name }) => {
  const sections = _.get(form.values, name);
  const [api] = useServerApi();

  const newSection = {
    ...defaultSectionValue,
    name: `Section ${sections.length + 1}`,
  };

  const handleAddSection = () => {
    sections.push(newSection);
    form.setFieldValue("productionSections", sections);
  };

  const handleRemoveSession = (index) => {
    sections.splice(index, 1);
    form.setFieldValue("productionSections", sections);
  };

  const { t } = useTranslation();

  const printItemList = async () => {
    try {
      // console.log("printItemList");
      if (!form.values._id) return;
      const result = await api.Project.printItemList(form.values._id);
      if (result && result.data && result.data.url) {
        window.open(result.data.url, "_blank");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <JsonViewer src={form.values} /> */}
      {_.isEmpty(sections) && (
        <Group>
          <Button
            size="xs"
            variant="default"
            onClick={handleAddSection}
            mr="xs"
          >
            {t("Add Section")}
          </Button>
        </Group>
      )}
      {!_.isEmpty(sections) && (
        <>
          <Group justify="right" mb="sm">
            <Button onClick={() => printItemList()} size="xs" variant="default">
              {t("Print Production")}
            </Button>
          </Group>

          <Tabs
            variant="outline"
            // orientation="vertical"
            defaultValue={"0"}
            placement="right"
            // mb="xl"
          >
            <Tabs.List grow>
              <Button
                size="xs"
                variant="default"
                onClick={handleAddSection}
                mr="xs"
              >
                {t("Add Section")}
              </Button>

              {sections.map((section, index) => (
                <Tabs.Tab value={index + ""} size={"xs"}>
                  <Group>
                    <Text size="xs">{section.name}</Text>
                    <GroupMenu
                      menu={[
                        {
                          label: "Remove Session",
                          color: "red",
                          onClick: () => handleRemoveSession(index),
                        },
                      ]}
                    />
                  </Group>
                </Tabs.Tab>
              ))}
            </Tabs.List>

            {sections.map((section, index) => (
              <Tabs.Panel value={index + ""} px={0} pr={"md"} key={index}>
                <ProductionSection
                  section={section}
                  form={form}
                  index={index}
                />
              </Tabs.Panel>
            ))}
          </Tabs>
        </>
      )}
    </>
  );
};

export default ProductionSections;
