import React, { useState } from "react";
import {
  UnstyledButton,
  UnstyledButtonProps,
  Group,
  Avatar,
  Text,
  createStyles,
  Menu,
  Button,
} from "@mantine/core";
import {
  IconChevronRight,
  IconChevronDown,
  IconAt,
  IconPhoneCall,
  IconSettings,
  IconMessageCircle,
  IconPhoto,
  IconSearch,
  IconLogout,
  IconSun,
  IconMoon,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import {
  useMantineColorScheme,
  SegmentedControl,
  Center,
  Box,
} from "@mantine/core";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../context/language";
import { useContext } from "react";

export const ToogleThemeButton = () => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <SegmentedControl
      fullWidth
      value={colorScheme}
      onChange={(value) => toggleColorScheme(value)}
      data={[
        {
          value: "light",
          label: (
            <Center>
              <IconSun size={14} stroke={1.5} />
              <Box ml={10}>Light</Box>
            </Center>
          ),
        },
        {
          value: "dark",
          label: (
            <Center>
              <IconMoon size={14} stroke={1.5} />
              <Box ml={10}>Dark</Box>
            </Center>
          ),
        },
      ]}
    />
  );
};
const UserPopupButton = ({ user, ...others }) => {
  const { image, name, email, phone, title, icon } = user;
  const [opened, setOpened] = useState(false);
  const { language, changeLanguage } = useContext(LanguageContext);
  const { t } = useTranslation();
  const handleChangeLanguage = (lng) => {
    changeLanguage(lng);
  };
  const signOut = useSignOut();
  const navigate = useNavigate();
  return (
    <Menu
      position="top"
      opened={opened}
      onChange={setOpened}
      offset={10}
      width={"280"}
      withArrow
    >
      <Menu.Target>
        <UnstyledButton
          {...others}
          style={{
            display: "block",
            width: "100%",
          }}
          px={"xl"}
          mb={"xl"}
        >
          <Group spacing={"xs"}>
            <Avatar src={image} radius="xl" />
            <div style={{ flex: 1 }}>
              <Text size="sm" weight={500}>
                {name}
              </Text>

              <Text color="dimmed" size="xs" lineClamp={1}>
                {email}
              </Text>
            </div>
            {icon || <IconChevronDown size={14} stroke={1.5} />}
          </Group>
        </UnstyledButton>
      </Menu.Target>
      {/* Menu items */}
      <Menu.Dropdown>
        <Menu.Label> {t("Setting")}</Menu.Label>
        <Menu.Divider />

        <Menu.Item
          icon={<IconSettings size={14} />}
          onClick={() => navigate("/setting")}
        >
          {t("User Profile")}
        </Menu.Item>

        <Menu.Item
          icon={<IconLogout size={14} />}
          onClick={() => navigate("/resetPassword")}
        >
          {t("Change Password")}
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            signOut();
            navigate("/login");
          }}
          icon={<IconLogout size={14} />}
        >
          {t("Logout")}
        </Menu.Item>

        <Menu.Divider />
        {/* <Menu.Item> */}
        <ToogleThemeButton />
        {/* </Menu.Item> */}
        <Group noWrap spacing={"sm"} my="xs" gap={"xs"} justify="">
          <Button variant="default" onClick={() => handleChangeLanguage("en")}>
            EN
          </Button>
          <Button
            variant="default"
            onClick={() => handleChangeLanguage("zh-tw")}
          >
            繁體
          </Button>
          <Button
            variant="default"
            onClick={() => handleChangeLanguage("zh-tw")}
          >
            簡体
          </Button>
        </Group>
      </Menu.Dropdown>
    </Menu>
  );
};

export default UserPopupButton;
