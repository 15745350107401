import { Select, TextInput, Textarea } from "@mantine/core";
import { ENUM_FORM_DISPLAY } from "../../components/pageList";
import AutoCodeInput from "../../components/autoCodeInput";
import { DATA_UNIT_OF_MEASURE } from "../../data/options";
import RemoteSelect2 from "../../components/remoteSelect2";
import { DateInput } from "@mantine/dates";
export const TaskFormSchema = {
  title: "Task",
  isMainForm: true,
  display: {
    mode: ENUM_FORM_DISPLAY.DRAWER,
    size: "xl", //xs, sm, xl  ....100%
    position: "right",
  },
  apiEntity: "task",

  initialValues: {
    code: "",
    name: "",
    title: "",
    user: null,
    status: "toDo",
  },

  layout: {
    fields: [
      {
        name: "title",
        component: TextInput,
        props: {
          required: true,
          label: "Title",
          placeholder: "",
        },
      },
      {
        name: "user",
        component: RemoteSelect2,
        props: {
          label: "User",
          apiEntity: "user",
          required: true,
          valueField: "_id",
          labelField: "name",
          labelRender: (data) => `[${data.staffId}] ${data.aliasName}`,
          searchFields: ["name", "aliasName", "staffId"],
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "status",
        component: Select,
        props: {
          required: true,
          label: "Status",
          placeholder: "",
          defaultValue: "toDo",
          data: [
            { value: "toDo", label: "To Do" },
            { value: "done", label: "Completed" },
          ],
        },
      },
      {
        name: "completedAt",
        component: DateInput,
        type: "datePicker",

        props: {
          label: "Completed At",
          zIndex: 1000,
          placeholder: "Pick Date",
          allowFreeInput: true,
          type: "datePicker",
          clearable: true,
        },
      },
      {
        name: "description",
        component: Textarea,
        props: {
          // required: true,
          label: "Description",
          placeholder: "",
          mb: "xl",
        },
      },
    ],
  },
};
