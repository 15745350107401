import React from "react";
import _ from "lodash";
import { DatePicker, DateInput } from "@mantine/dates";
import moment from "moment";

const QuotationDateInput = ({
  form,
  name,
  label,
  placeholder,
  allowFreeInput = true,
  defaultValue,
  validDateField = "validDate",
  dayDiff = 14,
}) => {
  return (
    // <>DateInput</>
    <DateInput
      value={moment(_.get(form.values, name)).toDate()}
      allowFreeInput={allowFreeInput}
      defaultValue={defaultValue}
      onChange={(value) => {
        form.setFieldValue(name, value);
        form.setFieldValue(
          validDateField,
          moment(value).add(dayDiff, "days").toDate()
        );
      }}
      placeholder={placeholder}
      label={label}
    />
  );
};

export default QuotationDateInput;
