import moment from "moment";
import {
  Title,
  Text,
  Group,
  Badge,
  Avatar,
  Card,
  Popover,
  UnstyledButton,
  Select,
} from "@mantine/core";
import React, { useState } from "react";
import _ from "lodash";
import {
  IconUser,
  IconBuildingBank,
  IconUsers,
  IconPhone,
  IconMail,
  IconStar,
  IconBuildingFactory2,
  IconBuildingFactory,
  IconBuildingSkyscraper,
  IconHome2,
  IconBrandProducthunt,
  IconBox,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useFormatter } from "../hooks/useFomatter";

export const openWhatsapp = (phone, message = "") => {
  // Regex expression to remove all characters which are NOT alphanumeric
  if (!phone) return;
  let number = phone.replace(/[^\w\s]/gi, "").replace(/ /g, "");

  // Appending the phone number to the URL
  let url = `https://web.whatsapp.com/send?phone=${number}`;
  url += `&text=${encodeURI(message)}&app_absent=0`;

  window.open(url, "child-window");
};

export const useCellRender = () => {
  const [formatter] = useFormatter();
  const FollowupAction = (col, data) => (
    <>
      <Group>
        <Badge>{data?.messages?.length ?? 0}</Badge>
        <span>
          <Text size="sm">{data.title}</Text>
          <Text color="dimmed" size="xs" transform="capitalize">
            {data.type}
          </Text>
        </span>
      </Group>
    </>
  );

  const BidType = (type, size = "sm") => {
    const getColor = (type) => {
      switch (type) {
        case "COMMISSION":
          return "green";
        case "DEBT":
          return "red";
        case "ALLOWANCE":
          return "blue";

        default:
          return "dimmed";
      }
    };
    return (
      <>
        <Badge color={getColor(type)} size={size}>
          {type}
        </Badge>
      </>
    );
  };

  const QuoRequest = {
    status: (status) => {
      const getStatusColor = (status) => {
        switch (status) {
          case "DFT":
            return "yellow";
          case "CAN":
            return "red";
          case "DONE":
            return "yellow";
          case "CONFIRM":
            return "green";
          case "COPIEDDN":
            return "blue";
        }
      };
      return (
        <>
          <Badge color={getStatusColor(status)} size="xs" radius={0}>
            {status}
          </Badge>
        </>
      );
    },
  };

  const Status = (status, size = "xs", radius = 0) => {
    const getStatusColor = (status) => {
      switch (status.toUpperCase()) {
        case "DFT":
          return "yellow";
        case "OPENING":
          return "red";
        case "PENDING":
          return "red";
        case "PND":
          return "red";
        case "PAID":
          return "green";
        case "DONE":
          return "green";
        case "COMPLETED":
          return "green";
        case "CONFIRMED":
          return "green";
        case "CAN":
          return "red";
        case "CANCEL":
          return "red";
        case "INACTIVE":
          return "orange";
        case "TERMINATED":
          return "red";

        case "INPROGRESS":
          return "orange";
        default:
          return "blue";
      }
    };
    return (
      <>
        <Badge color={getStatusColor(status)} size={size} radius={radius}>
          {status}
        </Badge>
      </>
    );
  };

  const FollowupDuration = (start, end) => {
    return (
      <>
        {end && <Badge> {moment().diff(end, "day") + " Days"} </Badge>}
        {!end && <Badge> {moment().diff(start, "day") + " Days"} </Badge>}
        <Text>{moment(start).format("LLL")} </Text>
        {/* { end && <Text>{moment(end).format("LLL")} </Text> } */}
      </>
    );
  };

  const NumberFormat = (value) =>
    !Number.isNaN(parseFloat(value))
      ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : "";

  const Currency = (price, currency) => formatter.currency(price, currency);

  const Date = (date, format, fontWeight = "normal") => {
    if (!date) return "-";
    return moment(date).format(format);
  };

  const Mask = (
    value,
    markSymbol,
    showSymbolNumber,
    showValueNumber,
    maskPosition = "start"
  ) => {
    if (!value) return "";
    switch (maskPosition) {
      case "start":
        return (
          markSymbol.repeat(showSymbolNumber) + value.slice(-showValueNumber)
        );
      case "end":
        return (
          value.slice(0, showValueNumber) + markSymbol.repeat(showSymbolNumber)
        );
    }
  };

  const GetClientTypeIcon = (type) => {
    switch (type) {
      case "INDIVIDUAL":
        return <IconUser color="#7367F0" />;
      case "CORPORATE":
        return <IconBuildingBank color="#7367F0" />;

      case "COOPERATE":
        return <IconBuildingBank color="red" />;
      case "STAFF":
        return <IconUsers color="orange" />;
    }
  };

  const Address = (address, size = "md") => {
    try {
      const getAddressContent = (addPart) => {
        if (_.isEmpty(addPart) || _.isNaN(addPart) || _.isUndefined(addPart))
          return "";

        if (_.isString(addPart)) return addPart.toUpperCase();

        if (_.isObject(addPart) && _.isArray(addPart.richText)) {
          const strarray = addPart.richText.map((p) =>
            _.isString(p.text) ? p.text?.toUpperCase() : ""
          );
          return strarray?.join(", ");
        }

        return "";
      };

      return (
        <>
          {/* <ReactJson src={address} style={{ backgroud: "white" }} /> */}
          {address?.line1 && (
            <Text size={size}>{getAddressContent(address?.line1)}</Text>
          )}
          {address?.line2 && (
            <Text size={size}>{getAddressContent(address?.line2)}</Text>
          )}
          {
            <Text size={size}>
              {address?.city && getAddressContent(address?.city) + ", "}
              {address?.state && getAddressContent(address?.state)}
            </Text>
          }

          {
            <Text size={size}>
              {address?.zip && getAddressContent(address?.zip) + ", "}
              {address?.country && getAddressContent(address?.country)}
            </Text>
          }
        </>
      );
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  const AddressType = (type) => {
    return (
      <>
        {type === "correspondence" && (
          <Group>
            <IconMail size={15} />
            <Text size={"xs"} transform="capitalize">
              {type}
            </Text>
          </Group>
        )}
        {type != "correspondence" && (
          <Group>
            <IconHome2 size={15} />
            <Text size={"xs"} transform="capitalize">
              {type}
            </Text>
          </Group>
        )}
      </>
    );
  };

  const ContactPersonName = (contact) => {
    return (
      <span>
        <Text>{contact.name}</Text>
        <Text size="xs" color="dimmed">
          {contact.title} - {contact.department}
        </Text>
      </span>
    );
  };

  const ClientInfo = (
    client,
    showPhone = true,
    showEmail = false,
    showIcon = false,
    allowWhatsapp = true
  ) => {
    return (
      <>
        {client && (
          <Group noWrap>
            {showIcon && <div>{GetClientTypeIcon(client.type)}</div>}
            <span>
              <Group maw={200}>
                <Text lineClamp={1}>
                  {_.startCase(_.toLower(client.name))} {client.cname}
                </Text>
              </Group>
              <Group spacing={"lg"} noWrap>
                {showPhone && client.phone && (
                  <UnstyledButton onClick={() => openWhatsapp(client.phone)}>
                    <Group spacing={3} noWrap>
                      <IconPhone size={10}></IconPhone>
                      <Text size="xs" color={"dimmed"}>
                        {client.phone}
                      </Text>
                    </Group>
                  </UnstyledButton>
                )}

                {showEmail && client.email && (
                  <Group spacing={3} noWrap>
                    <IconMail size={10} />
                    <Text size="xs" color={"dimmed"} lineClamp={1}>
                      {client.email}
                    </Text>
                  </Group>
                )}
              </Group>
            </span>
          </Group>
        )}
      </>
    );
  };

  const ProductInfo = (product) => {
    return (
      product && (
        <Group noWrap>
          {/* <ReactJson src={product} style={{ background: "white" }} /> */}
          <Avatar
            src={
              product.provider?.image
                ? `${process.env.REACT_APP_FILE_URL}/${product.provider?.image}`
                : null
            }
            radius={"xl"}
          >
            {(!product?.provider || !product.provider?.image) && <IconBox />}
          </Avatar>
          <span>
            <Text lineClamp={1}>{product.name}</Text>
            <Text color={"dimmed"} lineClamp={1}>
              {product.cname}
            </Text>
          </span>
        </Group>
      )
    );
  };

  const ProductType = (type) => {
    return (
      <div>
        <Text>{type?.name}</Text>
        <Text color={"dimmed"}>{type?.cname}</Text>
      </div>
    );
  };

  const ProviderInfo = (provider) => {
    return (
      provider && (
        <Group noWrap>
          {/* {provider} */}
          {/* <ReactJson src={provider} style={{ background: "white" }} /> */}
          <Avatar
            src={
              provider.image
                ? `${process.env.REACT_APP_FILE_URL}/${provider?.image}`
                : null
            }
            radius={"xl"}
          >
            <IconBuildingSkyscraper size="1.5rem" />
          </Avatar>
          <span>
            <Text>{provider.shortName}</Text>
            <Text size="xs" color={"dimmed"}>
              {provider.name}
            </Text>

            <Text color={"dimmed"}>{provider.cname}</Text>
          </span>
        </Group>
      )
    );
  };

  const ProductCode = (product) => {
    return (
      <>
        <Text>{product.code}</Text>
        <Text color={"dimmed"}>{product.provider?.name}</Text>
      </>
    );
  };

  const QuoteRequestCode = (dn) => {
    const getStatusColor = (status) => {
      switch (status) {
        case "DFT":
          return "yellow";

        case "CAN":
          return "red";

        case "DONE":
          return "green";

        default:
          return "blue";
      }
    };

    return (
      <Group noWrap>
        <span>
          <Badge color={getStatusColor(dn.status)} size="xs" radius={0}>
            {dn.status}
          </Badge>
          <Text size={"xs"}>{dn.code}</Text>
          <Text color={"dimmed"} size="xs">
            {moment(dn.createdAt).format("LL")}
          </Text>
          {/* <Text size={"xs"}>{order.policyNumber}</Text> */}
        </span>
      </Group>
    );
  };

  const DebitNoteCode = (dn) => {
    const getStatusColor = (status) => {
      switch (status) {
        case "DFT":
          return "yellow";
        case "WAIT":
          return "orange";
        case "CAN":
          return "red";
        case "PAID":
          return "blue";
        case "SETTLED":
          return "green";

        default:
          return "blue";
      }
    };

    return (
      <Group noWrap>
        <span>
          <Badge color={getStatusColor(dn.status)} size="xs" radius={0}>
            {dn.status}
          </Badge>
          <Text size={"xs"}>{dn.code}</Text>
          <Text color={"dimmed"} size="xs">
            {moment(dn.createdAt).format("LL")}
          </Text>
          {/* <Text size={"xs"}>{order.policyNumber}</Text> */}
        </span>
      </Group>
    );
  };

  const Invoice = {
    status: (status) => {
      const getStatusColor = (status) => {
        switch (status) {
          case "DFT":
            return "yellow";
          case "WAIT":
            return "blue";
          case "PARTIALLY":
            return "blue";
          case "CANCEL":
            return "red";
          case "PAID":
            return "green";
        }
      };
      return (
        <>
          <Badge color={getStatusColor(status)} size="xs" radius={0}>
            {status}
          </Badge>
        </>
      );
    },
  };

  const Quotation = {
    status: (status) => {
      const getStatusColor = (status) => {
        switch (status) {
          case "DFT":
            return "yellow";
          case "WAIT":
            return "blue";
          case "CANCEL":
            return "red";
          case "CONFIRM":
            return "green";
        }
      };
      return (
        <>
          <Badge color={getStatusColor(status)} size="xs" radius={0}>
            {status}
          </Badge>
        </>
      );
    },
  };

  const Order = {
    status: (status) => {
      const getStatusColor = (status) => {
        switch (status) {
          case "DFT":
            return "yellow";
          case "CAN":
            return "red";
          case "RCV":
            return "yellow";
          case "PND":
            return "orange";
          case "OTH":
            return "orange";
          case "AIP":
            return "cyan";
          case "SUB":
            return "cyan";
          case "INF":
            return "green";
          case "DONE":
            return "green";

          case "SUS":
            return "red";
          case "COL":
            return "red";
          case "TER":
            return "red";
          case "LAP":
            return "red";
          case "WTH":
            return "red";
          case "REJ":
            return "red";
          case "SUR":
            return "red";
          case "TRO":
            return "red";

          case "EXP":
            return "red";
          case "UNKNOWN":
            return "red";
        }
      };
      return (
        <>
          <Badge color={getStatusColor(status)} size="xs" radius={0}>
            {status}
          </Badge>
        </>
      );
    },
    type: (type) => (
      <>
        {type === "NEW" ? (
          <Badge color="green" size="xs" radius={0} mr={1}>
            N
          </Badge>
        ) : (
          <Badge color="yellow" size="xs" radius={0} mr={1}>
            T
          </Badge>
        )}
      </>
    ),
    code: (order, bySignDate = false) => {
      return (
        <Group noWrap>
          <span>
            {order.type === "NEW" ? (
              <Badge color="green" size="xs" radius={0} mr={1}>
                N
              </Badge>
            ) : (
              <Badge color="yellow" size="xs" radius={0} mr={1}>
                T
              </Badge>
            )}
            {Order.status(order.status)}
            <Text size={"xs"}>{order.code}</Text>
            <Text color={"dimmed"} size="xs">
              {!bySignDate && moment(order.createdAt).format("LL")}
              {bySignDate &&
                order.signedDate &&
                moment(order.signedDate).format("LL")}
            </Text>
            {/* <Text size={"xs"}>{order.policyNumber}</Text> */}
          </span>
        </Group>
      );
    },

    serviceCode: (service) => {
      return (
        <Group>
          <span>
            <Group spacing={1}>
              {Order.status(service.status)}
              <Badge color="blue" size="xs" radius={0} mr={1}>
                {service.serviceType.name}
              </Badge>
            </Group>
            <Text size={"xs"}>{service.code}</Text>
            <Text color={"dimmed"} size="xs">
              {moment(service.createdAt).format("LL")}
            </Text>
            {/* <Text size={"xs"}>{order.policyNumber}</Text> */}
          </span>
        </Group>
      );
    },

    simpleCodeWithStatus: (order, fontWeight = 600) => (
      <Group spacing={"lg"}>
        <Text weight={fontWeight}>
          {order.code} {Order.status(order.status)}
        </Text>
      </Group>
    ),
  };

  const Allowance = {
    name: (allowance) => (
      <div>
        <Text>{allowance.plan?.name}</Text>
        <Text>{allowance.plan?.cname}</Text>
      </div>
    ),
  };

  const Users = (users, maxDisplayUser = 4, showName = true) => {
    const [formatter] = useFormatter();

    const userExtra = _.isEmpty(users) ? 0 : users.length - maxDisplayUser;

    const getStatusColor = (status) => {
      switch (status) {
        case "INACTIVE":
          return "orange";
        case "TERMINATED":
          return "red";
        default:
          return "cyan";
      }
    };

    return (
      <>
        {/* {JSON.stringify(users)} */}

        <Group noWrap>
          {!_.isEmpty(users) && (
            <Avatar.Group spacing="sm" w={60}>
              {users?.map((user, index) =>
                index < maxDisplayUser ? (
                  <Avatar
                    key={index}
                    alt={user.name}
                    src={
                      user.image
                        ? `${process.env.REACT_APP_FILE_URL}/${user.image}`
                        : null
                    }
                    radius="xl"
                  >
                    {formatter.nameInitial(user)}
                  </Avatar>
                ) : null
              )}
              {userExtra > 0 ? (
                <Avatar radius="xl">{`+${userExtra}`}</Avatar>
              ) : null}
            </Avatar.Group>
          )}

          <span>
            {showName && !_.isEmpty(users)
              ? users.map((user) => (
                  <Group spacing={"xs"} noWrap>
                    <Badge
                      size="xs"
                      p={0}
                      radius={0}
                      mr={0}
                      w={50}
                      color={getStatusColor(user.status)}
                    >
                      {user.staffId}
                    </Badge>
                    <Text lineClamp={1}>{formatter.friendlyName(user)}</Text>
                  </Group>
                ))
              : null}
          </span>
        </Group>
      </>
    );
  };

  const UserAvatar = (user) => {
    return (
      <>
        <Avatar
          alt={user.name}
          src={
            _.isEmpty(user.image)
              ? null
              : `${process.env.REACT_APP_FILE_URL}/${user?.image}`
          }
          radius="xl"
        />
      </>
    );
  };

  const User = (user, showStaffId = false, description) => {
    const getStatusColor = (status) => {
      switch (status) {
        case "INACTIVE":
          return "orange";
        case "TERMINATED":
          return "red";
        default:
          return "cyan";
      }
    };

    return (
      <>
        {/* {user.image} */}
        {!user && "-"}
        {user && (
          <Group>
            <Avatar
              alt={user.name}
              src={
                _.isEmpty(user.image)
                  ? null
                  : `${process.env.REACT_APP_FILE_URL}/${user?.image}`
              }
              radius="xl"
            >
              {formatter.nameInitial(user)}
            </Avatar>
            <div>
              {
                <Group spacing={"xs"} noWrap>
                  {showStaffId && (
                    <Badge
                      size="xs"
                      p={0}
                      radius={0}
                      mr={0}
                      w={50}
                      color={getStatusColor(user.status)}
                    >
                      {user.staffId}
                    </Badge>
                  )}
                  <Text lineClamp={1}>{formatter.friendlyName(user)}</Text>

                  {/* <ReactJson src={user} style={{ background: "white" }} /> */}
                </Group>
              }
              <Text size="xs" color="dimmed">
                {description}
              </Text>
            </div>
          </Group>
        )}
      </>
    );
  };

  const ConsultantClients = (user, clientNum = 0) => {
    return (
      <>
        {user && (
          <Group justify="space-between">
            <Group>
              <Avatar
                alt={user?.name}
                src={
                  user?.image
                    ? `${process.env.REACT_APP_FILE_URL}/${user?.image}`
                    : null
                }
                radius="xl"
              >
                {/* {user.name} {user.cname} */}
                {formatter.nameInitial(user?.name)}
              </Avatar>

              <div>
                <Text lineClamp={1}>{user?.name}</Text>
                <Text size={"xs"} color={"dimmed"}>
                  {user?.staffId}
                </Text>
              </div>
            </Group>
            <Badge size="xs">{clientNum} </Badge>
          </Group>
        )}
      </>
    );
  };

  const cellRender = {
    FollowupAction,
    FollowupDuration,
    ClientInfo,
    ContactPersonName,
    GetClientTypeIcon,
    ProductInfo,
    ProductCode,
    ProductType,
    Status,
    Date,
    Users,
    User,
    Mask,
    UserAvatar,
    ConsultantClients,
    Allowance,
    Currency,
    NumberFormat,
    Order,
    Address,
    AddressType,
    DebitNoteCode,
    BidType,
    ProviderInfo,
    QuoteRequestCode,
    QuoRequest,
    Quotation,
    Invoice,
  };
  return [cellRender];
};
