import React, { useState, useEffect } from "react";
import { useForceUpdate } from "@mantine/hooks";

import {
  ActionIcon,
  Checkbox,
  Group,
  ScrollArea,
  Stack,
  Tabs,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { IconCirclePlus, IconPlus, IconSquarePlus } from "@tabler/icons-react";
import { useServerApi } from "../hooks/useServerApi";
import { Notification } from "@mantine/notifications";
import { useFormNotification } from "../layout/page";
import { useAuthUser } from "react-auth-kit";
import { TaskFormSchema } from "../schema/form/task";
import AddNewButton from "./addNewButton";
import { ENUM_FORM_DISPLAY } from "./pageList";

//Revise the taskNewSchema
const reviseTaskSchema = (TaskFormSchema, auth) => {
  const taskNewSchema = { ...TaskFormSchema };
  taskNewSchema.display.mode = ENUM_FORM_DISPLAY.MODAL;
  taskNewSchema.initialValues.user = auth().id;
  taskNewSchema.initialValues.status = "toDo";
  //remove layout fields "completed At"
  taskNewSchema.layout.fields = taskNewSchema.layout.fields.filter(
    (f) => f.name !== "completedAt" && f.name !== "status" && f.name !== "user"
  );
  return taskNewSchema;
};

const MyTaskList = () => {
  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();
  const [api] = useServerApi();
  const [tasks, setTasks] = useState([]);
  const [notifyFormSuccess, notifyFormError] = useFormNotification();
  const auth = useAuthUser();
  const taskNewSchema = reviseTaskSchema(TaskFormSchema, auth);

  const setTaskStatus = async (id, status) => {
    // console.log("set task status", status);
    const task = tasks.find((t) => t._id === id);
    task.status = status;
    forceUpdate();

    //update task status to server
    try {
      await api.updateById({
        apiEntity: "task",
        id,
        values: { status },
      });
      notifyFormSuccess({ title: "Task Status Updated" });
    } catch (e) {
      console.error(e);
      notifyFormError({ title: "Task Status Update Failed" });
    }
  };

  const toDoTasks = tasks.filter((t) => t.status === "toDo");
  const doneTasks = tasks.filter((t) => t.status === "done");

  const fetchTask = async () => {
    try {
      if (!auth() || !auth().id) return;
      const result = await api.search({
        apiEntity: "task",
        pageSize: 500,
        preQuery: { user: auth().id },
        byAggregation: false,
      });
      // console.log("result", result);
      if (result.docs) {
        setTasks(result.docs);
      }

      // setTasks(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchTask();
  }, []);

  return (
    <Tabs defaultValue={"toDo"}>
      <Tabs.List>
        <Tabs.Tab label="To Do" value="toDo">
          <Text size="xs">{t("To Do")} </Text>
        </Tabs.Tab>

        <Tabs.Tab label="done" value="done">
          <Text size="xs"> {t("Completed")} </Text>
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="toDo" pt="md">
        <Stack justify="space-between" h={320}>
          <ScrollArea h={300}>
            {_.isEmpty(toDoTasks) && (
              <Text size="xs">{t("No Task Found")}</Text>
            )}
            {toDoTasks.map((task) => (
              <Group align="center" mb={"sm"}>
                <Checkbox
                  key={task._id}
                  checked={task.status === "done"}
                  onChange={(e) => {
                    setTaskStatus(task._id, e.target.checked ? "done" : "toDo");
                  }}
                />
                <Text size="xs"> {task.title}</Text>
              </Group>
            ))}
          </ScrollArea>
          <AddNewButton
            formSchema={taskNewSchema}
            onSubmitSuccess={() => fetchTask()}
          />
        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="done" pt="md">
        {_.isEmpty(doneTasks) && <Text>{t("No Task Found")}</Text>}
        <ScrollArea h={300}>
          {doneTasks.map((task) => (
            <Checkbox
              label={task.title}
              key={task._id}
              td={"line-through"}
              checked={task.status === "done"}
              onChange={(e) => {
                setTaskStatus(task._id, e.target.checked ? "done" : "toDo");
              }}
              mb={"sm"}
            />
          ))}
        </ScrollArea>
      </Tabs.Panel>
    </Tabs>
  );
};
export default MyTaskList;
