import React, { useEffect, useState } from "react";
import {
  Container,
  Text,
  Title,
  Box,
  useMantineTheme,
  useComputedColorScheme,
  Group,
  Center,
  Select,
  Button,
} from "@mantine/core";

import { ResponsiveCalendar, Calendar } from "@nivo/calendar";

import _ from "lodash";
import { FormSectionCard } from "../../layout/page";
import { useServerApi } from "../../hooks/useServerApi";
import { calendarData } from "../../data/sampleChartData";
import JsonViewer from "../common/jsonViewer";
import { PeriodFilter } from "../periodFilter";

// const YearSelect = (forward = 1, backward = 1, onChange) => {
//   const [years, setYears] = useState([]);

//   const handleChange = (e) => {
//     onChange(e.target.value);
//   };
//   return (
//     <Select
//       onChange={handleChange}
//       defaultValue={new Date().getFullYear()}
//       data={years}
//     />
//   );
// };

const CalendarChart = ({
  title,
  height = 250,
  description,
  chartProps = {},
  staticData = [],
  dataAggreation,
  apiEntity,
  useSampleData = false,
  onRefresh,
  onPeriodChange,
  showPeriod = 3,
  margin = { top: 0, right: 0, bottom: 0, left: 0 },
  p = "md",
}) => {
  const computedColorScheme = useComputedColorScheme("dark");
  const theme = useMantineTheme();
  const [api] = useServerApi();
  const [data, setData] = useState({});

  const fetchData = async () => {
    try {
      if (useSampleData) {
        return setData(calendarData);
      }

      if (staticData) return setData(staticData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [staticData]);

  const chartTheme = {
    textColor:
      computedColorScheme === "dark"
        ? theme.colors.gray[1]
        : theme.colors.dark[8],
    grid: {
      line: {
        stroke:
          computedColorScheme === "dark"
            ? theme.colors.gray[8]
            : theme.colors.gray[1],
      },
    },
  };

  const defaultChartProps = {
    align: "top-left",
    from: "2024-01-01",
    to: "2024-12-31",
    emptyColor: "#eeeeee",
    colors: ["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"],
    margin: { top: 40, right: 40, bottom: 40, left: 40 },
    yearSpacing: 40,
    dayBorderWidth: 1,
    //   dayBorderColor: "#ffffff",
    //monthBorderColor: "#ffffff",

    dayBorderColor: theme.colorScheme === "dark" ? "black" : "white",
    monthBorderColor: theme.colorScheme === "dark" ? "black" : "white",

    legends: [
      {
        anchor: "bottom-right",
        direction: "row",
        translateY: 36,
        itemCount: 4,
        itemWidth: 42,
        itemHeight: 36,
        itemsSpacing: 14,
        itemDirection: "right-to-left",
      },
    ],
  };

  return (
    <>
      {/* <JsonViewer src={staticData} /> */}

      <FormSectionCard title={title} h={height} w={"100%"} p={p}>
        <Text size={"xs"} color="dimmed">
          {description}
        </Text>

        <ResponsiveCalendar
          data={data ?? []}
          theme={chartTheme}
          margin={margin}
          // tooltip={(n) => {}}
          {..._.merge(defaultChartProps, chartProps)}
          tooltip={({ day, value, color }) => (
            <Center w={200} h={25} bg={"#aaa"}>
              <Text>
                {day}: {value}
              </Text>
            </Center>
          )}
          legends={[
            {
              anchor: "bottom-left",
              direction: "row",
              translateY: -50,
              translateX: -10,
              itemCount: 4,
              itemWidth: 42,
              itemHeight: 36,
              itemsSpacing: 14,
              itemDirection: "right-to-left",
            },
          ]}
        />
      </FormSectionCard>
    </>
  );
};

export default CalendarChart;
