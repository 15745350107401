import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil, IconTrash, IconCopy } from "@tabler/icons-react";

export const ProjectQuotationListTableSchema = (cellRender, { projectId }) => {
  return {
    preQuery: { project: projectId },
    showActionColumn: true,
    columns: [
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (col, data) => cellRender.Status(data.status),
      },
      {
        field: "code",
        headerName: "Code",
        sortable: true,
      },
      {
        field: "quoDate",
        headerName: "Quo Date",
        sortable: true,
        cellRender: (col, data) => cellRender.Date(data.quoDate, "ll"),
      },
      {
        field: "total",
        headerName: "Total",
        sortable: true,
        cellRender: (col, data) => cellRender.Currency(data.total),
      },

      // {
      //   field: "createdBy",
      //   headerName: "Created By",
      //   sortable: true,
      // },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "openEdit",
            linkPath: "quotation",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          {
            name: "copy",
            label: "Copy",
            icon: IconCopy,
            props: {
              // color: "red",
            },
          },
          {
            name: "delete",
            label: "Delete",
            icon: IconTrash,
            props: {
              color: "red",
            },
          },
        ],
      },
    ],
  };
};
