import { TextInput, Select, Textarea, NumberInput } from "@mantine/core";
import AutoCodeInput from "../../components/autoCodeInput";
import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../../components/pageList";
import remoteSelect2 from "../../components/remoteSelect2";
import { DATA_UNIT_OF_MEASURE } from "../../data/options";
import ActivityLog from "../../components/activityLog";
import FileList from "../../components/fileList";
import ItemBasicInfo from "../../components/itemBasicInfo";
import ItemInventory from "../../components/itemInventory";
import CalendarChart from "../../components/chartWidget/CalendarChart";
import ItemTransactionHistory from "../../components/itemTransactionHistory";
import ItemReservationCaldendar from "../../components/itemReservationCalendar";
import ItemOptions from "../../components/itemOptions";
const apiEntity = "item";
const initialValues = {
  dimensions: {
    width: 0,
    depth: 0,
    height: 0,
  },
};

export const miscItemAddForm = {
  name: "addMiscSchema",
  title: "Misc Item",
  display: {
    mode: ENUM_FORM_DISPLAY.MODAL,
  },
  apiEntity,
  initialValues,
  layout: {
    fields: [
      {
        name: "name",
        component: TextInput,
        props: {
          required: true,
          label: "Name",
          placeholder: "",
        },
      },
    ],
  },
};

export const addForm = {
  name: "addSchema",
  title: "Item",
  titleField: "name",
  description: "Please follow the step to create a New Item",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
  },
  apiEntity,
  initialValues,
  layout: {
    containers: [
      {
        key: "section-setting-cat",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Item Category",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 20,
          mb: 20,
        },
      },
      {
        key: "section-setting-basic",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Basic Info",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-setting-dimension",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Dimension",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 20,
          mb: 20,
        },
      },
    ],
    fields: [
      {
        name: "category",
        component: remoteSelect2,
        parent: "section-setting-cat",

        props: {
          label: "Item Category",
          apiEntity: "itemCategory",
          required: true,
          valueField: "_id",
          labelField: "name",
          paeSize: 1000,
          searchFields: ["name"],
          onDataChange: (data, form) => {
            //Set checkList
            form.setFieldValue("category", data);
            if (!data || !data.options)
              return form.setFieldValue("options", []);

            form.setFieldValue(
              "options",
              data?.options.map((c) => ({ name: c.name, value: null }))
            );
          },
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },

      {
        name: "options",
        component: ItemOptions,
        parent: "section-setting-cat",

        props: {
          // required: true,
          label: "Options",
          placeholder: "",
          mb: "xl",
        },
      },

      {
        name: "code",
        parent: "section-setting-basic",
        component: TextInput,
        props: {
          required: true,
          label: "Code",
          placeholder: "",
        },
      },

      {
        name: "name",
        component: TextInput,
        parent: "section-setting-basic",

        props: {
          required: true,
          label: "English Name",
          placeholder: "",
        },
      },
      {
        name: "cname",
        component: TextInput,
        parent: "section-setting-basic",

        props: {
          // required: true,
          label: "Chinese Name  ",
          placeholder: "",
        },
      },
      {
        name: "unitsOfMeasure",
        component: Select,
        parent: "section-setting-dimension",

        props: {
          required: true,
          label: "Unit of Measure",
          placeholder: "",
          data: DATA_UNIT_OF_MEASURE,
          searchable: true,
          clearable: true,
        },
      },

      {
        name: "dailyCost",
        component: NumberInput,
        parent: "section-setting-basic",

        props: {
          label: "Daily Use Cost",
        },
      },
      {
        name: "singleCost",
        component: NumberInput,
        parent: "section-setting-basic",

        props: {
          label: "Single Use Cost",
        },
      },
      {
        name: "dimensions.width",
        component: NumberInput,
        parent: "section-setting-dimension",

        props: {
          label: "Width",
        },
      },
      {
        name: "dimensions.depth",
        component: NumberInput,
        parent: "section-setting-dimension",

        props: {
          label: "Depth",
        },
      },
      {
        name: "dimensions.height",
        component: NumberInput,
        parent: "section-setting-dimension",

        props: {
          label: "Height",
        },
      },

      {
        name: "description",
        component: Textarea,
        parent: "section-setting-basic",

        props: {
          // required: true,
          label: "Description",
          placeholder: "",
        },
      },
    ],
  },
};

export const editForm = {
  name: "editSchema",
  title: "Item",
  titleField: "name",
  description: "Please follow the step to create a New Item",
  display: {
    mode: ENUM_FORM_DISPLAY.LINK,
  },
  apiEntity,
  initialValues,
  layout: {
    containers: [
      {
        key: "main-tab",
        parent: null,
        type: ENUM_FORM_LAYOUT_CONTAINER.TABS,
        props: {
          variant: "default", //default | pills | outline
          defaultValue: "Overview",
        },
        tab: [
          {
            key: "tab-overview",
            label: "Overview",
          },

          {
            key: "tab-transcation",
            label: "Transaction",
          },

          {
            key: "tab-setting",
            label: "Setting",
          },

          {
            key: "tab-files",
            label: "Files",
          },
          {
            key: "tab-logs",
            label: "Logs",
          },
        ],
      },
      {
        key: "section-basic",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Item Info",
          description: "Please fill in the basic information of user",
          withBorder: false,

          mt: 20,
        },
      },
      {
        key: "section-inventory",
        parent: "tab-overview",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Inventory",
          description: "Please fill in the basic information of user",
          withBorder: false,

          mt: 20,
        },
      },

      {
        key: "section-transaction",
        parent: "tab-transcation",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Transcation Records",
          description: "Please fill in the basic information of user",
          withBorder: false,

          mt: 20,
        },
      },

      {
        key: "section-files",
        parent: "tab-files",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Files",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          minHeight: "0px",
          mt: 20,
          mb: 20,
        },
      },
      {
        key: "section-log",
        parent: "tab-logs",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Logs",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 20,
          mb: 20,
        },
      },
      {
        key: "section-setting-cat",
        parent: "tab-setting",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Item Category",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 20,
          mb: 20,
        },
      },
      {
        key: "section-setting-basic",
        parent: "tab-setting",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Setting Basic",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 20,
          mb: 20,
        },
      },

      {
        key: "section-setting-dimension",
        parent: "tab-setting",
        type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,
        props: {
          title: "Dimension",
          titleOrder: 5,
          // description: "Please assign the corresponding consultant",
          withBorder: false,
          mt: 20,
          mb: 20,
        },
      },
    ],
    fields: [
      {
        name: "overview",
        component: ItemBasicInfo,
        parent: "section-basic",
        props: {},
      },
      {
        name: "inventory",
        component: ItemInventory,
        parent: "section-inventory",
        props: {},
      },
      {
        name: "inventory",
        component: ItemReservationCaldendar,
        parent: "tab-overview",
        props: {
          title: "Item Reservation",
          height: "35vh",
          p: 0,
          apiEntity: "itemReservation",
          chartProps: {
            direction: "horizontal",
            monthSpacing: 25,
          },
        },
      },

      {
        name: "transaction",
        component: ItemTransactionHistory,
        parent: "section-transaction",
        props: {},
      },

      {
        name: "files",
        component: FileList,
        parent: "section-files",
        props: {},
      },
      {
        name: "log",
        component: ActivityLog,
        parent: "section-log",
        props: {},
      },

      {
        name: "code",
        parent: "section-setting-basic",
        component: TextInput,
        props: {
          required: true,
          label: "Code",
          placeholder: "",
        },
      },

      {
        name: "category",
        component: remoteSelect2,
        parent: "section-setting-cat",

        props: {
          label: "Item Category",
          apiEntity: "itemCategory",
          required: true,
          valueField: "_id",
          labelField: "name",
          searchFields: ["name"],
          paeSize: 1000,

          onDataChange: (data, form) => {
            //Set checkList
            form.setFieldValue("category", data);
            if (!data || !data.options)
              return form.setFieldValue("options", []);

            form.setFieldValue(
              "options",
              data?.options.map((c) => ({ name: c.name, value: null }))
            );
          },
          sort: {
            by: "name",
            order: "asc",
          },
        },
      },
      {
        name: "options",
        component: ItemOptions,
        parent: "section-setting-cat",

        props: {
          // required: true,
          label: "Options",
          placeholder: "",
          mb: "xl",
        },
      },
      {
        name: "name",
        component: TextInput,
        parent: "section-setting-basic",

        props: {
          required: true,
          label: "English Name",
          placeholder: "",
        },
      },
      {
        name: "cname",
        component: TextInput,
        parent: "section-setting-basic",

        props: {
          // required: true,
          label: "Chinese Name  ",
          placeholder: "",
        },
      },
      {
        name: "unitsOfMeasure",
        component: Select,
        parent: "section-setting-dimension",

        props: {
          required: true,
          label: "Unit of Measure",
          placeholder: "",
          data: DATA_UNIT_OF_MEASURE,
          searchable: true,
          clearable: true,
        },
      },

      {
        name: "dailyCost",
        component: NumberInput,
        parent: "section-setting-basic",

        props: {
          label: "Daily Use Cost",
        },
      },
      {
        name: "singleCost",
        component: NumberInput,
        parent: "section-setting-basic",

        props: {
          label: "Single Use Cost",
        },
      },
      {
        name: "dimensions.width",
        component: NumberInput,
        parent: "section-setting-dimension",

        props: {
          label: "Width",
        },
      },
      {
        name: "dimensions.depth",
        component: NumberInput,
        parent: "section-setting-dimension",

        props: {
          label: "Depth",
        },
      },
      {
        name: "dimensions.height",
        component: NumberInput,
        parent: "section-setting-dimension",

        props: {
          label: "Height",
        },
      },

      {
        name: "description",
        component: Textarea,
        parent: "section-setting-basic",

        props: {
          // required: true,
          label: "Description",
          placeholder: "",
        },
      },
    ],
  },
};

export const formSchema = {
  add: addForm,
  edit: editForm,
};

// export const formSchema = editForm;
