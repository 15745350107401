import React, { useState, useCallback, useEffect } from "react";
import { createStyles, Avatar, Text, Group, Button } from "@mantine/core";

import ClientUpdateActionButton from "./clientUpdateActionButton";
import ClientChangeServiceRightButton from "./clientChangeServiceRightButton";
import ClientActiveEnableButton from "./clientActiveEnableButton";

import NewTicketButton from "./newTicketButton";
import { useAuthUser } from "react-auth-kit";
import _ from "lodash";
import { useServerApi } from "../hooks/useServerApi";

const ClientActionList = ({ form, formAction, ...props }) => {
  const { status, code, saleman, _id } = form.values;
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [api] = useServerApi();

  return (
    <Group mt={10}>
      {<ClientUpdateActionButton mainForm={form} />}
      {<ClientActiveEnableButton mainForm={form} />}
    </Group>
  );
};

export default ClientActionList;
