import React, { useState } from "react";
import { Badge, Popover, Select } from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import { useEffect } from "react";
import { showNotification } from "@mantine/notifications";
import { useServerApi } from "../hooks/useServerApi";

const ProjectTableStatus = ({
  status,
  id,
  formValue,
  size = "xs",
  radius = 0,
}) => {
  const [v, setV] = useState(status);

  const [api] = useServerApi();

  const getStatusColor = (v) => {
    switch (v) {
      case "DFT":
        return "yellow";
      case "COMPLETED":
        return "blue";
      case "CONFIRMED":
        return "green";

      case "CANCEL":
        return "red";
      default:
        return "cyan";
    }
  };

  const handleChange = async (value) => {
    try {
      console.log(value, id);
      setV(value);
      const result = await api.updateById({
        apiEntity: "project",
        id,
        values: {
          status: value,
        },
      });
      console.log(result);
      showNotification({
        title: `Status Updated Successfully`,
        message: "updated",
      });
    } catch (error) {
      showNotification({ message: error, color: "red" });
    }
  };

  return (
    <>
      <Popover width={200} position="bottom" withArrow shadow="md">
        <Popover.Target>
          <Badge
            color={getStatusColor(v)}
            size={size}
            radius={radius}
            style={{ cursor: "pointer" }}
          >
            {v}
          </Badge>
        </Popover.Target>
        <Popover.Dropdown>
          <Select
            size="xs"
            value={v}
            onChange={handleChange}
            allowDeselect={false}
            data={[
              { value: "DFT", label: "Draft" },
              { value: "COMPLETED", label: "Completed" },
              { value: "CONFIRM", label: "Confirm" },
              { value: "CANCEL", label: "Cancelled" },
            ]}
          />
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

export default ProjectTableStatus;
