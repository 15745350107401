import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { AddActionButton, PageList } from "./pageList";
import { useTableSchema } from "../hooks/useTableSchema";
import { useFormSchema } from "../hooks/useFormSchema";
import JsonViewer from "./common/jsonViewer";
import DataTable from "./dataTable";
import { useServerApi } from "../hooks/useServerApi";
import AddNewButton from "./addNewButton";
import { useFormRender } from "../hooks/useFormRender";
import RelatedDataTable from "./relatedDataTable";

const ProjectClaimList = ({ form: mainForm, ...props }) => {
  const [api] = useServerApi();
  const [data, setData] = useState([]);
  const [getFormSchema] = useFormSchema();
  const [getTableSchema] = useTableSchema();

  //Form
  const projectId = _.get(mainForm.values, "_id");

  const formSchema = {
    ...getFormSchema("claim"),
    initialValues: {
      project: projectId,
      amount: 0,
      claimType: "",
    },
  };

  //Table
  const tableSchema = {
    ...getTableSchema("ProjectClaimList", {
      projectId,
    }),
  };

  return (
    <>
      <RelatedDataTable
        tableSchema={tableSchema}
        formSchema={formSchema}
        mainForm={mainForm}
        apiEntity={"claim"}
        fieldIn="_id"
        fieldOut="project"
      />
    </>
  );
};

export default ProjectClaimList;
