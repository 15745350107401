import React, { useRef } from "react";
import { useForceUpdate } from "@mantine/hooks";
import RemoteSelect2 from "./remoteSelect2";
import { Group, NumberInput, TextInput, Select } from "@mantine/core";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";

const GoogleMemberRow = ({ item, index, form, name }) => {
  const forceUpdate = useForceUpdate();
  const remoteRef = useRef(null);

  useDeepCompareEffect(() => {
    // console.log("item changed", item);
    forceUpdate();
    remoteRef.current?.refresh();
  }, [item]);

  return (
    <>
      {/* <JsonViewer src={item} /> */}
      <Group grow>
        {/* <Text size="md"> {item.content["category"]}</Text> */}
        {/* item : {item.content["_id"]} */}
        <RemoteSelect2
          ref={remoteRef}
          apiEntity="user"
          labelField="name"
          valueField="_id"
          labelRender={(data) => `${data.aliasName} [ ${data.name} ]`}
          multiple={false}
          searchFields={["name", "code", "cname"]}
          placeholder="Staff"
          clearable={true}
          value={item.content["user"]}
          onDataChange={(data) => {
            // console.log("data", data);
            item.content["user"] = data ? data._id : null;
            // item.content["gmail"] = null;
            forceUpdate();
          }}
          sort={{
            by: "name",
            order: "asc",
          }}
        />
        <TextInput
          {...form.getInputProps(`${name}.${index}.email`)}
          placeholder="Gmail Account"
          width={300}
        />
        <Select
          value={item.content["role"]}
          onChange={(d) => {
            item.content["role"] = d;
            forceUpdate();
          }}
          placeholder="Access Right"
          data={[
            { value: "read", label: "Read" },
            { value: "write", label: "Write" },
          ]}
        />
      </Group>
    </>
  );
};

export default GoogleMemberRow;
