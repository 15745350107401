import moment from "moment";
import ActionCellRender from "../../components/actionCellRender";
import { IconPencil } from "@tabler/icons-react";

/**
 * @function UserTableSchema
 * @description Get the user page table schema
 * @param {*} cellRender
 * @returns
 */
export const UserMainTableSchema = (cellRender) => {
  return {
    showActionColumn: true,
    initSort: { by: "code", order: "desc" },
    // searchByTextField: true,

    columns: [
      {
        field: "name",
        headerName: "Name",
        sortable: true,
        cellRender: (column, data) => cellRender.User(data),
      },
      {
        field: "staffId",
        headerName: "Staff ID",
        sortable: true,
      },

      {
        field: "email",
        headerName: "Email",
        sortable: true,
      },

      {
        field: "userRole",
        headerName: "User Role",
        sortable: true,
        cellRender: (column, data) => data.userRole?.name,
      },
      {
        field: "employmentDate",
        headerName: "Join Date",
        sortable: true,
        cellRender: (column, data) =>
          moment(data.employmentDate).format("YYYY-MM-DD"),
      },

      {
        field: "status",
        headerName: "Status",
        sortable: true,
        cellRender: (column, data) => cellRender.Status(data.status),
      },

      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [
          {
            name: "edit",
            icon: IconPencil,
            props: {
              //   color:"red"
            },
          },
        ],
        actionMenu: [
          // {
          // 	name: "delete",
          // 	label: "Delete",
          // 	icon: IconTrash,
          // 	props: {
          // 		color: "red",
          // 	},
          // },
        ],
      },
    ],

    searchableFields: [
      "code",
      "name",
      "userType",
      "cname",
      "email",
      "phone",
      "staffId",
      "status",
      "aliasName",
    ],
  };
};
