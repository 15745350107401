import { useState, useEffect } from "react";
import {
  Drawer,
  Modal,
  Skeleton,
  Grid,
  Tabs,
  Button,
  Group,
  Space,
  Box,
  Text,
  TextInput,
  Collapse,
  Divider,
  ScrollArea,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useForm } from "@mantine/form";
import { DatePicker } from "@mantine/dates";
import { useForceUpdate, useHotkeys } from "@mantine/hooks";
import {
  IconCheck,
  IconSettings,
  IconExclamationMark,
  IconMessageCircle,
  IconCoin,
} from "@tabler/icons-react";

import { useServerApi } from "./useServerApi";
import FormSection from "../components/formSection";
import FormTab from "../components/formTab";
import FormWizard from "../components/formWizard";
import {
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "../components/pageList";
import useDeepCompareEffect from "./useDeepCompareEffect";
import { useNavigate, useLocation } from "react-router-dom";
import { useCellRender } from "./useCellRender";
import { useTranslation } from "react-i18next";
import JsonViewer from "../components/common/jsonViewer";
import { useAuthUser } from "react-auth-kit";
import _ from "lodash";
const K_SECTION_MIN_HEIGHT = "350px";
const K_CONFIRM_DIRTY_EXIT =
  "Are you sure to close the form? There are unsaved changes.";
const emptySchema = {
  display: {
    mode: 0,
  },
};
/**
 * @descirption: Hook functions useFormRender
 * @Params: [
 *
 *  formSchema:         JSON base schema to describe and build the form
 *  onSumitSuccess:     Function to call when form submit successfully
 *  onSubmit:           Custom submit function instead of using API
 *
 * ]
 *
 */

export const useFormRender = (
  formSchema = emptySchema,
  onSubmitSuccess,
  onSubmit,
  onChange
) => {
  const [formOpened, setFormOpened] = useState(false);
  const [formMode, setformMode] = useState("add");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [api] = useServerApi();
  const navigate = useNavigate();
  const [currentFormSchema, setCurrentFormSchema] = useState({});
  const location = useLocation();
  const forceUpdate = useForceUpdate();
  const query = new URLSearchParams(useLocation().search);
  const linkId = query.get("id");
  const linkMode = query.get("mode");
  const linkPath = useLocation().pathname;
  const { t } = useTranslation();

  const auth = useAuthUser();
  /**
   * HotKeys Setting
   */
  useHotkeys([
    // ["ctrl+S", () => saveForm()],
    // ["mod+S", () => saveForm()],
  ]);

  /**
   * Definition of possible Form Action which will be accesible through hook
   */
  const formAction = {
    open: (o) => openForm(o),
    close: () => closeForm(),
    save: async () => await saveForm(),
    reset: () => form.reset(),
    load: (data) => loadData(data),
    setFieldValue: (field, value) => setFieldValue(field, value),
    updateAndSave: (updates, showNotification) =>
      updateAndSave(updates, showNotification),
    getTitle: () => getFormTitle(),
    getValues: () => form.values,
    clear: () => clearForm(),
    setMode: (mode) => setformMode(mode),
    getCurrentSchema: () => currentFormSchema,
    reload: () => loadFormData(id),
    setInitialValues: (data) => setInitialValues(data),
  };

  /**
   * Description: Get the current form schema. Schema define the layout and fields
   * of the form. Form definition may be same for edit mode and add mode. If form structure
   * are different for modes, further definition of schema fields (add , edit) need.
   * @returns
   */
  const getCurrentFormSchema = () =>
    (formMode === "add" ? formSchema.add : formSchema.edit) ?? formSchema;

  const getCurrentFormSchemByMode = (mode) =>
    (mode === "add" ? formSchema.add : formSchema.edit) ?? formSchema;

  /**
   * display:     the definition of display ways of forms (ENUM_FORM_DISPLAY)
   * showSaveBar: whether to show the save and close menu bar on the top of the form
   */
  const {
    display,
    showSaveBar = true,
    showSaveButton = false,
    saveButtonText = "Save",
    apiEntity,
    saveWithNotification = true,
    name: schemaName,
    initialValues,
    initialErrors,
  } = getCurrentFormSchema();

  /**
   * definition of the form object by using the hook useForm from mantine
   */
  // console.log("formMode", formMode, schemaName, initialValues);
  const form = useForm({
    initialValues: getCurrentFormSchema().initialValues,
    initialErrors: getCurrentFormSchema().initialErrors,
    validate: getCurrentFormSchema().validate,
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (!form || !onChange) return;
    onChange(form.values);
  }, [form.values]);

  useEffect(() => {
    const schema = formSchema.edit ?? formSchema;
    // console.log(linkId, linkMode, schema);
    if (!schema || !schema.display) return;
    if (schema.display.mode !== ENUM_FORM_DISPLAY.LINK) return;
    setId(linkId);
    loadFormData(linkId);
    if (linkId) openForm({ mode: linkMode, id: linkId });
  }, [linkId, linkMode]);

  useDeepCompareEffect(() => {
    // console.log("Form Schema Changed", formSchema);
    forceUpdate();
    // form.setInitialValues(getCurrentFormSchema().initialValues);
  }, [formSchema]);

  /**
   * Form Action Functions
   *
   */
  const saveForm = async () => {
    try {
      if (onSubmit) {
        return saveWithCustom();
      }
      return await save();
    } catch (error) {
      console.log(error);
    }
  };

  const saveWithCustom = () => {
    try {
      const values = form.values;
      onSubmit({ values, formMode });

      if (onSubmitSuccess) {
        // console.log("Save with Custom 2", formMode);
        onSubmitSuccess({ values });
        if (formSchema.closeAfterSave) closeForm();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const save = async () => {
    try {
      if (formMode === "show") return;
      if (formMode === "add") {
        await saveNew();
      }

      if (formMode === "edit") {
        await saveEdit();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveNew = async () => {
    try {
      // console.log("Save New=> Mode: ", formMode);
      if (!saveWithNotification) return;

      //API Submit
      setLoading(true);
      if (!apiEntity) return;
      let values = form.values;
      // console.log("Save New, values=", values);
      const result = await api.add({ apiEntity, values });
      // console.log("Save  New", result);

      setLoading(false);
      //Handle Fail Add
      if (!result.success) {
        return notifySaveFail(result.error);
      }

      notifySaveOk(`${values.name ?? values.code ?? "Data"} add successfully`);
      handleAddSuccess(result);
    } catch (error) {
      console.log(error);
    }
  };

  const notifySaveFail = (error) => {
    try {
      console.log(error);
      return showNotification({
        color: "red",
        icon: <IconExclamationMark size={18} />,
        title: "Save Fail",
        message: api.getErrorMsg(error),
        // message: _.isString(error) ? error : error?.message ?? error?.msg,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const notifySaveOk = (title) => {
    try {
      if (!saveWithNotification) return;
      return showNotification({
        title,
        // icon: <IconCheck size={18} />,
        message: "Data Saved",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddSuccess = (result) => {
    // console.log("handleAddSuccess", result);
    try {
      setformMode("edit");
      setId(result.data._id);
      loadFormData(result.data._id);

      if (formSchema.closeAfterSave) closeForm();

      if (onSubmitSuccess) onSubmitSuccess({ values: result.data });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditSuccess = (result) => {
    if (formSchema.closeAfterSave) closeForm();
    if (onSubmitSuccess) return onSubmitSuccess({ values: form.values });
  };

  /**
   * Description : Save to server with the form values
   */
  const saveEdit = async () => {
    await saveValues(form.values);
  };

  /**
   * @description Save to server with the bypass values
   * @param {*} values
   * @returns
   */
  const saveValues = async (values, showNotification = true) => {
    console.log("saveValues", values, apiEntity, id);
    if (!apiEntity || !id) return;

    setLoading(true);
    let result = await api.updateById({
      apiEntity,
      values: values ?? form.values,
      id,
    });
    setLoading(false);
    if (!result.success) {
      // console.log(result);
      return notifySaveFail(result.error);
    }

    if (showNotification)
      notifySaveOk(`${values.name ?? values.code ?? "Data"} Updated`);
    return handleEditSuccess(result);
  };

  /**
   * @description update the form value and save to db directly
   * @param {*} field
   * @param {*} value
   */
  const updateAndSave = async (updates, showNotification = true) => {
    // console.log("updateAndSave", updates);
    for (const [field, value] of Object.entries(updates)) {
      form.setFieldValue(field, value);
    }
    const v = { ...form.values, ...updates };
    // console.log("updateAndSave v", v);
    await saveValues(v, showNotification);
  };

  const clearForm = () => {
    form.reset();
    setformMode(null);
  };

  const loadData = (data) => {
    return form.setValues(data);
  };

  const setInitialValues = (data) => {
    return form.setInitialValues(data);
  };

  const setFieldValue = (field, value) => {
    return form.setFieldValue(field, value);
  };

  const openForm = ({ mode, id, data }) => {
    try {
      clearForm();
      setformMode(mode);
      setFormOpened(true);
      const schema = getCurrentFormSchemByMode(mode);

      // console.log("OpenFOrm", mode, id, data);
      if (schema?.display?.mode === ENUM_FORM_DISPLAY.LINK) {
        const link = `${location.pathname}?id=${id}&mode=${mode}`;
        return navigate(link);
      }

      if (data) {
        // console.log("openForm", data);
        if (id) setId(id);
        return form.setValues(data);
      }

      if (id) {
        setId(id);
        loadFormData(id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeForm = () => {
    // console.log("Close Form");
    try {
      // if (
      //   formMode === "add" &&
      //   form.isDirty() &&
      //   !window.confirm(K_CONFIRM_DIRTY_EXIT)
      // )
      //   return;

      setFormOpened(false);
      clearForm();
      if (formSetting.displayMode === ENUM_FORM_DISPLAY.LINK) {
        navigate(linkPath);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadFormData = async (id) => {
    try {
      // console.log("loadFormData", id, getCurrentFormSchema().apiEntity);
      if (!id) return;
      let data = await api.getById({
        apiEntity: getCurrentFormSchema().apiEntity,
        id,
      });
      form.setValues(data);
      // console.log("loadFormData", data);
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseForm = () => {
    // if (
    //   formMode === "add" &&
    //   form.isDirty() &&
    //   !window.confirm(K_CONFIRM_DIRTY_EXIT)
    // )
    //   return;

    setFormOpened(false);
    form.reset();
  };

  const getFormTitle = () => {
    try {
      const { title, titleField } = getCurrentFormSchema();
      if (formMode === "add") return t(title);

      let str = "";
      if (title) str += t(title);
      if (title && titleField) str += " > ";
      if (titleField) str += _.get(form.values, titleField) ?? "";

      return str;
    } catch (error) {
      console.log(error);
    }
  };

  const getChildContainers = (key) =>
    getCurrentFormSchema().layout.containers?.filter((c) => c.parent === key);
  const getChildFields = (key) =>
    getCurrentFormSchema().layout.fields?.filter((c) => c.parent === key);

  const attachFormAction = () => {
    form.action = formAction;
    form.save = () => saveForm();
    form.saveValues = (values) => saveValues(values);
    form.open = (o) => openForm(o);
    form.setInitialValues = (data) => setInitialValues(data);
    // form.updateAndSave = (field, value) => updateAndSave(field, value);
    form.updateAndSave = (updates, showNotification) =>
      updateAndSave(updates, showNotification);
    form.close = () => closeForm();
    form.getTitle = () => getFormTitle();
    form.clear = () => clearForm();
    form.reload = () => loadFormData(id);
  };

  const renderField = (field) => {
    try {
      let value = _.get(form.values, field.name);
      if (
        (field.type === "datePicker" || field.type === "dateInput") &&
        value
      ) {
        value = new Date(value);
      }

      return (
        <span key={field.name}>
          <field.component
            disabled={formMode === "show" ? true : false}
            variant={formMode === "show" ? "unstyled" : "default"}
            style={field.style}
            key={field.name}
            name={field.name}
            {...field.props}
            {...form.getInputProps(field.name, {
              type: field?.props?.type,
            })}
            value={value}
            label={t(field.props?.label)}
            form={form} //For custom component
            formAction={formAction}
            formMode={formMode}
          />
          <Space h="md" />
        </span>
      );
    } catch (error) {
      console.log(error);
    }
  };

  const renderFields = (fields) => fields?.map((f) => renderField(f));

  const renderContainerSection = (section) => {
    try {
      return (
        <FormSection
          key={section.key}
          mt={section.props?.mt || "xl"}
          style={{ minHeight: K_SECTION_MIN_HEIGHT }}
          {...section.props}
        >
          {getChildContainers(section.key).map((c) => renderContainer(c))}
          {getChildFields(section.key).map((f) => renderField(f))}
        </FormSection>
      );
    } catch (error) {
      console.log(error);
    }
  };

  const renderContainerTabs = (tabs) => {
    try {
      return (
        <FormTab
          key={tabs.key}
          tabs={tabs}
          getChildContainers={getChildContainers}
          getChildFields={getChildFields}
          renderContainer={renderContainer}
          renderField={renderField}
        ></FormTab>
      );
    } catch (error) {
      console.log(error);
    }
  };
  const renderContainerWizard = (wizard, form) => {
    try {
      return (
        <FormWizard
          key={wizard.key}
          wizard={wizard}
          getChildContainers={getChildContainers}
          getChildFields={getChildFields}
          renderContainer={renderContainer}
          renderField={renderField}
          form={form}
          confirmSubmit={async () => {
            await saveForm();
            // closeForm();
          }}
        ></FormWizard>
      );
    } catch (error) {
      console.log(error);
    }
  };

  const renderContainerGrid = (container) => {
    try {
      return (
        <Grid key={container.key} {...container.props} gutter="xl">
          {container.cols.map(({ props, key }) => (
            <Grid.Col {...props} key={key}>
              {getChildContainers(key).map((c) => renderContainer(c))}
              {getChildFields(key).map((f) => renderField(f))}
            </Grid.Col>
          ))}
        </Grid>
      );
    } catch (error) {
      console.log(error);
    }
  };

  const renderContainerBox = (container) => {
    try {
      return (
        <Box key={container.key} {...container.props}>
          <>
            {getChildContainers(container.key).map((c) => renderContainer(c))}
            {getChildFields(container.key).map((f) => renderField(f))}
          </>
        </Box>
      );
    } catch (error) {
      console.log(error);
    }
  };

  const renderContainerEmpty = (container) => {
    try {
      return (
        <>
          {getChildContainers(container.key).map((c) => renderContainer(c))}
          {getChildFields(container.key).map((f) => renderField(f))}
        </>
      );
    } catch (error) {
      console.log(error);
    }
  };

  const renderContainer = (container) => {
    try {
      if (container.visibleOnly) {
        // console.log("VisibleOnly", auth());
        if (!container.visibleOnly(form.values, auth().userRole)) return <></>;
      }

      switch (container.type) {
        case ENUM_FORM_LAYOUT_CONTAINER.EMPTY:
          return renderContainerEmpty(container);
        case ENUM_FORM_LAYOUT_CONTAINER.BOX:
          return renderContainerBox(container);
        case ENUM_FORM_LAYOUT_CONTAINER.GRID:
          return renderContainerGrid(container);
        case ENUM_FORM_LAYOUT_CONTAINER.TABS:
          return renderContainerTabs(container);
        case ENUM_FORM_LAYOUT_CONTAINER.WIZARD:
          return renderContainerWizard(container, form);
        case ENUM_FORM_LAYOUT_CONTAINER.SECTION:
          return renderContainerSection(container);
      }
      return <> No Container Type Defined</>;
    } catch (error) {
      console.log(error);
    }
  };

  const renderFormMain = () => {
    try {
      attachFormAction();
      const { layout, initialValues } = getCurrentFormSchema();
      if (!layout) return <>Form Layout Not Defined</>;

      // console.log("RenderFormMain", initialValues);
      // form.setInitialValues(initialValues);
      //If No containers defined, all fields render in a single column
      if (!layout.containers || layout.containers.length == 0)
        return renderFields(layout.fields);

      //Get root Container
      const rootContainers = layout.containers.filter((c) => c.parent === null);

      // return rootContainers.map( container => <div key={container.key}></div>)
      return rootContainers.map((container) => renderContainer(container));
    } catch (error) {
      console.log(error);
    }
  };

  const renderSaveBar = () => {
    return (
      <>
        <Group justify="space-between">
          <span></span>

          <Group>
            {formMode !== "show" && (
              <Button
                size="xs"
                variant="filled"
                onClick={saveForm}
                loading={loading}
              >
                Save
              </Button>
            )}
            <Button
              size="xs"
              variant="default"
              onClick={closeForm}
              disabled={loading}
            >
              Close
            </Button>
          </Group>
        </Group>
      </>
    );
  };

  const renderForm = () => {
    try {
      switch (display?.mode) {
        case ENUM_FORM_DISPLAY.INSIDE:
          return (
            <>
              {/* {<ReactJson src={form} style={{ background: "white" }} collapsed />} */}

              {formOpened && renderFormMain()}
              {showSaveButton && formMode !== "show" && (
                <Button size="xs" variant="filled" onClick={saveForm}>
                  {saveButtonText}
                </Button>
              )}
            </>
          );

        case ENUM_FORM_DISPLAY.LINK:
          return (
            <>
              {/* {
                <ReactJson
                  src={form.values}
                  style={{ background: "white" }}
                  collapsed
                />
              } */}

              {formOpened && renderFormMain()}
              {showSaveButton && formMode !== "show" && (
                <Button size="xs" variant="filled" onClick={saveForm}>
                  {saveButtonText}
                </Button>
              )}
            </>
          );

        case ENUM_FORM_DISPLAY.DRAWER:
          return (
            <Drawer
              opened={formOpened}
              onClose={onCloseForm}
              title={getFormTitle()}
              padding="xl"
              size={display.size}
              position={display.position}
            >
              {/* Drawer content */}
              {/* {formMode} - {id} */}
              {/* { JSON.stringify(form.values)} */}
              <ScrollArea>{renderFormMain()}</ScrollArea>

              {showSaveBar && renderSaveBar()}
            </Drawer>
          );

        case ENUM_FORM_DISPLAY.MODAL:
          return (
            <Modal
              opened={formOpened}
              onClose={onCloseForm}
              title={getFormTitle()}
              size={display.size ?? "xl"}
              // size="auto"
              closeOnClickOutside={false}
              closeOnEscape={false}
              h={display.height}
            >
              {/* Modal content */}
              {/* Mode: {{ formMode } - { id }}
              {JSON.stringify(form.values)} */}
              {/* <JsonViewer
                src={formSchema}
                name="UseFormRender>Modal>FormSchema"
              /> */}
              {renderFormMain()}
              {/* <Space h="md" /> */}
              {showSaveBar && renderSaveBar()}
            </Modal>
          );

        case ENUM_FORM_DISPLAY.FULLSCREEN:
          return (
            <Modal
              zIndex={999}
              opened={formOpened}
              onClose={() => setFormOpened(false)}
              title={getFormTitle()}
              fullScreen
            >
              {/* Modal content */}
              {/* {formMode} - {id} */}
              {showSaveBar && renderSaveBar()}
              {renderFormMain()}
            </Modal>
          );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formDisplay = display;

  const formStatus = {
    mode: formMode,
    isOpen: formOpened, //isDirty ....
  };

  const formSetting = {
    schema: getCurrentFormSchema(),
    displayMode: display?.mode,
  };

  return [renderForm, formAction, formStatus, formSetting, form];
  // return [ renderForm, formAction, formOpened, formMode , formDisplay ]
};
