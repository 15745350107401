import React, { useState, useEffect } from "react";
import {
  Text,
  TextInput,
  Kbd,
  Button,
  Flex,
  UnstyledButton,
} from "@mantine/core";
import {
  IconArrowBackUp,
  IconArrowUp,
  IconArrowUpBar,
  IconSearch,
  IconSectionSign,
  IconSeparatorVertical,
  IconSlash,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { openSpotlight } from "@mantine/spotlight";
import { useTranslation } from "react-i18next";

const RightSection = () => <Kbd mr={5}>Ctrl</Kbd>;

const SearchBox = ({}) => {
  const [searchText, setSearchText] = useState("");
  const Navigate = useNavigate();

  const search = (text) => {
    // console.log("search", searchText);
    Navigate(`/search?q=${searchText}`);
    setSearchText("");
  };

  const handleKeyDown = (e) => {
    // console.log("keydown", e.code);
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      return search(e.target.value);
    }
  };

  const openSpotLight = () => {
    openSpotlight();
  };
  const { t } = useTranslation();
  return (
    <>
      {/* Search Text :{searchText} */}
      <TextInput
        width={100}
        size="xs"
        mx={"sm"}
        placeholder={t("Search Text + Enter")}
        icon={<IconSearch size="0.8rem" />}
        onChange={(e) => setSearchText(e.currentTarget.value)}
        onKeyDown={(e) => handleKeyDown(e)}
        value={searchText}
        rightSection={
          <UnstyledButton onClick={() => openSpotLight()}>
            <IconSeparatorVertical size="0.8rem" />
          </UnstyledButton>
        }
      />
    </>
  );
};

export default SearchBox;
